import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsModuleRestApiService } from '../reports-module-data/reports-module-rest-api.service';
import {
	ReferanceMovement,
	ReferanceAirport,
	ReferanceAirline,
	ReferanceAircraft,
	ReferanceGeoTypes,
	ReferanceSeason,
	ReferanceRegulature,
	ReferanceChannels,
	Reference,
	ReferenceKeycloakUser,
} from '../reports-module-data/referance';
import {
	ReportParams,
	ReportDailyPlan,
	ReportSynchron,
	ReportMediaLogs,
	ReportGroup,
	ReportSubGroup,
	ReportItem,
	ReportData,
	ChartItem,
	Dashboard,
	MesssageWindow,
	DashboardChart,
	reportShow,
} from '../reports-module-data/reports';
import { FileSaverService } from 'ngx-filesaver';
import { NgForm } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { ActivatedRoute } from '@angular/router';
import moment = require('moment');
import { firstValueFrom } from 'rxjs';
import { SettingsService } from '@core/services/settings/settings.service';
import { Module } from '@shared/models/module-name';

@Component({
	selector: 'app-reports-module',
	templateUrl: './reports-module.component.html',
	styleUrls: ['./reports-module.component.less'],
})
export class ReportsModuleComponent implements OnInit {
	private apiWeightBalanceUrl: string;
	private apiLostFoundUrl: string;
	private apiReportUrl: string;

	constructor(
		public restApi: ReportsModuleRestApiService,
		private fileSaverService: FileSaverService,
		private settingsService: SettingsService,
		private activatedroute: ActivatedRoute,
		public globalI18n: GlobalI18n
	) {
		this.activatedroute.queryParams.subscribe(params => {
			this.reportParams.id = params['id'];
			this.reportActiveId = +params['report_id'];
			this.reportActiveTab = +params['reportGroup_id'];

			settingsService.general.applicationConfig$.subscribe(config => {
				this.apiWeightBalanceUrl = config.apiWeightBalanceURL;
				this.apiLostFoundUrl = config.apiLostFoundURL;
				this.apiReportUrl = config.apiReportURL;
			});
		});
		// this.activatedroute.params.subscribe(data => {
		//   if (data['id']) {
		//     this.reportParams.id = data['id'];
		//     this.my_loadReport();
		//   }
		// });
	}

	references = {
		irregularity_codes: [],
		irregularity_groups: [],
	};

	selectLoadAnimation = {
		irregularity_groups: true,
		irregularity_codes: true,
	};

	referenceKeycloakUser: ReferenceKeycloakUser[];
	referanceMovements: ReferanceMovement[];
	referanceAirports: ReferanceAirport[];
	referanceAirlines: ReferanceAirline[];
	referanceAircraftTypes: ReferanceAircraft[];
	referanceGeoTypes: ReferanceGeoTypes[];
	referanceSeasons: ReferanceSeason[];
	referanceRegulatures: ReferanceRegulature[];
	referanceChannels: ReferanceChannels[];
	reportDailyPlan: ReportDailyPlan;
	reportSynchron: ReportSynchron[];
	reportMediaLogs: ReportMediaLogs[];
	reportParams: ReportParams = new ReportParams();
	dashboards: Dashboard[];
	dashboard: Dashboard = new Dashboard();
	dashboardChart: DashboardChart = new DashboardChart();

	// Активная вкладка в левом меню в разделе Reports. Раньше была активна tab-daily-plan
	activeTab = 'menu-reports';
	reportSubGroup: ReportSubGroup[] = [];
	// Все возможные отчеты в массив(на случай дубликатов)
	reportItemAll: ReportItem[] = [];
	reportItem: ReportItem[];
	privateReportGroup: ReportGroup[] = [];
	reportVariantVisual: Array<string> = [];
	reportVisual: Array<string> = [];
	reportActiveTab;
	reportActiveId = null;
	reportActive: ReportItem = null;
	reportActiveData: ReportData;

	showReportData = false;

	// Данные по отчетам
	reportsData: Array<ReportData> = [];
	// Способ отображения отчёта. chart & table
	reportView = 'table';
	// Правая вкладка с опциями внутри отчета. Принимает два значения - parameters, favorites
	reportRightTab = 'parameters';
	activeModule = 'module-coordination';
	// Варианты в режиме сравнения. comparison или overlay
	compareMode = 'comparison';
	// id выбранного отчета
	selectedReport: number;

	@ViewChild('modalAddChart') modalAddChart: any;

	// Массив с возможными полями запроса
	reportParamsAll = [
		{ id: 0, name: 'id' },
		{ id: 1, name: 'start' },
		{ id: 2, name: 'finish' },
		{ id: 3, name: 'export' },
		{ id: 4, name: 'recipients' },
		{ id: 5, name: 'stardtt' },
		{ id: 6, name: 'airline' },
		{ id: 7, name: 'movement' },
		{ id: 8, name: 'geo' },
		{ id: 9, name: 'aircraft' },
		{ id: 10, name: 'airport' },
		{ id: 11, name: 'season' },
		{ id: 12, name: 'regularity' },
		{ id: 13, name: 'groupBy' },
		{ id: 14, name: 'channel' },
		{ id: 15, name: 'airports' },
		{ id: 16, name: 'irregularityAirports' },
		{ id: 17, name: 'totals' },
		{ id: 18, name: 'irregularityCode' },
	];

	// Период для отчета - текущий или предыдущий
	selectedPeriod = 'current';
	// Тип действия
	activeState = '';
	// Действие пользователя
	userAction = '';
	// Режим, в котором находится пользователь, к примеру, редактирование дашборда
	userMode = '';
	// Объект для отображения сообщений
	messageWindow: MesssageWindow = new MesssageWindow();
	// Виды графиков
	reportShow: Array<reportShow> = [];

	@ViewChild('reportForm') reportForm: NgForm;

	// Пагинация
	currentPage = 1;
	itemsPerPage = 15;

	// Google Charts
	diagramType = 'Calendar';
	diagramData = [];
	diagramColumns = ['Date', 'Flight'];

	// Google Charts для универсального отчета
	reportDiagramType = []; // 'ColumnChart'; // PieChart
	reportComboType = 'ComboChart';
	reportDiagramData = [];
	reportComboData = [];
	reportDiagramActive = 1;
	reportDiagramColumns = [];
	reportDiagramOptions = [];
	ChartItem: ChartItem[] = [];

	// Блок переменных для работы ng-select списков
	// Размер отображаемых данных в выпадающем списке
	referanceBufferSize = 20;
	// Количество элементов до конца списка, перед загрузкой новой порции
	numberOfItemsFromEndBeforeFetchingMore = 10;

	// Статус загрузки/обновления справочника для отображения анимации
	// в компоненте ng-select для Авиакомпаний
	referanceAirlinesLoading = false;
	// Подгружаемый список для вывода в выпадающий список
	referanceAirlinesBuffer = [];

	// Статус загрузки/обновления справочника для отображения анимации
	// в компоненте ng-select для Типов Движения
	referanceMovementsLoading = false;
	// Подгружаемый список для вывода в выпадающий список
	referanceMovementsBuffer = [];

	// Статус загрузки/обновления справочника для отображения анимации
	// в компоненте ng-select для Типов Воздушных Судов
	referanceAircraftTypesLoading = false;
	// Подгружаемый список для вывода в выпадающий список
	referanceAircraftTypesBuffer = [];

	// Статус загрузки/обновления справочника для отображения анимации
	// в компоненте ng-select для Направлений
	referanceAirportsLoading = false;
	// Подгружаемый список для вывода в выпадающий список
	referanceAirportsBuffer = [];

	// Поиск отчета
	searchReport = '';

	// Индикатор загрузки
	loading = false;

	// Модалка ввода адресов для отправки
	modalEnterEmail = false;

	// Системные сообщения
	systemMessagesTexts: Array<string> = [];
	// Флаг что сообщение отправлено
	messageSended = false;
	routeNetworkOnly = false;

	async ngOnInit() {
		Promise.all([this.loadReferences()]).then(() => {
			this.loadReferencesLocal();
		});
		// this.loadMovements();
		// this.loadAircrafts();
		this.loadKeycloakUsers();
		this.loadAirlines();
		this.loadAirports();
		// this.loadGeoTypes();
		// this.loadSeasons();
		await this.loadReportfunction();
		if (this.reportActiveId) {
			this.changeReport(this.reportActiveId);
			this.my_loadReport();
		}
		this.systemMessagesTexts['messageSent'] = this.globalI18n.getMessage(Module.Reports, 'messageSent');
		this.systemMessagesTexts['deleteChart'] = this.globalI18n.getMessage(Module.Reports, 'deleteChart');

		// ! Странный код, понять как работает и проверить
		// TODO перерабоать
		// находим первую вкладку, на которую есть права и показываем ее
		this.reportActiveTab =
			this.reportActiveTab ||
			this.reportGroup.find(el => this.settingsService.user.isUserHasRoleForAccessToModule(el.rights))?.id ||
			null;
	}

	// Псевдоданные
	loadReportfunction() {
		const ReportGroup1 = new ReportGroup();
		ReportGroup1.id = 1;
		ReportGroup1.title = ['Statistics and analytics', 'Статистика и аналитика'];
		const ReportGroup2 = new ReportGroup();
		ReportGroup2.id = 2;
		ReportGroup2.title = ['Operational', 'Оперативные'];
		const ReportGroup3 = new ReportGroup();
		ReportGroup3.id = 3;
		ReportGroup3.title = ['Technical processes', 'Технические процессы'];
		const ReportGroup4 = new ReportGroup();
		ReportGroup4.id = 4;
		ReportGroup4.title = ['Delays', 'Задержки'];
		const ReportGroup5 = new ReportGroup();
		ReportGroup5.id = 5;
		ReportGroup5.title = ['Planning', 'Планирование'];
		const ReportGroup6 = new ReportGroup();
		ReportGroup6.id = 6;
		ReportGroup6.title = ['Normative', 'Нормативные'];

		const ReportGroup7 = new ReportGroup();
		ReportGroup7.id = 7;
		ReportGroup7.title = ['Communication module', 'Сообщения'];
		ReportGroup7.rights = Module.ComMan;

		const ReportGroup8 = new ReportGroup();
		ReportGroup8.id = 8;
		ReportGroup8.title = ['Users', 'Пользователи'];
		ReportGroup8.rights = Module.ComMan;

		const ReportGroup9 = new ReportGroup();
		ReportGroup9.id = 9;
		ReportGroup9.title = ['Weight balance', 'Центровка'];
		ReportGroup9.rights = Module.WeightBalance;

		const ReportGroup10 = new ReportGroup();
		ReportGroup10.id = 10;
		ReportGroup10.title = ['Lost and found', 'Розыск багажа'];
		ReportGroup10.rights = Module.LostFound;

		const RerportSubGroup1 = new ReportSubGroup();
		const RerportSubGroup2 = new ReportSubGroup();
		const RerportSubGroup3 = new ReportSubGroup();
		const RerportSubGroup4 = new ReportSubGroup();
		const RerportSubGroup5 = new ReportSubGroup();
		const RerportSubGroup6 = new ReportSubGroup();
		const RerportSubGroup7 = new ReportSubGroup();
		const RerportSubGroup8 = new ReportSubGroup();
		const RerportSubGroup9 = new ReportSubGroup();
		RerportSubGroup1.title = ['Flights', 'Рейсы'];
		RerportSubGroup1.id = 1;
		RerportSubGroup2.title = ['Cargo', 'Грузы'];
		RerportSubGroup2.id = 2;
		RerportSubGroup3.title = ['Passangers', 'Пассажиры'];
		RerportSubGroup3.id = 3;
		RerportSubGroup4.title = ['Delays', 'Задержки'];
		RerportSubGroup4.id = 4;
		RerportSubGroup5.title = ['Number of messages', 'Количество сообщений'];
		RerportSubGroup5.id = 5;
		RerportSubGroup6.title = ['Logs', 'Логи'];
		RerportSubGroup6.id = 6;
		RerportSubGroup7.title = ['Actions', 'Действия'];
		RerportSubGroup7.id = 7;
		RerportSubGroup8.title = ['Main', 'Основные'];
		RerportSubGroup8.id = 8;
		RerportSubGroup9.title = ['Main', ' Основные'];
		RerportSubGroup9.id = 9;

		const ReportItem1 = new ReportItem(1, ['Total number of flights', 'Общее количество рейсов']);
		ReportItem1.favorite = 1;
		ReportItem1.description = [
			'The total number of all flights performed at the airport, arrivals and departures during the reporting period, except for official flights',
			'Общее количество всех выполненных рейсов в аэропорту, прилетевших и вылетевших в отчетный период, кроме служебных рейсов',
		];
		ReportItem1.formula = [
			'The sum by the number of all flights performed, except for service flights',
			'Сумма по количеству всех выполненных рейсов, кроме служебных рейсов',
		];
		// Справочник в reportVariantVisual
		ReportItem1.visualizationOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'];
		// Справочник в reportVisual
		ReportItem1.visual = ['0', '1'];
		// Подгруппа Рейсы
		const ReportItem2 = new ReportItem(2, ['SOP Passenger Service Register', 'Реестр по обслуживанию пассажиров СОП']);
		ReportItem2.favorite = 1;
		ReportItem2.description = [
			'Description of the report on the register on passenger service SOP',
			'Описание отчета про реестр по обслуживанию пассажиров СОП',
		];
		const ReportItem3 = new ReportItem(3, ['Volume indicators', 'Объемные показатели']);
		ReportItem3.favorite = 1;
		ReportItem3.description = [
			'Description of the report on the register on passenger service SOP',
			'Красивое и лаконичное описание отчеёта по объемным показателям',
		];
		const ReportItem4 = new ReportItem(4, ['Parking lot occupancy', 'Занятость стоянок']);
		ReportItem4.favorite = 1;
		ReportItem4.description = [
			'Description of the report on the occupancy of parking lots',
			'Описание отчета по занятости стоянок',
		];
		const ReportItem5 = new ReportItem(5, [
			'Loading by passengers for one takeoff and landing operation',
			'Загрузка пассажирами на одну взлётно-посадочную операцию',
		]);
		ReportItem5.favorite = 1;
		ReportItem5.description = ['Показатели работы аэропорта загрузка пассажирами на одну взлётно-посадочную операцию'];
		const ReportItem6 = new ReportItem(6, [
			'Loading with cargo for one takeoff and landing operation',
			'Загрузка грузом на одну взлётно-посадочную операцию',
		]);
		ReportItem6.favorite = 0;
		ReportItem6.description = ['Description report 6'];
		const ReportItem7 = new ReportItem(7, ['Maximum takeoff weight', 'Максимальный взлётный вес']);
		ReportItem7.favorite = 0;
		ReportItem7.description = ['Description report 7'];
		const ReportItem8 = new ReportItem(8, ['Seat occupancy rate', 'Процент занятости кресел']);
		ReportItem8.favorite = 0;
		ReportItem8.description = ['Description report 8'];
		const ReportItem9 = new ReportItem(9, [
			'ACRP - Average Number of Seats Per Flight',
			'ACRP - Среднее количество мест в рейсе',
		]);
		ReportItem9.favorite = 0;
		ReportItem9.description = ['Description report 9'];
		const ReportItem10 = new ReportItem(10, ['Route network directions', 'Направления маршрутной сети']);
		ReportItem10.favorite = 0;
		ReportItem10.description = ['Description report 10'];
		const ReportItem11 = new ReportItem(11, [
			'ACRP - Average number of flights per gate per day',
			'ACRP - Среднее количество рейсов на гейт в день',
		]);
		ReportItem11.favorite = 0;
		ReportItem11.description = ['Description report 12'];
		const ReportItem12 = new ReportItem(12, ['Seat occupancy rate', 'Процент занятности кресел']);
		ReportItem12.favorite = 0;
		ReportItem12.description = ['Description report 12'];
		// Подгруппа Грузы
		const ReportItem13 = new ReportItem(13, ['Number of transported cargo', 'Количество перевезенного груза']);
		ReportItem13.favorite = 0;
		ReportItem13.description = ['Description report 13'];
		const ReportItem14 = new ReportItem(14, ['Amount of cargo handled', 'Количество груза, обработано']);
		ReportItem14.favorite = 0;
		ReportItem14.description = ['Description report 14'];
		// Подгруппа Пассажиры
		const ReportItem15 = new ReportItem(15, ['Number of passengers carried', 'Количество перевезенных пассажиров']);
		ReportItem15.favorite = 0;
		ReportItem15.description = ['Description report 15'];
		const ReportItem16 = new ReportItem(16, ['Percentage of RAU passengers per year', 'Доля пассажиров РАУ за год']);
		ReportItem16.favorite = 0;
		ReportItem16.description = ['Description report 16'];
		// Подгруппа Задержки
		const ReportItem17 = new ReportItem(17, ['Regularity of flights', 'Регулярность отправления рейсов']);
		ReportItem17.favorite = 0;
		ReportItem17.description = ['Description report 17'];
		const ReportItem18 = new ReportItem(18, ['Delays due to reasons', 'Задержки по причинам']);
		ReportItem18.favorite = 1;
		ReportItem18.description = ['Description report 19'];
		const ReportItem19 = new ReportItem(19, ['Daily flight plan execution', 'Выполнение СПП']);
		ReportItem19.favorite = 0;
		ReportItem19.description = ['Description report 19'];
		const ReportItem20 = new ReportItem(20, [
			'ACRP - Average Arrival Delay',
			'ACRP - Средняя задержка прибытия на рейс',
		]);
		ReportItem20.favorite = 0;
		ReportItem20.description = ['Description report 20'];
		const ReportItem21 = new ReportItem(21, [
			'ACRP - Flight Arrival Delay Percentage',
			'ACRP - Процент задержек прибывших рейсов',
		]);
		ReportItem21.favorite = 0;
		ReportItem21.description = ['Description report 21'];

		// Подгруппа Отчеты по количеству сообщений
		const ReportItem22 = new ReportItem(22, ['For a given period of time', 'За заданный период времени']);
		ReportItem22.favorite = 0;
		ReportItem22.description = [
			'List of folders with the number of received / sent messages',
			'Перечень папок с количеством принятых/переданных сообщений',
		];
		ReportItem22.url = '/reports/stats_received_sent_by_period';
		ReportItem22.port = this.apiReportUrl;
		ReportItem22.params = [1, 2, 3, 4];

		const ReportItem23 = new ReportItem(23, ['Yesterday, today and this month', 'За вчера, сегодня и за месяц']);
		ReportItem23.favorite = 0;
		ReportItem23.description = [
			'List of folders with the number of received / sent messages for the previous, current day and current month',
			'Перечень папок с количеством принятых/переданных сообщений за предыдущие, текущие сутки и текущий месяц',
		];
		ReportItem23.url = '/reports/stats_received_sent_for_date';
		ReportItem23.port = this.apiReportUrl;
		ReportItem23.params = [5, 3, 4];

		const ReportItem24 = new ReportItem(24, ['Received and transmitted', 'Принятые и переданные']);
		ReportItem24.favorite = 0;
		ReportItem24.description = [
			'List of received / transmitted message types',
			'Перечень типов сообщений принятых/переданных',
		];
		ReportItem24.url = '/reports/stats_received_sent_by_types_and_period';
		ReportItem24.port = this.apiReportUrl;
		ReportItem24.params = [1, 2, 3, 4];

		const ReportItem25 = new ReportItem(25, [
			'Reaction time of receiving a message in a folder',
			'Время реакции получения сообщения в папке',
		]);
		ReportItem25.favorite = 0;
		ReportItem25.description = [
			'Displaying folders with message read times',
			'Отображение папок с временами прочтения сообщений',
		];
		ReportItem25.url = '/reports/stats_reaction_time_by_period';
		ReportItem25.port = this.apiReportUrl;
		ReportItem25.params = [1, 2, 3, 4];

		// Подгруппа логи
		const ReportItem26 = new ReportItem(26, ['Report for message', 'Отчет по сообщению']);
		ReportItem26.favorite = 0;
		ReportItem26.description = ['Report for message', 'Отчет по сообщению'];
		ReportItem26.url = '/reports/logs_by_message';
		ReportItem26.port = this.apiReportUrl;
		ReportItem26.params = [0];

		const ReportItem28 = new ReportItem(28, ['Channel statistics over time', 'Статистика по каналу за время']);
		ReportItem28.favorite = 0;
		ReportItem28.description = ['Channel statistics over time', 'Статистика по каналу за время'];
		ReportItem28.url = '/reports/cks_stats/report';
		ReportItem28.port = this.apiReportUrl;
		ReportItem28.params = [1, 2, 3, 14];

		// Подгруппа действия пользователя
		const ReportItem27 = new ReportItem(27, ['Login and logout report', 'Отчет о входе и выходе из системы']);
		ReportItem27.favorite = 0;
		ReportItem27.description = [
			'The date and time when users logged in; date and time when logout',
			'Дата и время, когда пользователи вошел в систему; дата и время, когда вышел',
		];
		ReportItem27.url = '/reports/logs_logon_logoff';
		ReportItem27.port = this.apiReportUrl;
		ReportItem27.params = [1, 2, 3];

		// Подгруппа действия пользователя в центровке
		const ReportItem29 = new ReportItem(29, ['Weight balance report', 'Отчет по центровке рейса']);
		ReportItem29.favorite = 0;
		ReportItem29.description = ['Weight balance report', 'Отчет по центровке рейса'];
		ReportItem29.url = '/reports/flights';
		ReportItem29.port = this.apiWeightBalanceUrl;
		ReportItem29.params = [0];

		// Подгруппа логи в розыске
		const ReportItem30 = new ReportItem(30, ['Lost and found report', 'Отчет по розыску багажа']);
		ReportItem30.favorite = 0;
		ReportItem30.description = ['Lost and found report', 'Отчет по розыску багажа'];
		ReportItem30.url = '/reports/logs_by_id/';
		ReportItem30.port = this.apiLostFoundUrl;
		ReportItem30.params = [0];

		const ReportItem31 = new ReportItem(31, ['Case report', 'Отчет по кейсам']);
		ReportItem31.favorite = 0;
		ReportItem31.description = ['Case report', 'Отчет по кейсам'];
		ReportItem31.url = '/reports/cases';
		ReportItem31.port = this.apiLostFoundUrl;
		ReportItem31.params = [1, 2, 15, 24];

		// const ReportItem32 = new ReportItem(32, ['Quantity by cause of failure', 'Количество по причинам неисправности']);
		// ReportItem32.favorite = 0;
		// ReportItem32.description = ['Quantity by cause of failure', 'Количество по причинам неисправности'];
		// ReportItem32.url = '/reports/count_by_irregularity_codes';
		// ReportItem32.port = this.apiLostFoundUrl;
		// ReportItem32.params = [1, 2, 15, 16];

		const ReportItem33 = new ReportItem(33, ['Delivery report', 'Отчет по досылке']);
		ReportItem33.favorite = 0;
		ReportItem33.description = ['Delivery report', 'Отчет по досылке'];
		ReportItem33.url = '/reports/forwarding';
		ReportItem33.port = this.apiLostFoundUrl;
		ReportItem33.params = [1, 2, 28];

		const ReportItem34 = new ReportItem(34, [
			'Baggage quantity due to malfunction',
			'Статистика причин неисправности багажа по виновным станциям',
		]);
		ReportItem34.favorite = 0;
		ReportItem34.description = [
			'Baggage quantity due to malfunction',
			'Статистика причин неисправности багажа по виновным станциям',
		];
		ReportItem34.url = '/reports/baggage_quantity_due_to_malfunction';
		ReportItem34.port = this.apiLostFoundUrl;
		ReportItem34.params = [1, 2, 15, 17];

		const ReportItem35 = new ReportItem(35, ['Claim and baggage report', 'Отчет по заявлениям и багажу']);
		ReportItem35.favorite = 0;
		ReportItem35.description = ['Claim and baggage report', 'Отчет по заявлениям и багажу'];
		ReportItem35.url = '/reports/statement_and_baggage';
		ReportItem35.port = this.apiLostFoundUrl;
		ReportItem35.params = [1, 2, 15, 18, 19, 20, 21, 24, 30];

		const ReportItem36 = new ReportItem(36, [
			'Claim and baggage report for a fault station',
			'Отчет по заявлениям и багажу по виновному аэропорту',
		]);
		ReportItem36.favorite = 0;
		ReportItem36.description = [
			'Claim and baggage report for a fault station',
			'Отчет по заявлениям и багажу по виновному аэропорту',
		];
		ReportItem36.url = '/reports/fault_station_statement_and_baggage';
		ReportItem36.port = this.apiLostFoundUrl;
		ReportItem36.params = [1, 2, 15, 18, 19, 20, 22];

		const ReportItem37 = new ReportItem(37, ['Station operation report', 'Отчет по работе агентов']);
		ReportItem37.favorite = 0;
		ReportItem37.description = ['Station operation report', 'Отчет по работе агентов'];
		ReportItem37.url = '/reports/station_operations';
		ReportItem37.port = this.apiLostFoundUrl;
		ReportItem37.params = [1, 2, 15, 23];

		const ReportItem38 = new ReportItem(38, [
			'Report on the operation of stations by airlines',
			'Отчет работы станций по авиакомпаниям',
		]);
		ReportItem38.favorite = 0;
		ReportItem38.description = [
			'Report on the operation of stations by airlines',
			'Отчет работы станций по авиакомпаниям',
		];
		ReportItem38.url = '/reports/station_operations';
		ReportItem38.port = this.apiLostFoundUrl;
		ReportItem38.params = [1, 2, 24, 23];

		const ReportItem39 = new ReportItem(39, ['Storage Report', 'Отчет по хранению']);
		ReportItem39.favorite = 0;
		ReportItem39.description = ['Storage Report', 'Отчет по хранению'];
		ReportItem39.url = '/reports/storage';
		ReportItem39.port = this.apiLostFoundUrl;
		ReportItem39.params = [1, 2, 15, 23, 24, 25];

		const ReportItem40 = new ReportItem(40, ['Lost things report', 'Отчет потерянным вещам']);
		ReportItem40.favorite = 0;
		ReportItem40.description = ['Lost things report', 'Отчет потерянным вещам'];
		ReportItem40.url = '/reports/lost_items';
		ReportItem40.port = this.apiLostFoundUrl;
		ReportItem40.params = [1, 2, 15, 19, 23, 24, 29];

		const ReportItem41 = new ReportItem(41, ['Agent worklog', 'Отчет по работе агента']);
		ReportItem41.favorite = 0;
		ReportItem41.description = ['Agent worklog', 'Отчет по работе агента'];
		ReportItem41.url = '/reports/agent_worklog';
		ReportItem41.port = this.apiLostFoundUrl;
		ReportItem41.params = [1, 2, 27];

		// Подгруппа действия пользователя в центровке
		const ReportItem42 = new ReportItem(42, ['Number of calculations by airport', 'Количество расчетов по аэропорту']);
		ReportItem42.favorite = 0;
		ReportItem42.description = ['Number of calculations by airport', 'Количество расчетов по аэропорту'];
		ReportItem42.url = '/reports/documents';
		ReportItem42.port = this.apiWeightBalanceUrl;
		ReportItem42.params = [1, 10];

		const ReportItem43 = new ReportItem(43, ['Report on lost items', 'Отчет о потерянных вещах']);
		ReportItem43.favorite = 0;
		ReportItem43.description = ['Report on lost items', 'Отчет о потерянных вещах'];
		ReportItem43.url = '/reports/logs_by_id_lost/';
		ReportItem43.port = this.apiLostFoundUrl;
		ReportItem43.params = [0];

		// Подгруппа Рейсы
		RerportSubGroup1.reportItem.push(ReportItem1);
		RerportSubGroup1.reportItem.push(ReportItem2);
		RerportSubGroup1.reportItem.push(ReportItem3);
		RerportSubGroup1.reportItem.push(ReportItem4);
		RerportSubGroup1.reportItem.push(ReportItem5);
		RerportSubGroup1.reportItem.push(ReportItem6);
		RerportSubGroup1.reportItem.push(ReportItem7);
		RerportSubGroup1.reportItem.push(ReportItem8);
		RerportSubGroup1.reportItem.push(ReportItem9);
		RerportSubGroup1.reportItem.push(ReportItem10);
		RerportSubGroup1.reportItem.push(ReportItem11);
		RerportSubGroup1.reportItem.push(ReportItem12);
		// Подгруппа Грузы
		RerportSubGroup2.reportItem.push(ReportItem13);
		RerportSubGroup2.reportItem.push(ReportItem14);
		// Подгруппа Пассажиры
		RerportSubGroup3.reportItem.push(ReportItem15);
		RerportSubGroup3.reportItem.push(ReportItem16);
		// Подгруппа Задержки
		RerportSubGroup4.reportItem.push(ReportItem17);
		RerportSubGroup4.reportItem.push(ReportItem18);
		RerportSubGroup4.reportItem.push(ReportItem19);
		RerportSubGroup4.reportItem.push(ReportItem20);
		RerportSubGroup4.reportItem.push(ReportItem21);
		// Подгруппа Статистика по кол-ву сообщений
		RerportSubGroup5.reportItem.push(ReportItem22);
		RerportSubGroup5.reportItem.push(ReportItem23);
		RerportSubGroup5.reportItem.push(ReportItem24);
		RerportSubGroup5.reportItem.push(ReportItem25);
		// Подгруппа логи
		RerportSubGroup6.reportItem.push(ReportItem26);
		RerportSubGroup6.reportItem.push(ReportItem28);
		// Подгруппа действия пользователя
		RerportSubGroup7.reportItem.push(ReportItem27);
		// Подгруппа отчетов по центровке
		RerportSubGroup8.reportItem.push(ReportItem29);
		RerportSubGroup8.reportItem.push(ReportItem42);

		// Подгруппа отчетов по розыску
		RerportSubGroup9.reportItem.push(ReportItem30);
		// RerportSubGroup9.reportItem.push(ReportItem32);
		RerportSubGroup9.reportItem.push(ReportItem33);
		RerportSubGroup9.reportItem.push(ReportItem34);
		RerportSubGroup9.reportItem.push(ReportItem35);
		RerportSubGroup9.reportItem.push(ReportItem36);
		RerportSubGroup9.reportItem.push(ReportItem37);
		RerportSubGroup9.reportItem.push(ReportItem38);
		RerportSubGroup9.reportItem.push(ReportItem31);
		RerportSubGroup9.reportItem.push(ReportItem39);
		RerportSubGroup9.reportItem.push(ReportItem40);
		RerportSubGroup9.reportItem.push(ReportItem41);
		RerportSubGroup9.reportItem.push(ReportItem43);

		ReportGroup1.reportSubGroup.push(RerportSubGroup1);
		ReportGroup1.reportSubGroup.push(RerportSubGroup2);
		ReportGroup1.reportSubGroup.push(RerportSubGroup3);
		ReportGroup1.reportSubGroup.push(RerportSubGroup4);
		ReportGroup2.reportSubGroup.push(RerportSubGroup3);
		ReportGroup2.reportSubGroup.push(RerportSubGroup1);
		ReportGroup7.reportSubGroup.push(RerportSubGroup5);
		ReportGroup7.reportSubGroup.push(RerportSubGroup6);
		ReportGroup8.reportSubGroup.push(RerportSubGroup7);
		ReportGroup9.reportSubGroup.push(RerportSubGroup8);
		ReportGroup10.reportSubGroup.push(RerportSubGroup9);

		// Один и тот же отчет может быть на разных вкладках.
		// Поэтому все возмодные отчеты засунем в массив. Ну и перебрать удобней.
		this.reportItemAll.push(ReportItem1);
		this.reportItemAll.push(ReportItem2);
		this.reportItemAll.push(ReportItem3);
		this.reportItemAll.push(ReportItem4);
		this.reportItemAll.push(ReportItem5);
		this.reportItemAll.push(ReportItem6);
		this.reportItemAll.push(ReportItem7);
		this.reportItemAll.push(ReportItem8);
		this.reportItemAll.push(ReportItem9);
		this.reportItemAll.push(ReportItem10);
		this.reportItemAll.push(ReportItem11);
		this.reportItemAll.push(ReportItem12);
		this.reportItemAll.push(ReportItem13);
		this.reportItemAll.push(ReportItem14);
		this.reportItemAll.push(ReportItem15);
		this.reportItemAll.push(ReportItem16);
		this.reportItemAll.push(ReportItem17);
		this.reportItemAll.push(ReportItem18);
		this.reportItemAll.push(ReportItem19);
		this.reportItemAll.push(ReportItem20);
		this.reportItemAll.push(ReportItem21);
		this.reportItemAll.push(ReportItem22);
		this.reportItemAll.push(ReportItem23);
		this.reportItemAll.push(ReportItem24);
		this.reportItemAll.push(ReportItem25);
		this.reportItemAll.push(ReportItem26);
		this.reportItemAll.push(ReportItem27);
		this.reportItemAll.push(ReportItem28);
		this.reportItemAll.push(ReportItem29);
		this.reportItemAll.push(ReportItem30);
		this.reportItemAll.push(ReportItem31);
		// this.reportItemAll.push(ReportItem32);
		this.reportItemAll.push(ReportItem33);
		this.reportItemAll.push(ReportItem34);
		this.reportItemAll.push(ReportItem35);
		this.reportItemAll.push(ReportItem36);
		this.reportItemAll.push(ReportItem37);
		this.reportItemAll.push(ReportItem38);
		this.reportItemAll.push(ReportItem39);
		this.reportItemAll.push(ReportItem40);
		this.reportItemAll.push(ReportItem41);
		this.reportItemAll.push(ReportItem42);
		this.reportItemAll.push(ReportItem43);

		// this.privateReportGroup.push(ReportGroup1);
		// this.privateReportGroup.push(ReportGroup2);
		// this.privateReportGroup.push(ReportGroup3);
		// this.privateReportGroup.push(ReportGroup4);
		// this.privateReportGroup.push(ReportGroup5);
		// this.privateReportGroup.push(ReportGroup6);
		this.privateReportGroup.push(ReportGroup7);
		this.privateReportGroup.push(ReportGroup8);
		this.privateReportGroup.push(ReportGroup9);
		this.privateReportGroup.push(ReportGroup10);

		// Опишу справочник вариантов визуализации
		this.reportVariantVisual = [
			'Общее количество рейсов',
			'Количество рейсов, прилет',
			'Количество рейсов, вылет',
			'Количество рейсов терминал 1',
			'Количество рейсов терминал 2',
			'Количество рейсов терминал 2',
			'АК',
			'Российская АК',
			'Иностранная АК',
			'Рейсы ЦР',
			'Чартерные рейсы',
			'Рейсы МВЛ',
			'Рейсы ВВЛ',
			'Рейс с загрузкой',
			'Терминалы прочие (не 1,2,3)',
		];
		this.reportVisual = ['Табличные данные', 'Диаграмма', 'График'];

		// Псевдоданные для отдельных отчётов
		const reportData1 = new ReportData();
		reportData1.id = 1;
		// reportData1.columns = ['col1', 'col2', 'col3', 'col4', 'col5', 'col6'];
		reportData1.titles = [
			'Gate-МС',
			'Количество рейсов цифра',
			'',
			'Ср. кол-во пасс. на рейсе',
			'Кол-во пассажиров',
			'',
		];
		reportData1.data.push(
			['9-11', '10', '21,7391304347826', '111', '1108', '28,0506329113924'],
			['10-17', '1', '2,17391304347826', '116', '116', '2,93670886075949'],
			['10-19', '1', '2,17391304347826', '118', '118', '2,9873417721519'],
			['10-49', '2', '4,34782608695652', '25', '49', '1,24050632911392'],
			['10-50', '3', '6,52173913043478', '22', '67', '1,69620253164557'],
			['10-51', '2', '4,34782608695652', '29', '58', '1,46835443037975'],
			['10-57', '1', '2,17391304347826', '57', '57', '1,44303797468354'],
			['10-58', '2', '4,34782608695652', '43', '86', '2,17721518987342'],
			['10-59', '1', '2,17391304347826', '81', '81', '2,0506329113924'],
			['10-61', '1', '2,17391304347826', '66', '66', '1,67088607594937'],
			['11-49', '1', '2,17391304347826', '35', '35', '0,886075949367089'],
			['13-12', '9', '19,5652173913043', '113', '1016', '25,7215189873418'],
			['15-17', '1', '2,17391304347826', '117', '117', '2,9620253164557'],
			['15-38', '1', '2,17391304347826', '142', '142', '3,59493670886076'],
			['15-51', '1', '2,17391304347826', '37', '37', '0,936708860759494'],
			['15-57', '2', '4,34782608695652', '93', '186', '4,70886075949367'],
			['15-58', '3', '6,52173913043478', '61', '182', '4,60759493670886'],
			['15-59', '2', '4,34782608695652', '82', '164', '4,15189873417722'],
			['15-60', '1', '2,17391304347826', '136', '136', '3,44303797468354'],
			['15-61', '1', '2,17391304347826', '129', '129', '3,26582278481013']
		);

		// Отчет №2
		const reportData2 = new ReportData();
		reportData2.id = 2;
		reportData2.titles = [
			'Прилет/Вылет',
			'Рейс","Тип ВС/Борт',
			'Маршрут',
			'МС',
			'Время расчетное/фактическое',
			'Время готовности к посадке',
			'ТП',
			'Ресурсы',
			'Начало ТП фактическое',
			'Окончание ТП фактическое',
			'Стойки регистрации',
			'Выход на посадку',
			'Кол-во пассажиров',
			'Кол-во пассажиров до ЕКБ',
			'Причина задержки',
			'Время Т',
		];
		reportData2.data.push(
			[
				'Вылет',
				'DP-563',
				'B-737-800W/VPBQB',
				'КРАСНОДАР',
				'13',
				'10.07 00:0510.07 00:21',
				'09.07 23:31',
				'Посадка',
				'Акимова В.М.',
				'23:38',
				'00:00',
				'25,26',
				'14',
				'183',
				'',
				'/Снятие багажа пассажиров',
				'22',
			],
			[
				'Вылет',
				'SU-1409',
				'B-737-800/VQBHX',
				'МОСКВА/SVO',
				'11',
				'10.07 00:1510.07 00:19',
				'09.07 23:29',
				'Посадка',
				'Зварич Н.В.',
				'23:40',
				'00:01',
				'45,46,47',
				'9',
				'157',
				'',
				'/',
				'21',
			],
			[
				'Вылет',
				'DP-516',
				'B-737-800/VPBQG',
				'С-ПЕТЕРБУРГ',
				'14',
				'10.07 00:4010.07 00:45',
				'10.07 00:14',
				'Посадка',
				'Лешукова М.С.',
				'00:15',
				'00:27',
				'28,29',
				'17',
				'186',
				'',
				'/',
				'12',
			],
			[
				'Вылет',
				'DP-516',
				'B-737-800/VPBQG',
				'С-ПЕТЕРБУРГ',
				'14',
				'10.07 00:4010.07 00:45',
				'',
				'Регистрация',
				'Лешукова М.С.Фролова Д.Д.Караматова Е.Г.',
				'21:54',
				'00:00',
				'28,29',
				'17',
				'186',
				'',
				'/',
				'142',
			],
			[
				'Вылет',
				'AY-726',
				'EMB-190/OHLKE',
				'ХЕЛЬСИНКИ',
				'9',
				'10.07 00:5010.07 01:05',
				'10.07 00:27',
				'Посадка',
				'Волкова С.С.Шабурова А.М.',
				'00:29',
				'00:43',
				'13,14,15',
				'3',
				'98',
				'',
				'/',
				'14',
			],
			[
				'Вылет',
				'AY-726',
				'EMB-190/OHLKE',
				'ХЕЛЬСИНКИ',
				'9',
				'10.07 00:5010.07 01:05',
				'',
				'Регистрация',
				'Волкова С.С.Шелковникова А.А.Шабурова А.М.',
				'21:48',
				'00:05',
				'13,14,15',
				'3',
				'98',
				'',
				'/',
				'136',
			],
			[
				'Вылет',
				'U6-2931',
				'A-319/VPBJV',
				'СИМФЕРОПОЛЬ',
				'58',
				'10.07 00:5510.07 01:02',
				'10.07 00:09',
				'Посадка',
				'Иванова Н.А.Акимова В.М.',
				'00:26',
				'00:43',
				'33,35,36,37',
				'10',
				'135',
				'',
				'/',
				'17',
			],
			[
				'Вылет',
				'U6-2931',
				'A-319/VPBJV',
				'СИМФЕРОПОЛЬ',
				'58',
				'10.07 00:5510.07 01:02',
				'',
				'Регистрация',
				'Иванова Н.А.Мухамадеева Е.В.Чухланцева Е.Н.Булычева А.Н.Беженцева С.А.',
				'18:47',
				'00:15',
				'33,35,36,37',
				'10',
				'135',
				'',
				'/',
				'327',
			],
			[
				'Вылет',
				'DP-340',
				'B-737-800/VPBQY',
				'СОЧИ',
				'50',
				'10.07 00:5510.07 01:19',
				'10.07 00:22',
				'Посадка',
				'Ярославцева Е.С.Маркелова К.С.Топоркова Е.А.Маркова А.С.',
				'00:38',
				'00:55',
				'30',
				'11,12',
				'191',
				'',
				'/',
				'17',
			],
			[
				'Вылет',
				'DP-340',
				'B-737-800/VPBQY',
				'СОЧИ',
				'50',
				'10.07 00:5510.07 01:19',
				'',
				'Регистрация',
				'Маркова А.С.Топоркова Е.А.Маркелова К.С.',
				'21:57',
				'00:15',
				'30',
				'11,12',
				'191',
				'',
				'/',
				'137',
			],
			[
				'Вылет',
				'S7-52',
				'A-319/VPBHP',
				'МОСКВА/DME',
				'49',
				'10.07 01:1010.07 01:22',
				'10.07 00:30',
				'Посадка',
				'Филиппова Н.А.Никитина В.А.',
				'00:45',
				'01:06',
				'42,43',
				'16',
				'140',
				'',
				'/',
				'21',
			],
			[
				'Вылет',
				'S7-52',
				'A-319/VPBHP',
				'МОСКВА/DME',
				'49',
				'10.07 01:1010.07 01:22',
				'',
				'Регистрация',
				'Никитина В.А.Филиппова Н.А.Ступникова Е.С.',
				'22:06',
				'00:30',
				'42,43',
				'16',
				'140',
				'',
				'/',
				'143',
			],
			[
				'Вылет',
				'FV-6402',
				'A-319/VPBBT',
				'С-ПЕТЕРБУРГ',
				'56',
				'10.07 01:1510.07 01:17',
				'10.07 00:26',
				'Посадка',
				'Харинина М.С.Шек А.С.',
				'00:37',
				'00:55',
				'45,46,47',
				'15',
				'127',
				'',
				'/',
				'18',
			],
			[
				'Вылет',
				'FV-6402',
				'A-319/VPBBT',
				'С-ПЕТЕРБУРГ',
				'56',
				'10.07 01:1510.07 01:17',
				'',
				'Регистрация',
				'Гулакова А.А.Зварич Н.В.Струлькова А.М.Солошина Ю.А.',
				'21:43',
				'00:35',
				'45,46,47',
				'15',
				'127',
				'',
				'/',
				'171',
			],
			[
				'Вылет',
				'DP-303',
				'B-737-800W/VPBPT',
				'АНАПА',
				'12',
				'10.07 01:1510.07 01:27',
				'10.07 00:46',
				'Посадка',
				'Анисимова О.С.',
				'00:48',
				'01:05',
				'24,26',
				'13',
				'194',
				'',
				'/',
				'17',
			],
			[
				'Вылет',
				'DP-303',
				'B-737-800W/VPBPT',
				'АНАПА',
				'12',
				'10.07 01:1510.07 01:27',
				'',
				'Регистрация',
				'Анисимова О.С.Мурадян А.Н.Банных А.Г.',
				'21:10',
				'00:35',
				'24,26',
				'13',
				'194',
				'',
				'/',
				'204',
			],
			[
				'Вылет',
				'U6-219',
				'A-320/VPBMT',
				'СОЧИ',
				'59',
				'10.07 01:1510.07 01:33',
				'10.07 00:34',
				'Посадка',
				'Шелковникова А.А.Ступникова Е.С.',
				'00:48',
				'01:11',
				'33,35,36,37',
				'10',
				'167',
				'',
				'/',
				'23',
			],
			[
				'Вылет',
				'U6-219',
				'A-320/VPBMT',
				'СОЧИ',
				'59',
				'10.07 01:1510.07 01:33',
				'',
				'Регистрация',
				'Чухланцева Е.Н.Мухамадеева Е.В.Булычева А.Н.Беженцева С.А.Иванова Н.А.',
				'18:47',
				'00:35',
				'33,35,36,37',
				'10',
				'167',
				'',
				'/',
				'347',
			],
			[
				'Вылет',
				'DP-404',
				'B-737-800W/VPBPS',
				'МОСКВА/VKO',
				'15',
				'10.07 01:2010.07 01:35',
				'10.07 00:50',
				'Посадка',
				'Караматова Е.Г.',
				'00:54',
				'01:14',
				'29',
				'18',
				'187',
				'',
				'/',
				'20',
			],
			[
				'Вылет',
				'DP-404',
				'B-737-800W/VPBPS',
				'МОСКВА/VKO',
				'15',
				'10.07 01:2010.07 01:35',
				'',
				'Регистрация',
				'Фролова Д.Д.Караматова Е.Г.Лешукова М.С.',
				'22:23',
				'00:40',
				'29',
				'18',
				'187',
				'',
				'/',
				'136',
			],
			[
				'Вылет',
				'U6-264',
				'A-320/VPBQW',
				'МОСКВА/DME',
				'60',
				'10.07 01:5010.07 01:52',
				'10.07 01:04',
				'Посадка',
				'Фролова Д.Д.Лешукова М.С.',
				'01:19',
				'01:40',
				'33,34,35,36,37',
				'15',
				'159',
				'',
				'/',
				'21',
			],
			[
				'Вылет',
				'U6-264',
				'A-320/VPBQW',
				'МОСКВА/DME',
				'60',
				'10.07 01:5010.07 01:52',
				'',
				'Регистрация',
				'Чухланцева Е.Н.Булычева А.Н.Иванова Н.А.Мухамадеева Е.В.Беженцева С.А.',
				'20:04',
				'01:10',
				'33,34,35,36,37',
				'15',
				'159',
				'',
				'/',
				'305',
			],
			[
				'Вылет',
				'SU-1407',
				'B-737-800/VPBCD',
				'МОСКВА/SVO',
				'16',
				'10.07 02:0010.07 02:04',
				'10.07 01:22',
				'Посадка',
				'Филиппова Н.А.Гулакова А.А.',
				'01:28',
				'01:48',
				'46,47',
				'16',
				'150',
				'',
				'/',
				'20',
			],
			[
				'Вылет',
				'SU-1407',
				'B-737-800/VPBCD',
				'МОСКВА/SVO',
				'16',
				'10.07 02:0010.07 02:04',
				'',
				'Регистрация',
				'Зварич Н.В.Струлькова А.М.Солошина Ю.А.Гулакова А.А.',
				'21:42',
				'01:20',
				'46,47',
				'16',
				'150',
				'',
				'/',
				'217',
			],
			[
				'Вылет',
				'DP-406',
				'B-737-800W/VQBWG',
				'МОСКВА/VKO',
				'14',
				'10.07 02:1010.07 02:26',
				'10.07 01:51',
				'Посадка',
				'Банных А.Г.',
				'01:52',
				'02:07',
				'24,26',
				'17',
				'185',
				'',
				'/',
				'15',
			],
			[
				'Вылет',
				'DP-406',
				'B-737-800W/VQBWG',
				'МОСКВА/VKO',
				'14',
				'10.07 02:1010.07 02:26',
				'',
				'Регистрация',
				'Мурадян А.Н.Банных А.Г.Анисимова О.С.',
				'21:10',
				'01:35',
				'24,26',
				'17',
				'185',
				'',
				'/',
				'264',
			],
			[
				'Вылет',
				'7R-805',
				'CRJ-100/VPBVC',
				'НОВЫЙ УРЕНГОЙ',
				'52',
				'10.07 02:1510.07 02:17',
				'10.07 01:48',
				'Посадка',
				'Топоркова Е.А.Маркелова К.С.',
				'01:42',
				'01:53',
				'49,50',
				'10',
				'49',
				'',
				'/',
				'11',
			],
			[
				'Вылет',
				'7R-805',
				'CRJ-100/VPBVC',
				'НОВЫЙ УРЕНГОЙ',
				'52',
				'10.07 02:1510.07 02:17',
				'',
				'Регистрация',
				'Карагодин Е.В.Абдрахманова Д.И.Москвина М.И.',
				'23:16',
				'01:35',
				'49,50',
				'10',
				'49',
				'',
				'/',
				'138',
			],
			[
				'Вылет',
				'7R-841',
				'CRJ-200/VQBFB',
				'АРХАНГЕЛЬСК',
				'51',
				'10.07 02:5010.07 03:01',
				'10.07 02:21',
				'Посадка',
				'Караматова Е.Г.',
				'02:18',
				'02:37',
				'49,50',
				'16',
				'23',
				'',
				'/',
				'19',
			],
			[
				'Вылет',
				'7R-841',
				'CRJ-200/VQBFB',
				'НАРЬЯН-МАР',
				'51',
				'10.07 02:5010.07 03:01',
				'',
				'Регистрация',
				'Москвина М.И.Карагодин Е.В.Абдрахманова Д.И.',
				'00:06',
				'02:10',
				'49,50',
				'16',
				'23',
				'',
				'/',
				'123',
			],
			[
				'Вылет',
				'U6-262',
				'A-320/VPBTZ',
				'МОСКВА/DME',
				'15',
				'10.07 03:1510.07 03:20',
				'10.07 02:42',
				'Посадка',
				'Ступникова Е.С.',
				'02:43',
				'03:02',
				'35,36,37',
				'18',
				'145',
				'',
				'/',
				'19',
			],
			[
				'Вылет',
				'U6-262',
				'A-320/VPBTZ',
				'МОСКВА/DME',
				'15',
				'10.07 03:1510.07 03:20',
				'',
				'Регистрация',
				'Булычева А.Н.Мухамадеева Е.В.Чухланцева Е.Н.Иванова Н.А.Беженцева С.А.',
				'20:04',
				'02:35',
				'35,36,37',
				'18',
				'145',
				'',
				'/',
				'390',
			],
			[
				'Вылет',
				'YC-121',
				'RRJ-95LR/89087',
				'САЛЕХАРД',
				'21',
				'10.07 03:2510.07 03:29',
				'10.07 02:50',
				'Посадка',
				'Келембет К.А.',
				'03:04',
				'03:09',
				'49,50',
				'16',
				'77',
				'',
				'/',
				'5',
			],
			[
				'Вылет',
				'YC-121',
				'RRJ-95LR/89087',
				'НОВЫЙ УРЕНГОЙ',
				'21',
				'10.07 03:2510.07 03:29',
				'',
				'Регистрация',
				'Абдрахманова Д.И.Москвина М.И.Карагодин Е.В.',
				'00:39',
				'02:47',
				'49,50',
				'16',
				'77',
				'',
				'/',
				'127',
			],
			[
				'Вылет',
				'U6-365',
				'A-320/VPBDL',
				'ВЛАДИВОСТОК',
				'18',
				'10.07 03:3010.07 03:33',
				'10.07 02:43',
				'Посадка',
				'Бирюкова О.В.Рогожина Л.М.',
				'03:01',
				'03:13',
				'35,36,37',
				'15',
				'158',
				'',
				'/',
				'12',
			],
			[
				'Вылет',
				'U6-365',
				'A-320/VPBDL',
				'ИРКУТСК',
				'18',
				'10.07 03:3010.07 03:33',
				'',
				'Регистрация',
				'Беженцева С.А.Чухланцева Е.Н.Иванова Н.А.Мухамадеева Е.В.Булычева А.Н.',
				'20:04',
				'02:53',
				'35,36,37',
				'15',
				'158',
				'',
				'/',
				'409',
			],
			[
				'Вылет',
				'SU-1437',
				'A-320/VPBCE',
				'МОСКВА/SVO',
				'13',
				'10.07 03:4010.07 03:40',
				'10.07 03:00',
				'Посадка',
				'Владимирова А.И.',
				'03:01',
				'03:19',
				'45,47',
				'14',
				'151',
				'',
				'/',
				'18',
			],
			[
				'Вылет',
				'SU-1437',
				'A-320/VPBCE',
				'МОСКВА/SVO',
				'13',
				'10.07 03:4010.07 03:40',
				'',
				'Регистрация',
				'Струлькова А.М.Солошина Ю.А.Зварич Н.В.Емельянова М.Е.Шек А.С.',
				'23:09',
				'03:00',
				'45,47',
				'14',
				'151',
				'',
				'/',
				'231',
			],
			[
				'Вылет',
				'U6-223',
				'A-320/VQBFW',
				'С-ПЕТЕРБУРГ',
				'12',
				'10.07 05:0010.07 05:05',
				'10.07 04:17',
				'Посадка',
				'Курдоякова А.Р.',
				'04:23',
				'04:45',
				'34,35,36,37',
				'13',
				'157',
				'',
				'/',
				'22',
			],
			[
				'Вылет',
				'U6-223',
				'A-320/VQBFW',
				'С-ПЕТЕРБУРГ',
				'12',
				'10.07 05:0010.07 05:05',
				'',
				'Регистрация',
				'Мухамадеева Е.В.Беженцева С.А.Аницаева О.А.Чухланцева Е.Н.Казакова А.К.Булычева А.Н.Иванова Н.А.Денисова М.С.',
				'20:03',
				'04:22',
				'34,35,36,37',
				'13',
				'157',
				'',
				'/',
				'499',
			],
			[
				'Вылет',
				'U6-149',
				'A-319/VQBTP',
				'ГЕЛЕНДЖИК',
				'10B',
				'10.07 05:0510.07 05:08',
				'10.07 04:11',
				'Посадка',
				'Келембет К.А.Рогожина Л.М.',
				'04:26',
				'04:44',
				'34,35,36,37',
				'15',
				'142',
				'',
				'/',
				'18',
			],
			[
				'Вылет',
				'U6-149',
				'A-319/VQBTP',
				'ГЕЛЕНДЖИК',
				'10B',
				'10.07 05:0510.07 05:08',
				'',
				'Регистрация',
				'Мухамадеева Е.В.Булычева А.Н.Денисова М.С.Чухланцева Е.Н.Иванова Н.А.Аницаева О.А.Казакова А.К.Беженцева С.А.',
				'20:04',
				'04:27',
				'34,35,36,37',
				'15',
				'142',
				'',
				'/',
				'503',
			],
			[
				'Вылет',
				'U6-701',
				'A-320/VQBGI',
				'ПРАГА',
				'8',
				'10.07 05:1510.07 05:17',
				'10.07 04:43',
				'Посадка',
				'Бирюкова О.В.',
				'04:45',
				'04:58',
				'10',
				'2',
				'143',
				'',
				'/',
				'13',
			],
			[
				'Вылет',
				'U6-701',
				'A-320/VQBGI',
				'ПРАГА',
				'8',
				'10.07 05:1510.07 05:17',
				'',
				'Регистрация',
				'Харинина М.С.Шукшина О.А.Носырева К.В.Васильева К.И.',
				'02:10',
				'04:37',
				'10',
				'2',
				'143',
				'',
				'/',
				'147',
			],
			[
				'Вылет',
				'U6-1',
				'A-320/VPBMW',
				'МОСКВА/SVO',
				'9',
				'10.07 05:3010.07 05:37',
				'10.07 04:59',
				'Посадка',
				'Аницаева О.А.Гусельникова А.Н.',
				'05:04',
				'05:18',
				'34,35,36,37',
				'16',
				'154',
				'',
				'/',
				'14',
			],
			[
				'Вылет',
				'U6-1',
				'A-320/VPBMW',
				'МОСКВА/SVO',
				'9',
				'10.07 05:3010.07 05:37',
				'',
				'Регистрация',
				'Чухланцева Е.Н.Булычева А.Н.Мухамадеева Е.В.Иванова Н.А.Беженцева С.А.Казакова А.К.Аницаева О.А.Денисова М.С.',
				'20:04',
				'04:51',
				'34,35,36,37',
				'16',
				'154',
				'',
				'/',
				'528',
			],
			[
				'Вылет',
				'U6-201',
				'A-320/VPBKX',
				'АНАПА',
				'13',
				'10.07 06:0010.07 06:00',
				'10.07 05:19',
				'Посадка',
				'Денисова М.С.',
				'05:22',
				'05:37',
				'35',
				'14',
				'166',
				'',
				'/',
				'15',
			],
			[
				'Вылет',
				'U6-201',
				'A-320/VPBKX',
				'АНАПА',
				'13',
				'10.07 06:0010.07 06:00',
				'',
				'Регистрация',
				'Денисова М.С.Аницаева О.А.Казакова А.К.',
				'02:55',
				'05:23',
				'35',
				'14',
				'166',
				'',
				'/',
				'148',
			],
			[
				'Вылет',
				'FV-5603',
				'B-747-400/EIXLG',
				'СОЧИ',
				'11',
				'10.07 06:3010.07 06:32',
				'10.07 05:31',
				'Посадка',
				'Бирюкова О.В.Муравьева И.А.',
				'05:32',
				'06:07',
				'24,25',
				'9',
				'531',
				'',
				'/',
				'35',
			],
			[
				'Вылет',
				'FV-5603',
				'B-747-400/EIXLG',
				'СОЧИ',
				'11',
				'10.07 06:3010.07 06:32',
				'',
				'Регистрация',
				'Васильева К.И.Волкова С.С.Муравьева И.А.Шабурова А.М.Носырева К.В.Струина Н.Г.Михайлова И.В.',
				'01:27',
				'05:50',
				'24,25',
				'9',
				'531',
				'',
				'/',
				'273',
			],
			[
				'Вылет',
				'UT-105',
				'AT-72-5/VQBLL',
				'УФА',
				'57',
				'10.07 06:3510.07 06:44',
				'10.07 06:05',
				'Посадка',
				'Масленникова К.Е.',
				'06:01',
				'06:15',
				'50',
				'16',
				'63',
				'',
				'/',
				'14',
			],
			[
				'Вылет',
				'UT-105',
				'AT-72-5/VQBLL',
				'УФА',
				'57',
				'10.07 06:3510.07 06:44',
				'',
				'Регистрация',
				'Масленникова К.Е.',
				'03:37',
				'05:55',
				'50',
				'16',
				'63',
				'',
				'/',
				'137',
			],
			[
				'Вылет',
				'S7-5016',
				'EMB-170/VQBYL',
				'НОВОСИБИРСК',
				'14',
				'10.07 06:4510.07 06:48',
				'10.07 06:15',
				'Посадка',
				'Бондарь О.Ю.',
				'06:16',
				'06:29',
				'42',
				'17',
				'72',
				'',
				'/',
				'13',
			],
			[
				'Вылет',
				'S7-5016',
				'EMB-170/VQBYL',
				'НОВОСИБИРСК',
				'14',
				'10.07 06:4510.07 06:48',
				'',
				'Регистрация',
				'Бондарь О.Ю.',
				'03:43',
				'06:05',
				'42',
				'17',
				'72',
				'',
				'/',
				'142',
			],
			[
				'Вылет',
				'U6-246',
				'A-320/VPBIE',
				'КРАСНОЯРСК',
				'12',
				'10.07 07:0010.07 07:14',
				'10.07 06:12',
				'Посадка',
				'Баранова Г.А.',
				'06:14',
				'06:31',
				'35',
				'13',
				'130',
				'',
				'/На борту работает техник',
				'17',
			],
			[
				'Вылет',
				'U6-246',
				'A-320/VPBIE',
				'КРАСНОЯРСК',
				'12',
				'10.07 07:0010.07 07:14',
				'',
				'Регистрация',
				'Аницаева О.А.Казакова А.К.Денисова М.С.',
				'03:13',
				'06:11',
				'35',
				'13',
				'130',
				'',
				'/',
				'178',
			],
			[
				'Вылет',
				'SU-1411',
				'B-737-800/VQBWF',
				'МОСКВА/SVO',
				'15',
				'10.07 07:3510.07 07:36',
				'10.07 06:56',
				'Посадка',
				'Елисеева А.В.',
				'06:57',
				'07:12',
				'45',
				'18',
				'150',
				'',
				'/',
				'0,0104166666666666',
			],
			[
				'Вылет',
				'SU-1411',
				'B-737-800/VQBWF',
				'МОСКВА/SVO',
				'15',
				'10.07 07:3510.07 07:36',
				'',
				'Регистрация',
				'Колабкина Н.В.Хрипунова Е.В. Владимирова А.И.Елисеева А.В.',
				'04:33',
				'06:55',
				'45',
				'18',
				'150',
				'',
				'/',
				'0,0986111111111111',
			],
			[
				'Вылет',
				'U6-7709',
				'A-319/VQBTY',
				'ХАРБИН',
				'10A',
				'10.07 08:4510.07 08:45',
				'10.07 08:12',
				'Посадка',
				'Бирюкова О.В.',
				'08:13',
				'08:29',
				'9,10',
				'8',
				'92',
				'',
				'/',
				'0,0111111111111111',
			],
			[
				'Вылет',
				'U6-7709',
				'A-319/VQBTY',
				'ХАРБИН',
				'10A',
				'10.07 08:4510.07 08:45',
				'',
				'Регистрация',
				'Васильева К.И.Носырева К.В.',
				'05:41',
				'08:07',
				'9,10',
				'8',
				'92',
				'',
				'/',
				'0,101388888888889',
			],
			[
				'Вылет',
				'SU-1401',
				'B-737-800/VQBHQ',
				'МОСКВА/SVO',
				'12',
				'10.07 08:5510.07 08:57',
				'10.07 08:14',
				'Посадка',
				'Колабкина Н.В.',
				'08:14',
				'08:38',
				'45',
				'13',
				'155',
				'',
				'/',
				'0,0166666666666667',
			],
			[
				'Вылет',
				'SU-1401',
				'B-737-800/VQBHQ',
				'МОСКВА/SVO',
				'12',
				'10.07 08:5510.07 08:57',
				'',
				'Регистрация',
				'Хрипунова Е.В. Владимирова А.И.Елисеева А.В.',
				'05:30',
				'08:15',
				'45',
				'13',
				'155',
				'',
				'/',
				'0,114583333333333',
			],
			[
				'Вылет',
				'N4-7655',
				'B-737-800/VPBSP',
				'ДЖЕРБА',
				'9',
				'10.07 09:1010.07 09:16',
				'10.07 08:24',
				'Посадка',
				'Келембет К.А.Рогожина Л.М.',
				'08:25',
				'08:52',
				'16',
				'3',
				'190',
				'',
				'/',
				'0,01875',
			],
			[
				'Вылет',
				'N4-7655',
				'B-737-800/VPBSP',
				'ДЖЕРБА',
				'9',
				'10.07 09:1010.07 09:16',
				'',
				'Регистрация',
				'Келембет К.А.Рогожина Л.М.',
				'05:05',
				'08:31',
				'16',
				'3',
				'190',
				'',
				'/',
				'0,143055555555556',
			],
			[
				'Вылет',
				'U6-527',
				'A-319/VPBBG',
				'СИМФЕРОПОЛЬ',
				'13',
				'10.07 09:5510.07 09:55',
				'10.07 08:54',
				'Посадка',
				'Баранова Г.А.',
				'09:05',
				'09:29',
				'34,35,36',
				'14',
				'139',
				'',
				'/',
				'0,0166666666666666',
			],
			[
				'Вылет',
				'U6-527',
				'A-319/VPBBG',
				'СИМФЕРОПОЛЬ',
				'13',
				'10.07 09:5510.07 09:55',
				'',
				'Регистрация',
				'Струина Н.Г.Кутьина Ю.К.Абдуллаева М.М.Казакова А.К.',
				'06:54',
				'09:16',
				'34,35,36',
				'14',
				'139',
				'',
				'/',
				'0,0986111111111111',
			],
			[
				'Вылет',
				'U6-321',
				'A-320/VQBRE',
				'СОЧИ',
				'15',
				'10.07 10:2010.07 10:37',
				'10.07 10:02',
				'Посадка',
				'Кутьина Ю.К.',
				'10:03',
				'10:18',
				'34,35,36',
				'18',
				'163',
				'',
				'/',
				'0,0104166666666667',
			],
			[
				'Вылет',
				'U6-321',
				'A-320/VQBRE',
				'СОЧИ',
				'15',
				'10.07 10:2010.07 10:37',
				'',
				'Регистрация',
				'Казакова А.К.Абдуллаева М.М.Струина Н.Г.Кутьина Ю.К.',
				'07:01',
				'09:26',
				'34,35,36',
				'18',
				'163',
				'',
				'/',
				'0,100694444444444',
			],
			[
				'Вылет',
				'S7-54',
				'A-319/VPBTT',
				'МОСКВА/DME',
				'12',
				'10.07 10:4010.07 10:45',
				'10.07 10:09',
				'Посадка',
				'Муравьева И.А.',
				'10:12',
				'10:26',
				'43',
				'13',
				'144',
				'',
				'/',
				'0,00972222222222224',
			],
			[
				'Вылет',
				'S7-54',
				'A-319/VPBTT',
				'МОСКВА/DME',
				'12',
				'10.07 10:4010.07 10:45',
				'',
				'Регистрация',
				'Михайлова И.В.Муравьева И.А.',
				'07:36',
				'10:08',
				'43',
				'13',
				'144',
				'',
				'/',
				'0,105555555555556',
			],
			[
				'Вылет',
				'U6-300',
				'A-320/VQBLO',
				'МОСКВА/DME',
				'14',
				'10.07 11:0010.07 11:00',
				'10.07 10:07',
				'Посадка',
				'Абдуллаева М.М.',
				'10:25',
				'10:41',
				'35',
				'17',
				'157',
				'',
				'/',
				'0,0111111111111112',
			],
			[
				'Вылет',
				'U6-300',
				'A-320/VQBLO',
				'МОСКВА/DME',
				'14',
				'10.07 11:0010.07 11:00',
				'',
				'Регистрация',
				'Кутьина Ю.К.Струина Н.Г.Казакова А.К.Абдуллаева М.М.',
				'07:55',
				'10:20',
				'35',
				'17',
				'157',
				'',
				'/',
				'0,100694444444444',
			],
			[
				'Вылет',
				'U6-7027',
				'A-320/VPBMT',
				'ЛАРНАКА',
				'16',
				'10.07 11:0510.07 11:07',
				'10.07 10:39',
				'Посадка',
				'Денисова М.С.Поликарпова А.А.',
				'10:45',
				'10:51',
				'12,11',
				'6',
				'122',
				'',
				'/',
				'0,00416666666666665',
			],
			[
				'Вылет',
				'U6-7027',
				'A-320/VPBMT',
				'ЛАРНАКА',
				'16',
				'10.07 11:0510.07 11:07',
				'',
				'Регистрация',
				'Денисова М.С.Поликарпова А.А.',
				'08:03',
				'10:26',
				'12,11',
				'6',
				'122',
				'',
				'/',
				'0,0993055555555555',
			],
			[
				'Вылет',
				'FZ-902',
				'B-737-800W/A6FEE',
				'ДУБАЙ',
				'57',
				'10.07 11:1010.07 11:20',
				'10.07 10:34',
				'Посадка',
				'Масленникова К.Е.Хашимова Ш.Р.',
				'10:44',
				'11:05',
				'17,18,19',
				'4',
				'157',
				'',
				'/',
				'0,0145833333333334',
			],
			[
				'Вылет',
				'FZ-902',
				'B-737-800W/A6FEE',
				'ДУБАЙ',
				'57',
				'10.07 11:1010.07 11:20',
				'',
				'Регистрация',
				'Хашимова Ш.Р.Масленникова К.Е.Хрипунова Е.В. ',
				'08:03',
				'10:10',
				'17,18,19',
				'4',
				'157',
				'',
				'/',
				'0,0881944444444444',
			],
			[
				'Вылет',
				'KC-272',
				'EMB-190/P4KCH',
				'НУР-СУЛТАН',
				'60',
				'10.07 11:3510.07 11:36',
				'10.07 11:02',
				'Посадка',
				'Бондарь О.Ю.',
				'11:12',
				'11:17',
				'7,',
				'6',
				'75',
				'',
				'/',
				'0,00347222222222227',
			],
			[
				'Вылет',
				'KC-272',
				'EMB-190/P4KCH',
				'НУР-СУЛТАН',
				'60',
				'10.07 11:3510.07 11:36',
				'',
				'Регистрация',
				'Бондарь О.Ю.Гусельникова А.Н.',
				'08:55',
				'10:55',
				'7,',
				'6',
				'75',
				'',
				'/',
				'0,0833333333333334',
			],
			[
				'Вылет',
				'SU-1403',
				'B-737-800/VPBKF',
				'МОСКВА/SVO',
				'13',
				'10.07 11:5010.07 11:56',
				'10.07 11:10',
				'Посадка',
				'Сорокина И.С.',
				'11:11',
				'11:37',
				'45',
				'14',
				'154',
				'',
				'/',
				'0,0180555555555555',
			],
			[
				'Вылет',
				'SU-1403',
				'B-737-800/VPBKF',
				'МОСКВА/SVO',
				'13',
				'10.07 11:5010.07 11:56',
				'',
				'Регистрация',
				'Аницаева О.А.Сорокина И.С.Саградян А.Р.',
				'08:44',
				'11:12',
				'45',
				'14',
				'154',
				'',
				'/',
				'0,102777777777778',
			],
			[
				'Вылет',
				'FV-5865',
				'B-747-400/EIXLH',
				'АНТАЛЬЯ',
				'10',
				'10.07 12:0510.07 12:22',
				'10.07 11:06',
				'Посадка',
				'Мальчева М.В.Скуратова О.В.',
				'11:06',
				'11:49',
				'5',
				'7',
				'530',
				'',
				'/',
				'0,0298611111111111',
			],
			[
				'Вылет',
				'FV-5865',
				'B-747-400/EIXLH',
				'АНТАЛЬЯ',
				'10',
				'10.07 12:0510.07 12:22',
				'',
				'Регистрация',
				'Мальчева М.В.Казакова А.К.Бирюкова О.В.Скуратова О.В.',
				'07:05',
				'11:26',
				'5',
				'7',
				'530',
				'',
				'/',
				'0,18125',
			],
			[
				'Вылет',
				'R3-490',
				'RRJ-95LR/89038',
				'ЯКУТСК',
				'15',
				'10.07 12:2010.07 12:37',
				'10.07 11:59',
				'Посадка',
				'Хрипунова Е.В. ',
				'11:59',
				'12:15',
				'40',
				'18',
				'92',
				'',
				'/',
				'0,0111111111111111',
			],
			[
				'Вылет',
				'R3-490',
				'RRJ-95LR/89038',
				'НОВОСИБИРСК',
				'15',
				'10.07 12:2010.07 12:37',
				'',
				'Регистрация',
				'Хрипунова Е.В. ',
				'10:33',
				'11:40',
				'40',
				'18',
				'92',
				'',
				'/',
				'0,0465277777777777',
			],
			[
				'Вылет',
				'U6-775',
				'A-319/VPBJV',
				'ПЕКИН',
				'56',
				'10.07 12:3010.07 12:42',
				'10.07 12:05',
				'Посадка',
				'Бирюкова О.В.Рогожина Л.М.',
				'12:13',
				'12:20',
				'10',
				'4',
				'95',
				'',
				'/',
				'0,0048611111111112',
			],
			[
				'Вылет',
				'U6-775',
				'A-319/VPBJV',
				'ПЕКИН',
				'56',
				'10.07 12:3010.07 12:42',
				'',
				'Регистрация',
				'Рогожина Л.М.Бирюкова О.В.',
				'09:27',
				'11:50',
				'10',
				'4',
				'95',
				'',
				'/',
				'0,0993055555555556',
			],
			[
				'Вылет',
				'S7-5018',
				'EMB-170/VQBYC',
				'НОВОСИБИРСК',
				'14',
				'10.07 12:5010.07 13:05',
				'10.07 12:30',
				'Посадка',
				'Михайлова И.В.',
				'12:32',
				'12:42',
				'43',
				'17',
				'71',
				'',
				'/',
				'0,00694444444444442',
			],
			[
				'Вылет',
				'S7-5018',
				'EMB-170/VQBYC',
				'НОВОСИБИРСК',
				'14',
				'10.07 12:5010.07 13:05',
				'',
				'Регистрация',
				'Муравьева И.А.Михайлова И.В.',
				'07:36',
				'11:45',
				'43',
				'17',
				'71',
				'',
				'/',
				'0,172916666666667',
			],
			[
				'Вылет',
				'7R-870',
				'CRJ-100/VPBVC',
				'СЫКТЫВКАР',
				'52',
				'10.07 13:0010.07 13:01',
				'10.07 12:24',
				'Посадка',
				'Струина Н.Г.',
				'12:14',
				'12:39',
				'50',
				'16',
				'49',
				'',
				'/',
				'0,0173611111111112',
			],
			[
				'Вылет',
				'7R-870',
				'CRJ-100/VPBVC',
				'СЫКТЫВКАР',
				'52',
				'10.07 13:0010.07 13:01',
				'',
				'Регистрация',
				'Струина Н.Г.',
				'10:00',
				'12:20',
				'50',
				'16',
				'49',
				'',
				'/',
				'0,0972222222222223',
			],
			[
				'Вылет',
				'ZF-423',
				'B-767-300/VQBEN',
				'БОДРУМ/М',
				'9',
				'10.07 13:0010.07 13:10',
				'10.07 12:23',
				'Посадка',
				'Елисеева А.В.Келембет К.А.',
				'12:24',
				'12:43',
				'14',
				'3',
				'339',
				'',
				'/',
				'0,0131944444444444',
			],
			[
				'Вылет',
				'ZF-423',
				'B-767-300/VQBEN',
				'БОДРУМ/М',
				'9',
				'10.07 13:0010.07 13:10',
				'',
				'Регистрация',
				'Елисеева А.В.Рогожина Л.М.Келембет К.А.',
				'08:05',
				'12:21',
				'14',
				'3',
				'339',
				'',
				'/',
				'0,177777777777778',
			],
			[
				'Вылет',
				'FV-5629',
				'B-737-800/VQBSS',
				'АНАПА',
				'11',
				'10.07 13:0510.07 13:08',
				'10.07 12:34',
				'Посадка',
				'Курдоякова А.Р.',
				'12:34',
				'12:52',
				'25',
				'9',
				'185',
				'',
				'/',
				'0,0125',
			],
			[
				'Вылет',
				'FV-5629',
				'B-737-800/VQBSS',
				'АНАПА',
				'11',
				'10.07 13:0510.07 13:08',
				'',
				'Регистрация',
				'Курдоякова А.Р.Колабкина Н.В.',
				'10:01',
				'12:25',
				'25',
				'9',
				'185',
				'',
				'/',
				'0,0999999999999999',
			],
			[
				'Вылет',
				'ZF-9633',
				'B-767-300/VQBSY',
				'ПХУКЕТ',
				'8',
				'10.07 13:2510.07 13:32',
				'10.07 12:37',
				'Посадка',
				'Васильева К.И.Саламатова О.О.',
				'12:38',
				'13:09',
				'20,21',
				'2',
				'340',
				'',
				'/',
				'0,0215277777777778',
			],
			[
				'Вылет',
				'ZF-9633',
				'B-767-300/VQBSY',
				'ПХУКЕТ',
				'8',
				'10.07 13:2510.07 13:32',
				'',
				'Регистрация',
				'Васильева К.И.Курдоякова А.Р.Носырева К.В.',
				'08:53',
				'12:46',
				'20,21',
				'2',
				'340',
				'',
				'/',
				'0,161805555555556',
			],
			[
				'Вылет',
				'U6-173',
				'A-319/VQBTP',
				'ХАБАРОВСК/Н',
				'12',
				'10.07 13:3510.07 13:38',
				'10.07 12:51',
				'Посадка',
				'Бирюкова О.В.',
				'12:54',
				'13:11',
				'36',
				'13',
				'130',
				'',
				'/',
				'0,0118055555555556',
			],
			[
				'Вылет',
				'U6-173',
				'A-319/VQBTP',
				'ХАБАРОВСК/Н',
				'12',
				'10.07 13:3510.07 13:38',
				'',
				'Регистрация',
				'Абдуллаева М.М.',
				'10:39',
				'12:57',
				'36',
				'13',
				'130',
				'',
				'/',
				'0,0958333333333333',
			],
			[
				'Вылет',
				'SU-1413',
				'B-737-800/VPBPF',
				'МОСКВА/SVO',
				'15',
				'10.07 13:5510.07 14:12',
				'10.07 13:15',
				'Посадка',
				'Колабкина Н.В.',
				'13:20',
				'13:53',
				'45',
				'18',
				'148',
				'',
				'/',
				'0,0229166666666666',
			],
			[
				'Вылет',
				'SU-1413',
				'B-737-800/VPBPF',
				'МОСКВА/SVO',
				'15',
				'10.07 13:5510.07 14:12',
				'',
				'Регистрация',
				'Аницаева О.А.Сорокина И.С.Саградян А.Р.',
				'08:47',
				'13:05',
				'45',
				'18',
				'148',
				'',
				'/',
				'0,179166666666667',
			],
			[
				'Вылет',
				'U6-266',
				'A-320/VPBKX',
				'МОСКВА/DME',
				'14',
				'10.07 14:2510.07 14:39',
				'10.07 13:53',
				'Посадка',
				'Келембет К.А.',
				'13:53',
				'14:24',
				'36',
				'17',
				'151',
				'',
				'/',
				'0,0215277777777778',
			],
			[
				'Вылет',
				'U6-266',
				'A-320/VPBKX',
				'МОСКВА/DME',
				'14',
				'10.07 14:2510.07 14:39',
				'',
				'Регистрация',
				'Абдуллаева М.М.',
				'10:41',
				'13:01',
				'36',
				'17',
				'151',
				'',
				'/',
				'0,0972222222222223',
			],
			[
				'Вылет',
				'S7-56',
				'A-321/VQBQI',
				'МОСКВА/DME',
				'11',
				'10.07 15:0010.07 15:12',
				'10.07 14:31',
				'Посадка',
				'Хрипунова Е.В. ',
				'14:31',
				'14:51',
				'43',
				'9',
				'182',
				'',
				'/',
				'0,0138888888888888',
			],
			[
				'Вылет',
				'S7-56',
				'A-321/VQBQI',
				'МОСКВА/DME',
				'11',
				'10.07 15:0010.07 15:12',
				'',
				'Регистрация',
				'Владимирова А.И.Хрипунова Е.В. ',
				'11:59',
				'14:20',
				'43',
				'9',
				'182',
				'',
				'/',
				'0,0979166666666667',
			],
			[
				'Вылет',
				'FV-5803',
				'B-737-800/VPBOA',
				'ЛАРНАКА',
				'10B',
				'10.07 15:1510.07 15:20',
				'10.07 14:29',
				'Посадка',
				'Бондарь О.Ю.',
				'14:29',
				'14:48',
				'4',
				'7',
				'189',
				'',
				'/',
				'0,0131944444444445',
			],
			[
				'Вылет',
				'FV-5803',
				'B-737-800/VPBOA',
				'ЛАРНАКА',
				'10B',
				'10.07 15:1510.07 15:20',
				'',
				'Регистрация',
				'Бондарь О.Ю.Казакова А.К.',
				'12:15',
				'14:36',
				'4',
				'7',
				'189',
				'',
				'/',
				'0,0979166666666667',
			],
			[
				'Вылет',
				'7R-861',
				'CRJ-200/VQBFB',
				'КАЗАНЬ',
				'51',
				'10.07 15:3010.07 15:36',
				'10.07 15:05',
				'Посадка',
				'Родина С.В.',
				'15:01',
				'15:09',
				'49,50',
				'15',
				'50',
				'',
				'/',
				'0,00555555555555554',
			],
			[
				'Вылет',
				'7R-861',
				'CRJ-200/VQBFB',
				'КАЗАНЬ',
				'51',
				'10.07 15:3010.07 15:36',
				'10.07 15:05',
				'Посадка',
				'Специалист 1',
				'15:11',
				'15:17',
				'49,50',
				'15',
				'50',
				'',
				'/',
				'0,00416666666666665',
			],
			[
				'Вылет',
				'7R-861',
				'CRJ-200/VQBFB',
				'КАЗАНЬ',
				'51',
				'10.07 15:3010.07 15:36',
				'',
				'Регистрация',
				'Струина Н.Г.Гусельникова А.Н.',
				'12:00',
				'14:21',
				'49,50',
				'15',
				'50',
				'',
				'/',
				'0,0979166666666667',
			],
			[
				'Вылет',
				'SU-1405',
				'B-737-800/VQBHU',
				'МОСКВА/SVO',
				'12',
				'10.07 15:5010.07 16:07',
				'10.07 15:32',
				'Посадка',
				'Манькова Е.И.',
				'15:33',
				'15:47',
				'45',
				'13',
				'144',
				'',
				'/',
				'0,00972222222222219',
			],
			[
				'Вылет',
				'SU-1405',
				'B-737-800/VQBHU',
				'МОСКВА/SVO',
				'12',
				'10.07 15:5010.07 16:07',
				'',
				'Регистрация',
				'Ибрагимова Л.Н.Усова К.Б.Хашимова Ш.Р.Колчанова В.Б.Масленникова К.Е.',
				'12:46',
				'15:10',
				'45',
				'13',
				'144',
				'',
				'/',
				'0,1',
			],
			[
				'Вылет',
				'UT-112',
				'AT-72-5/VQBLL',
				'СУРГУТ',
				'56',
				'10.07 16:0510.07 16:11',
				'10.07 15:34',
				'Посадка',
				'Полыгалова А.С.',
				'15:28',
				'15:44',
				'49,50',
				'15',
				'64',
				'',
				'/',
				'0,0111111111111111',
			],
			[
				'Вылет',
				'UT-112',
				'AT-72-5/VQBLL',
				'СУРГУТ',
				'56',
				'10.07 16:0510.07 16:11',
				'',
				'Регистрация',
				'Минюхина А.С.Гусельникова А.Н.Струина Н.Г.Осмехина Е.Д.',
				'13:06',
				'15:25',
				'49,50',
				'15',
				'64',
				'',
				'/',
				'0,0965277777777778',
			],
			[
				'Вылет',
				'EO-374',
				'EMB-190/VPBZH',
				'МОСКВА/SVO',
				'11',
				'10.07 16:5010.07 17:02',
				'10.07 16:27',
				'Посадка',
				'Мурадян А.Н.',
				'16:28',
				'16:38',
				'41',
				'9',
				'89',
				'',
				'/',
				'0,00694444444444453',
			],
			[
				'Вылет',
				'EO-374',
				'EMB-190/VPBZH',
				'МОСКВА/SVO',
				'11',
				'10.07 16:5010.07 17:02',
				'',
				'Регистрация',
				'Михайлова И.В.Муравьева И.А.Мурадян А.Н.',
				'11:29',
				'15:55',
				'41',
				'9',
				'89',
				'',
				'/',
				'0,184722222222222',
			],
			[
				'Вылет',
				'UT-279',
				'AT-72-5/VQBLH',
				'САМАРА',
				'58',
				'10.07 17:0010.07 17:07',
				'10.07 16:23',
				'Посадка',
				'Ерохина В.В.',
				'16:47',
				'16:52',
				'49,50',
				'16',
				'70',
				'',
				'/',
				'0,00347222222222221',
			],
			[
				'Вылет',
				'UT-279',
				'AT-72-5/VQBLH',
				'САМАРА',
				'58',
				'10.07 17:0010.07 17:07',
				'',
				'Регистрация',
				'Гусельникова А.Н.Осмехина Е.Д.Минюхина А.С.',
				'14:01',
				'16:20',
				'49,50',
				'16',
				'70',
				'',
				'/',
				'0,0965277777777777',
			],
			[
				'Вылет',
				'U6-270',
				'A-320/VQBGI',
				'МОСКВА/DME',
				'9',
				'10.07 17:0010.07 17:16',
				'10.07 16:25',
				'Посадка',
				'Захарова Е.А.Родина С.В.',
				'16:36',
				'16:58',
				'35',
				'15',
				'151',
				'',
				'/',
				'0,0152777777777776',
			],
			[
				'Вылет',
				'U6-270',
				'A-320/VQBGI',
				'МОСКВА/DME',
				'9',
				'10.07 17:0010.07 17:16',
				'',
				'Регистрация',
				'Абдуллаева М.М.Рогожина Л.М.',
				'12:22',
				'14:50',
				'35',
				'15',
				'151',
				'',
				'/',
				'0,102777777777778',
			],
			[
				'Вылет',
				'SU-1415',
				'B-737-800/VPBMD',
				'МОСКВА/SVO',
				'12',
				'10.07 18:0010.07 18:03',
				'10.07 17:22',
				'Посадка',
				'Усова К.Б.',
				'17:23',
				'17:40',
				'45',
				'13',
				'153',
				'',
				'/',
				'0,0118055555555556',
			],
			[
				'Вылет',
				'SU-1415',
				'B-737-800/VPBMD',
				'МОСКВА/SVO',
				'12',
				'10.07 18:0010.07 18:03',
				'',
				'Регистрация',
				'Ибрагимова Л.Н.Усова К.Б.Колчанова В.Б.',
				'15:02',
				'17:20',
				'45',
				'13',
				'153',
				'',
				'/',
				'0,0958333333333333',
			],
			[
				'Вылет',
				'7R-845',
				'CRJ-100/VPBVC',
				'КАЛУГА',
				'52',
				'10.07 18:0010.07 18:05',
				'10.07 17:32',
				'Посадка',
				'Родина С.В.',
				'17:24',
				'17:39',
				'49',
				'15',
				'50',
				'',
				'/',
				'0,0104166666666666',
			],
			[
				'Вылет',
				'7R-845',
				'CRJ-100/VPBVC',
				'КАЛУГА',
				'52',
				'10.07 18:0010.07 18:05',
				'',
				'Регистрация',
				'Осмехина Е.Д.Минюхина А.С.',
				'15:13',
				'17:20',
				'49',
				'15',
				'50',
				'',
				'/',
				'0,0881944444444445',
			],
			[
				'Вылет',
				'UT-114',
				'AT-72-5/VQBLK',
				'НИЖНЕВАРТОВСК',
				'57',
				'10.07 18:2510.07 18:28',
				'10.07 17:47',
				'Посадка',
				'Осмехина Е.Д.',
				'17:45',
				'17:58',
				'49',
				'16',
				'40',
				'',
				'/',
				'0,00902777777777763',
			],
			[
				'Вылет',
				'UT-114',
				'AT-72-5/VQBLK',
				'НИЖНЕВАРТОВСК',
				'57',
				'10.07 18:2510.07 18:28',
				'',
				'Регистрация',
				'Минюхина А.С.Осмехина Е.Д.',
				'15:14',
				'17:25',
				'49',
				'16',
				'40',
				'',
				'/',
				'0,0909722222222223',
			],
			[
				'Вылет',
				'U6-299',
				'A-320/VQBLO',
				'БЛАГОВЕЩЕНСК',
				'14',
				'10.07 18:5510.07 19:05',
				'10.07 18:15',
				'Посадка',
				'Мурадян А.Н.',
				'18:19',
				'18:38',
				'35,36',
				'17',
				'156',
				'',
				'/',
				'0,0131944444444445',
			],
			[
				'Вылет',
				'U6-299',
				'A-320/VQBLO',
				'БЛАГОВЕЩЕНСК',
				'14',
				'10.07 18:5510.07 19:05',
				'',
				'Регистрация',
				'Вотинцева М.А.',
				'15:53',
				'18:15',
				'35,36',
				'17',
				'156',
				'',
				'/',
				'0,0986111111111111',
			],
			[
				'Вылет',
				'U6-2953',
				'A-320/VQBRE',
				'ДУШАНБЕ',
				'10B',
				'10.07 19:0010.07 19:10',
				'10.07 18:40',
				'Посадка',
				'Захарова Е.А.',
				'18:41',
				'18:57',
				'14',
				'7',
				'102',
				'',
				'/',
				'0,0111111111111111',
			],
			[
				'Вылет',
				'U6-2953',
				'A-320/VQBRE',
				'ДУШАНБЕ',
				'10B',
				'10.07 19:0010.07 19:10',
				'',
				'Регистрация',
				'Козлова М.В.Захарова Е.А.',
				'15:15',
				'17:35',
				'14',
				'7',
				'102',
				'',
				'/',
				'0,0972222222222222',
			],
			[
				'Вылет',
				'S7-5020',
				'EMB-170/VQBYL',
				'НОВОСИБИРСК',
				'15',
				'10.07 19:3510.07 19:38',
				'10.07 19:10',
				'Посадка',
				'Манькова Е.И.',
				'19:10',
				'19:23',
				'42',
				'18',
				'73',
				'',
				'/',
				'0,00902777777777775',
			],
			[
				'Вылет',
				'S7-5020',
				'EMB-170/VQBYL',
				'НОВОСИБИРСК',
				'15',
				'10.07 19:3510.07 19:38',
				'',
				'Регистрация',
				'Манькова Е.И.',
				'16:36',
				'18:55',
				'42',
				'18',
				'73',
				'',
				'/',
				'0,0965277777777778',
			],
			[
				'Вылет',
				'N4-1717',
				'B-777-200/VPBJG',
				'ИРАКЛИОН',
				'7',
				'10.07 20:4510.07 21:05',
				'10.07 20:13',
				'Посадка',
				'Родина С.В.Мурадян А.Н.',
				'20:14',
				'20:36',
				'3',
				'1',
				'447',
				'',
				'/Загрузка багажа',
				'0,0152777777777778',
			],
			[
				'Вылет',
				'N4-1717',
				'B-777-200/VPBJG',
				'ИРАКЛИОН',
				'7',
				'10.07 20:4510.07 21:05',
				'',
				'Регистрация',
				'Кузнецова К.В.Серетюк Е.О.Полыгалова А.С.Путина К.И.',
				'16:35',
				'20:05',
				'3',
				'1',
				'447',
				'',
				'/',
				'0,145833333333333',
			],
			[
				'Вылет',
				'U6-2955',
				'A-320/VPBMW',
				'ХУДЖАНД',
				'10A',
				'10.07 21:2510.07 21:27',
				'10.07 20:53',
				'Посадка',
				'Козлова М.В.',
				'20:55',
				'21:06',
				'14,15',
				'8',
				'107',
				'',
				'/',
				'0,00763888888888897',
			],
			[
				'Вылет',
				'U6-2955',
				'A-320/VPBMW',
				'ХУДЖАНД',
				'10A',
				'10.07 21:2510.07 21:27',
				'',
				'Регистрация',
				'Захарова Е.А.Козлова М.В.',
				'17:21',
				'20:45',
				'14,15',
				'8',
				'107',
				'',
				'/',
				'0,141666666666667',
			],
			[
				'Вылет',
				'XC-8182',
				'B-737-800/TCTJI',
				'АНТАЛЬЯ',
				'9',
				'10.07 22:4510.07 22:50',
				'10.07 21:48',
				'Посадка',
				'Колчанова В.Б.',
				'21:48',
				'22:08',
				'1',
				'3',
				'191',
				'',
				'/',
				'0,0138888888888888',
			],
			[
				'Вылет',
				'XC-8182',
				'B-737-800/TCTJI',
				'АНТАЛЬЯ',
				'9',
				'10.07 22:4510.07 22:50',
				'',
				'Регистрация',
				'Колчанова В.Б.Ибрагимова Л.Н.Усова К.Б.',
				'19:29',
				'21:50',
				'1',
				'3',
				'191',
				'',
				'/',
				'0,0979166666666667',
			],
			[
				'Вылет',
				'UT-280',
				'AT-72-5/VQBLH',
				'ТЮМЕНЬ',
				'60',
				'10.07 22:5010.07 23:05',
				'10.07 22:24',
				'Посадка',
				'Зварич Н.В.',
				'22:44',
				'22:46',
				'50',
				'15',
				'33',
				'',
				'/Снятие багажа пассажиров',
				'0,00138888888888877',
			],
			[
				'Вылет',
				'UT-280',
				'AT-72-5/VQBLH',
				'ТЮМЕНЬ',
				'60',
				'10.07 22:5010.07 23:05',
				'',
				'Регистрация',
				'Манькова Е.И.',
				'19:49',
				'22:10',
				'50',
				'15',
				'33',
				'',
				'/',
				'0,0979166666666668',
			],
			[
				'Вылет',
				'U6-661',
				'A-320/VPBMT',
				'СИМФЕРОПОЛЬ',
				'58',
				'10.07 23:0010.07 23:15',
				'10.07 22:10',
				'Посадка',
				'Свалова Т.Ф.Мезенцева Е.М.',
				'22:34',
				'22:43',
				'35,36,37',
				'10',
				'157',
				'',
				'/',
				'0,00624999999999998',
			],
			[
				'Вылет',
				'U6-661',
				'A-320/VPBMT',
				'СИМФЕРОПОЛЬ',
				'58',
				'10.07 23:0010.07 23:15',
				'',
				'Регистрация',
				'Шаврова О.О.Вотинцева М.А.',
				'19:52',
				'22:20',
				'35,36,37',
				'10',
				'157',
				'',
				'/',
				'0,102777777777778',
			],
			[
				'Вылет',
				'7R-823',
				'CRJ-200/VQBFB',
				'ТОМСК',
				'51',
				'11.07 00:0511.07 00:14',
				'10.07 23:42',
				'Посадка',
				'Манькова Е.И.',
				'23:40',
				'23:48',
				'50',
				'16',
				'51',
				'',
				'/',
				'0,00555555555555554',
			],
			[
				'Вылет',
				'7R-823',
				'CRJ-200/VQBFB',
				'ТОМСК',
				'51',
				'11.07 00:0511.07 00:14',
				'',
				'Регистрация',
				'Манькова Е.И.',
				'19:53',
				'22:15',
				'50',
				'16',
				'51',
				'',
				'/',
				'0,0986111111111112',
			],
			[
				'Вылет',
				'EO-174',
				'B-737-800/VPBPY',
				'МОСКВА/SVO',
				'56',
				'11.07 00:0511.07 00:19',
				'10.07 23:40',
				'Посадка',
				'Мурадян А.Н.Анисимова О.С.',
				'23:39',
				'23:58',
				'41',
				'15',
				'166',
				'',
				'/',
				'0,0131944444444444',
			],
			[
				'Вылет',
				'EO-174',
				'B-737-800/VPBPY',
				'МОСКВА/SVO',
				'56',
				'11.07 00:0511.07 00:19',
				'',
				'Регистрация',
				'Капасина Е.Б.Мурадян А.Н.Анисимова О.С.',
				'21:00',
				'23:25',
				'41',
				'15',
				'166',
				'',
				'/',
				'0,100694444444445',
			],
			[
				'Вылет',
				'5N-9309',
				'B-737-800/VQBEE',
				'ДАЛАМАН',
				'10A',
				'11.07 00:0511.07 00:23',
				'',
				'Регистрация',
				'Партина О.А.Серетюк Е.О.Ерохина В.В.',
				'19:59',
				'22:50',
				'17,18',
				'8',
				'177',
				'',
				'/',
				'0,11875',
			],
			[
				'Вылет',
				'DP-563',
				'B-737-800/VQBWH',
				'КРАСНОДАР',
				'13',
				'11.07 00:0511.07 00:26',
				'',
				'Регистрация',
				'Акимова В.М.Никитина В.А.Маковецкая А.Р.',
				'21:00',
				'23:25',
				'24,25,26',
				'14',
				'186',
				'',
				'/',
				'0,100694444444445',
			],
			[
				'Вылет',
				'SU-1409',
				'A-320/VQBTX',
				'МОСКВА/SVO',
				'11',
				'11.07 00:1511.07 00:30',
				'',
				'Регистрация',
				'Караматова Е.Г.Карпова Т.П.Валиева Э.З.Родина С.В.Пчельникова Е.В.',
				'21:12',
				'23:35',
				'45,46',
				'9',
				'152',
				'',
				'/',
				'0,0993055555555555',
			],
			[
				'Вылет',
				'DP-541',
				'B-737-800W/VPBPX',
				'МИН  ВОДЫ',
				'12',
				'11.07 00:3011.07 00:36',
				'',
				'Регистрация',
				'Ярославцева Е.С.Шукшина О.А.Филиппова Н.А.',
				'21:23',
				'23:50',
				'27,28,29',
				'13',
				'184',
				'',
				'/',
				'0,102083333333333',
			],
			[
				'Вылет',
				'U6-201',
				'A-320/VQBCY',
				'АНАПА',
				'14',
				'11.07 00:3511.07 00:47',
				'',
				'Регистрация',
				'Кузнецова К.В.Булычева А.Н.Путина К.И.',
				'21:27',
				'23:45',
				'32,33,34',
				'17',
				'166',
				'',
				'/',
				'0,0958333333333334',
			],
			[
				'Прилет',
				'AY-725',
				'EMB-190/OHLKE',
				'ХЕЛЬСИНКИ',
				'9',
				'10.07 00:0110.07 00:01',
				'',
				'Обслуживание/Прилет',
				'Шабурова А.М.',
				'00:07',
				'00:15',
				'',
				'',
				'102',
				'102',
				'/',
				'0,00555555555555555',
			],
			[
				'Прилет',
				'U6-2954',
				'A-319/VQBTY',
				'ДУШАНБЕ',
				'10A',
				'10.07 00:1110.07 00:11',
				'',
				'Обслуживание/Прилет',
				'Шукшина О.А.',
				'00:17',
				'00:27',
				'',
				'',
				'154',
				'144',
				'/',
				'0,00694444444444444',
			],
			[
				'Прилет',
				'SU-1436',
				'A-320/VPBCE',
				'МОСКВА/SVO',
				'13',
				'10.07 00:5510.07 00:53',
				'',
				'Обслуживание/Прилет',
				'Карагодин Е.В.',
				'01:02',
				'01:12',
				'',
				'',
				'153',
				'153',
				'/',
				'0,00694444444444443',
			],
			[
				'Прилет',
				'DP-564',
				'B-737-800W/VQBWG',
				'КРАСНОДАР',
				'14',
				'10.07 01:3510.07 01:31',
				'',
				'Обслуживание/Прилет',
				'Банных А.Г.',
				'01:41',
				'01:50',
				'',
				'',
				'187',
				'187',
				'/',
				'0,00625000000000001',
			],
			[
				'Прилет',
				'U6-365',
				'A-320/VPBTZ',
				'С-ПЕТЕРБУРГ',
				'15',
				'10.07 01:5010.07 01:49',
				'',
				'Обслуживание/Прилет',
				'Ярославцева Е.С.',
				'01:56',
				'02:06',
				'',
				'',
				'165',
				'112',
				'/',
				'0,00694444444444445',
			],
			[
				'Прилет',
				'U6-2904',
				'A-319/VQBTP',
				'ОШ',
				'10B',
				'10.07 02:3010.07 02:31',
				'',
				'Обслуживание/Прилет',
				'Акимова В.М.',
				'02:38',
				'02:46',
				'',
				'',
				'132',
				'125',
				'/',
				'0,00555555555555555',
			],
			[
				'Прилет',
				'U6-2986',
				'A-320/VPBMW',
				'БИШКЕК/М',
				'9',
				'10.07 03:5410.07 03:54',
				'',
				'Обслуживание/Прилет',
				'Елисеева А.В.',
				'04:03',
				'04:13',
				'',
				'',
				'137',
				'124',
				'/',
				'0,00694444444444448',
			],
			[
				'Прилет',
				'FV-5604',
				'B-747-400/EIXLG',
				'СОЧИ',
				'11',
				'10.07 04:0010.07 03:57',
				'',
				'Обслуживание/Прилет',
				'Колабкина Н.В.',
				'04:10',
				'04:20',
				'',
				'',
				'528',
				'528',
				'/',
				'0,00694444444444442',
			],
			[
				'Прилет',
				'U6-210',
				'A-320/VPBKX',
				'КРАСНОДАР',
				'13',
				'10.07 04:3510.07 04:31',
				'',
				'Обслуживание/Прилет',
				'Маркин А.А.',
				'04:38',
				'04:48',
				'',
				'',
				'172',
				'164',
				'/',
				'0,00694444444444445',
			],
			[
				'Прилет',
				'U6-273',
				'A-320/VPBIE',
				'МОСКВА/DME',
				'12',
				'10.07 05:2510.07 05:25',
				'',
				'Обслуживание/Прилет',
				'Курдоякова А.Р.',
				'05:32',
				'05:38',
				'',
				'',
				'163',
				'143',
				'/',
				'0,00416666666666665',
			],
			[
				'Прилет',
				'S7-5015',
				'EMB-170/VQBYL',
				'НОВОСИБИРСК',
				'14',
				'10.07 05:4510.07 05:51',
				'',
				'Обслуживание/Прилет',
				'Корвацкий В.А.',
				'05:59',
				'06:06',
				'',
				'',
				'80',
				'80',
				'/',
				'0,00486111111111109',
			],
			[
				'Прилет',
				'SU-1410',
				'B-737-800/VQBWF',
				'МОСКВА/SVO',
				'15',
				'10.07 05:5010.07 05:46',
				'',
				'Обслуживание/Прилет',
				'Хрипунова Е.В. ',
				'05:52',
				'06:02',
				'',
				'',
				'138',
				'138',
				'/',
				'0,00694444444444442',
			],
			[
				'Прилет',
				'N4-1758',
				'B-737-800/VPBSP',
				'МОНАСТИР',
				'9',
				'10.07 06:5510.07 06:55',
				'',
				'Обслуживание/Прилет',
				'Бирюкова О.В.',
				'07:03',
				'07:10',
				'',
				'',
				'190',
				'190',
				'/',
				'0,00486111111111109',
			],
			[
				'Прилет',
				'U6-78',
				'A-319/VPBBG',
				'СИМФЕРОПОЛЬ',
				'13',
				'10.07 07:1810.07 07:18',
				'',
				'Обслуживание/Прилет',
				'Поликарпова А.А.',
				'07:26',
				'07:33',
				'',
				'',
				'93',
				'93',
				'/',
				'0,00486111111111109',
			],
			[
				'Прилет',
				'SU-1400',
				'B-737-800/VQBHQ',
				'МОСКВА/SVO',
				'12',
				'10.07 07:4010.07 07:38',
				'',
				'Обслуживание/Прилет',
				'Саградян А.Р.',
				'07:44',
				'07:54',
				'',
				'',
				'134',
				'134',
				'/',
				'0,00694444444444442',
			],
			[
				'Прилет',
				'U6-300',
				'A-320/VQBLO',
				'БЛАГОВЕЩЕНСК',
				'14',
				'10.07 08:0110.07 08:01',
				'',
				'Обслуживание/Прилет',
				'Сорокина И.С.',
				'08:08',
				'08:18',
				'',
				'',
				'198',
				'62',
				'/',
				'0,00694444444444453',
			],
			[
				'Прилет',
				'U6-2932',
				'A-319/VPBJV',
				'СИМФЕРОПОЛЬ',
				'11',
				'10.07 08:3010.07 08:17',
				'',
				'Обслуживание/Прилет',
				'Бондарь О.Ю.',
				'08:24',
				'08:38',
				'',
				'',
				'129',
				'123',
				'/',
				'0,00972222222222219',
			],
			[
				'Прилет',
				'U6-261',
				'A-320/VQBRE',
				'МОСКВА/DME',
				'15',
				'10.07 09:0510.07 09:06',
				'',
				'Обслуживание/Прилет',
				'Корвацкий В.А.',
				'09:16',
				'09:24',
				'',
				'',
				'169',
				'144',
				'/',
				'0,00555555555555554',
			],
			[
				'Прилет',
				'FV-5866',
				'B-747-400/EIXLH',
				'АНТАЛЬЯ',
				'10',
				'10.07 09:1010.07 09:02',
				'',
				'Обслуживание/Прилет',
				'Аверкиев И.С.',
				'09:13',
				'09:25',
				'',
				'',
				'528',
				'528',
				'/',
				'0,0083333333333333',
			],
			[
				'Прилет',
				'S7-53',
				'A-319/VPBTT',
				'МОСКВА/DME',
				'12',
				'10.07 09:3010.07 09:28',
				'',
				'Обслуживание/Прилет',
				'Прокопьев Н.С.',
				'09:34',
				'09:40',
				'',
				'',
				'124',
				'124',
				'/',
				'0,00416666666666665',
			],
			[
				'Прилет',
				'ZF-9644',
				'B-767-300/VQBSY',
				'НЯЧАНГ',
				'8',
				'10.07 10:1010.07 10:12',
				'',
				'Обслуживание/Прилет',
				'Бирюкова О.В.',
				'10:23',
				'10:33',
				'',
				'',
				'338',
				'338',
				'/',
				'0,00694444444444453',
			],
			[
				'Прилет',
				'SU-1402',
				'B-737-800/VPBKF',
				'МОСКВА/SVO',
				'13',
				'10.07 10:2410.07 10:21',
				'',
				'Обслуживание/Прилет',
				'Прокопьев Н.С.',
				'10:32',
				'10:39',
				'',
				'',
				'125',
				'125',
				'/',
				'0,00486111111111115',
			],
			[
				'Прилет',
				'ZF-9696',
				'B-767-300/VQBEN',
				'АНТАЛЬЯ',
				'9',
				'10.07 10:5610.07 10:56',
				'',
				'Обслуживание/Прилет',
				'Бирюкова О.В.',
				'11:05',
				'11:15',
				'',
				'',
				'341',
				'341',
				'/',
				'0,00694444444444442',
			],
			[
				'Прилет',
				'R3-489',
				'RRJ-95LR/89038',
				'НОВОСИБИРСК',
				'15',
				'10.07 11:3410.07 11:32',
				'',
				'Обслуживание/Прилет',
				'Поликарпова А.А.',
				'11:41',
				'11:53',
				'',
				'',
				'103',
				'102',
				'/',
				'0,0083333333333333',
			],
			[
				'Прилет',
				'FV-5768',
				'B-737-800/VQBSS',
				'ЭНФИДА',
				'11',
				'10.07 11:4010.07 11:34',
				'',
				'Обслуживание/Прилет',
				'Аверкиев И.С.',
				'11:42',
				'11:50',
				'',
				'',
				'188',
				'188',
				'/',
				'0,00555555555555559',
			],
			[
				'Прилет',
				'U6-150',
				'A-319/VQBTP',
				'ГЕЛЕНДЖИК',
				'12',
				'10.07 11:4110.07 11:40',
				'',
				'Обслуживание/Прилет',
				'Хашимова Ш.Р.',
				'11:49',
				'11:56',
				'',
				'',
				'131',
				'131',
				'/',
				'0,00486111111111115',
			],
			[
				'Прилет',
				'S7-5017',
				'EMB-170/VQBYC',
				'НОВОСИБИРСК',
				'14',
				'10.07 11:4510.07 11:48',
				'',
				'Обслуживание/Прилет',
				'Масленникова К.Е.',
				'11:53',
				'12:00',
				'',
				'',
				'77',
				'77',
				'/',
				'0,00486111111111115',
			],
			[
				'Прилет',
				'U6-173',
				'A-320/VQBFW',
				'С-ПЕТЕРБУРГ',
				'13',
				'10.07 11:5010.07 11:50',
				'',
				'Обслуживание/Прилет',
				'Маркин А.А.',
				'11:57',
				'12:06',
				'',
				'',
				'161',
				'116',
				'/',
				'0,00625000000000003',
			],
			[
				'Прилет',
				'FV-5630',
				'B-737-800/VPBOA',
				'АНАПА',
				'10B',
				'10.07 12:2010.07 12:18',
				'',
				'Обслуживание/Прилет',
				'Поликарпова А.А.',
				'12:24',
				'12:33',
				'',
				'',
				'192',
				'192',
				'/',
				'0,00624999999999998',
			],
			[
				'Прилет',
				'SU-1412',
				'B-737-800/VPBPF',
				'МОСКВА/SVO',
				'15',
				'10.07 12:2510.07 12:25',
				'',
				'Обслуживание/Прилет',
				'Аверкиев И.С.',
				'12:24',
				'12:44',
				'',
				'',
				'134',
				'134',
				'/',
				'0,0138888888888888',
			],
			[
				'Прилет',
				'U6-202',
				'A-320/VPBKX',
				'АНАПА',
				'14',
				'10.07 12:5110.07 12:49',
				'',
				'Обслуживание/Прилет',
				'Корвацкий В.А.',
				'13:01',
				'13:09',
				'',
				'',
				'169',
				'169',
				'/',
				'0,00555555555555554',
			],
			[
				'Прилет',
				'S7-55',
				'A-321/VQBQI',
				'МОСКВА/DME',
				'11',
				'10.07 13:5510.07 13:58',
				'',
				'Обслуживание/Прилет',
				'Аницаева О.А.',
				'14:06',
				'14:15',
				'',
				'',
				'189',
				'189',
				'/',
				'0,00624999999999998',
			],
			[
				'Прилет',
				'SU-1404',
				'B-737-800/VQBHU',
				'МОСКВА/SVO',
				'12',
				'10.07 14:5510.07 14:53',
				'',
				'Обслуживание/Прилет',
				'Марченко А.В.',
				'15:00',
				'15:10',
				'',
				'',
				'135',
				'135',
				'/',
				'0,00694444444444442',
			],
			[
				'Прилет',
				'U6-702',
				'A-320/VQBGI',
				'ПРАГА',
				'9',
				'10.07 15:2010.07 15:17',
				'',
				'Обслуживание/Прилет',
				'Пыткеев И.П.',
				'15:27',
				'15:36',
				'',
				'',
				'132',
				'132',
				'/',
				'0,00625000000000009',
			],
			[
				'Прилет',
				'EO-373',
				'EMB-190/VPBZH',
				'МОСКВА/SVO',
				'11',
				'10.07 16:0510.07 16:04',
				'',
				'Обслуживание/Прилет',
				'Шаврова О.О.',
				'16:09',
				'16:16',
				'',
				'',
				'98',
				'98',
				'/',
				'0,00486111111111109',
			],
			[
				'Прилет',
				'SU-1414',
				'B-737-800/VPBMD',
				'МОСКВА/SVO',
				'12',
				'10.07 16:3510.07 16:34',
				'',
				'Обслуживание/Прилет',
				'Шаврова О.О.',
				'16:42',
				'16:52',
				'',
				'',
				'139',
				'139',
				'/',
				'0,00694444444444453',
			],
			[
				'Прилет',
				'U6-299',
				'A-320/VQBLO',
				'МОСКВА/DME',
				'14',
				'10.07 16:4010.07 16:41',
				'',
				'Обслуживание/Прилет',
				'Овчинников А.А.',
				'16:51',
				'16:59',
				'',
				'',
				'154',
				'71',
				'/',
				'0,00555555555555554',
			],
			[
				'Прилет',
				'N4-1842',
				'B-777-200/VPBJG',
				'АНТАЛЬЯ',
				'7',
				'10.07 17:2810.07 17:26',
				'',
				'Обслуживание/Прилет',
				'Марченко А.В.',
				'17:37',
				'17:48',
				'',
				'',
				'435',
				'435',
				'/',
				'0,00763888888888886',
			],
			[
				'Прилет',
				'U6-322',
				'A-320/VQBRE',
				'СОЧИ',
				'10B',
				'10.07 17:5010.07 17:45',
				'',
				'Обслуживание/Прилет',
				'Гочияев И.И.',
				'17:53',
				'18:01',
				'',
				'',
				'151',
				'142',
				'/',
				'0,00555555555555554',
			],
			[
				'Прилет',
				'S7-5019',
				'EMB-170/VQBYL',
				'НОВОСИБИРСК',
				'15',
				'10.07 18:4110.07 18:42',
				'',
				'Обслуживание/Прилет',
				'Пыткеев И.П.',
				'18:51',
				'18:56',
				'',
				'',
				'73',
				'73',
				'/',
				'0,0034722222222221',
			],
			[
				'Прилет',
				'U6-2',
				'A-320/VPBMW',
				'МОСКВА/SVO',
				'10A',
				'10.07 19:3010.07 19:28',
				'',
				'Обслуживание/Прилет',
				'Гочияев И.И.',
				'19:36',
				'19:44',
				'',
				'',
				'142',
				'142',
				'/',
				'0,00555555555555554',
			],
			[
				'Прилет',
				'XC-8181',
				'B-737-800/TCTJI',
				'АНТАЛЬЯ',
				'9',
				'10.07 20:5010.07 20:48',
				'',
				'Обслуживание/Прилет',
				'Гочияев И.И.',
				'20:56',
				'21:07',
				'',
				'',
				'192',
				'192',
				'/',
				'0,00763888888888886',
			],
			[
				'Прилет',
				'5N-9310',
				'B-737-800/VQBEE',
				'ДАЛАМАН',
				'10A',
				'10.07 21:5010.07 21:48',
				'',
				'Обслуживание/Прилет',
				'Свалова Т.Ф.',
				'21:56',
				'22:05',
				'',
				'',
				'189',
				'189',
				'/',
				'0,00624999999999998',
			],
			[
				'Прилет',
				'U6-7710',
				'A-319/VQBTY',
				'ХАРБИН',
				'7',
				'10.07 22:0510.07 22:02',
				'',
				'Обслуживание/Прилет',
				'Захарова Е.А.',
				'21:58',
				'22:26',
				'',
				'',
				'184',
				'113',
				'/',
				'0,0194444444444445',
			],
			[
				'Прилет',
				'DP-1403',
				'B-737-800/VPBQH',
				'МОСКВА/VKO',
				'15',
				'10.07 22:3510.07 22:36',
				'',
				'Обслуживание/Прилет',
				'Пыткеев И.П.',
				'22:48',
				'22:56',
				'',
				'',
				'194',
				'194',
				'/',
				'0,00555555555555554',
			],
			[
				'Прилет',
				'U6-265',
				'A-320/VQBCY',
				'МОСКВА/DME',
				'14',
				'10.07 22:4010.07 22:41',
				'',
				'Обслуживание/Прилет',
				'Овчинников А.А.',
				'22:50',
				'23:00',
				'',
				'',
				'140',
				'140',
				'/',
				'0,00694444444444453',
			],
			[
				'Прилет',
				'N4-7656',
				'B-737-800/VPBSP',
				'ДЖЕРБА',
				'9',
				'10.07 22:4510.07 22:46',
				'',
				'Обслуживание/Прилет',
				'Гочияев И.И.',
				'22:57',
				'23:05',
				'',
				'',
				'189',
				'189',
				'/',
				'0,00555555555555554',
			],
			[
				'Прилет',
				'SU-1408',
				'A-320/VQBTX',
				'МОСКВА/SVO',
				'11',
				'10.07 23:0010.07 23:01',
				'',
				'Обслуживание/Прилет',
				'Ерохина В.В.',
				'23:08',
				'23:15',
				'',
				'',
				'140',
				'140',
				'/',
				'0,00486111111111109',
			],
			[
				'Прилет',
				'U6-271',
				'A-321/VQBKH',
				'МОСКВА/DME',
				'10B',
				'10.07 23:1510.07 23:14',
				'',
				'Обслуживание/Прилет',
				'Пыткеев И.П.',
				'23:20',
				'23:30',
				'',
				'',
				'176',
				'176',
				'/',
				'0,00694444444444442',
			],
			[
				'Прилет',
				'DP-515',
				'B-737-800/VQBWH',
				'С-ПЕТЕРБУРГ',
				'13',
				'10.07 23:2010.07 23:17',
				'',
				'Обслуживание/Прилет',
				'Колчанова В.Б.',
				'23:28',
				'23:37',
				'',
				'',
				'192',
				'192',
				'/',
				'0,00625000000000009',
			],
			[
				'Прилет',
				'DP-542',
				'B-737-800W/VPBPX',
				'МИН  ВОДЫ',
				'12',
				'10.07 23:3510.07 23:24',
				'',
				'Обслуживание/Прилет',
				'Мезенцева Е.М.',
				'23:37',
				'23:43',
				'',
				'',
				'183',
				'183',
				'/',
				'0,00416666666666654',
			],
			[
				'Прилет',
				'ZF-424',
				'B-767-300/VQBEN',
				'БОДРУМ/М',
				'8',
				'10.07 23:3510.07 23:34',
				'',
				'Обслуживание/Прилет',
				'Свалова Т.Ф.',
				'23:45',
				'23:53',
				'',
				'',
				'339',
				'339',
				'/',
				'0,00555555555555554',
			]
		);

		const reportData3: ReportData = new ReportData();
		reportData3.id = 3;
		// reportData1.columns = ['col1', 'col2', 'col3', 'col4', 'col5', 'col6'];
		reportData3.titles = [
			'Дата',
			'Количество рейсов',
			'Номер рейса',
			'Главный/Подчиненный',
			'КодIATA АК',
			'КодICAO АК',
			'Наименование АК',
			'Наименование АК анг',
			'Тип АК',
			'Тип ВС',
			'Код ЦРТтипа ВС',
			'Краткоенаименование',
			'Бортовойномер',
			'Номерстоянки',
			'Видрейсов',
			'Тип вылета',
			'Вид движения',
			'План. датавыполнения',
			'План. времявыполнения',
			'Ожид. датавыполнения',
			'Ожид. времявыполнения',
			'Факт. датавыполнения',
			'Факт. времявыполнения',
			'Транзит',
			'Прилет/Вылет',
			'Литера',
			'Страна',
			'Аэропорт',
			'Код ЦРТаэропорта',
			'Код IATAаэропорта',
			'Код ICAOаэропорта',
			'Город',
			'Код ЦРТгорода',
			'Аэропорт назначения',
			'Код ЦРТаэропорта назначения',
			'Код IATAаэропорта назначения',
			'Код ICAOаэропорта назначения',
			'Город назначения',
			'Код ЦРТгорода назначения',
			'Пассажиров всего+РМ всего',
			'Пассажиров всего',
			'Пассажиров',
			'Пассажировтранзитных',
			'Пассажирытрансферные',
			'ВЗРвсего',
			'ВЗР',
			'ВЗРтранзитных',
			'РБ всего',
			'РБ',
			'РБтранзитных',
			'РМвсего',
			'РМ',
			'РМ транзитных',
			'Мест багажавсего',
			'Мест багажа',
			'Мест багажатранзитных',
			'Мест багажатрансферных',
			'Багажавсего',
			'Багаж',
			'Багажтранзитный',
			'Багаж платныйвсего',
			'Багаж платный',
			'Багаж платныйтранзитный',
			'Груз всего',
			'Груз',
			'Грузтранзитный',
			'Почтавсего',
			'Почта',
			'Почтатранзитная',
			'Грузтрансферный',
			'Почтатрансферная',
			'Пассажиры1 кл',
			'ПассажирыБ/кл',
			'ПассажирыЭ/кл',
			'MVM',
			'Количествокресел',
			'Предельныекресла',
			'Предельнаязагрузка',
			'Код задержки',
			'Источник задержки',
			'Весбортпитания',
			'Заправкатопливом',
			'Оператор',
		];
		reportData3.data.push(
			[
				'43631',
				'1',
				'655',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'VPBBG',
				'10B',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43630',
				'0,996527777777778',
				'',
				'',
				'43631',
				'0,00138888888888889',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'СИМФЕРОПОЛЬ',
				'СИП',
				'SIP',
				'URFF',
				'СИМФЕРОПОЛЬ',
				'СИП',
				'',
				'',
				'',
				'',
				'',
				'',
				'142',
				'137',
				'137',
				'0',
				'0',
				'105',
				'105',
				'0',
				'32',
				'32',
				'0',
				'5',
				'5',
				'0',
				'102',
				'102',
				'0',
				'0',
				'1115',
				'1115',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'137',
				'73,5',
				'140',
				'0',
				'12784',
				'',
				'',
				'0',
				'16800',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'174',
				'Главный',
				'EO',
				'KAR',
				'ООО Авиакомпания Икар',
				'Ikar LLC',
				'Российская',
				'Боинг 737-800  Пассажирский',
				'738',
				'B-737-800',
				'VPBPY',
				'56',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,00347222222222222',
				'',
				'',
				'43631',
				'0,00625',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/SVO',
				'ШРМ',
				'SVO',
				'UUEE',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'147',
				'147',
				'147',
				'0',
				'0',
				'142',
				'142',
				'0',
				'5',
				'5',
				'0',
				'0',
				'0',
				'0',
				'43',
				'43',
				'0',
				'0',
				'484',
				'484',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'147',
				'79,015',
				'189',
				'0',
				'17526',
				'',
				'',
				'0',
				'12400',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'542',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VPBPL',
				'13',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43630',
				'0,993055555555556',
				'43631',
				'0,00694444444444444',
				'43631',
				'0,00972222222222222',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МИН  ВОДЫ',
				'МРВ',
				'MRV',
				'URMM',
				'МИНЕРАЛЬНЫЕ ВОДЫ',
				'МРВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'174',
				'171',
				'171',
				'0',
				'0',
				'152',
				'152',
				'0',
				'19',
				'19',
				'0',
				'3',
				'3',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1139',
				'1139',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'171',
				'75,296',
				'189',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'1409',
				'Главный',
				'SU',
				'AFL',
				'ПАО Аэрофлот Российские авиалинии',
				'PJSC Aeroflot Russian Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VPBTI',
				'12',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0104166666666667',
				'',
				'',
				'43631',
				'0,0104166666666667',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/SVO',
				'ШРМ',
				'SVO',
				'UUEE',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'156',
				'155',
				'155',
				'0',
				'0',
				'143',
				'143',
				'0',
				'12',
				'12',
				'0',
				'1',
				'1',
				'0',
				'98',
				'98',
				'0',
				'0',
				'1392',
				'1392',
				'0',
				'0',
				'0',
				'0',
				'997',
				'997',
				'0',
				'57',
				'57',
				'0',
				'0',
				'0',
				'0',
				'7',
				'148',
				'75,5',
				'158',
				'0',
				'16351',
				'',
				'',
				'0',
				'11500',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'563',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VQBTJ',
				'13',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,00347222222222222',
				'',
				'',
				'43631',
				'0,0125',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'КРАСНОДАР',
				'КПА',
				'KRR',
				'URKK',
				'КРАСНОДАР',
				'КРР',
				'',
				'',
				'',
				'',
				'',
				'',
				'182',
				'180',
				'180',
				'0',
				'0',
				'154',
				'154',
				'0',
				'26',
				'26',
				'0',
				'2',
				'2',
				'0',
				'79',
				'79',
				'0',
				'0',
				'790',
				'790',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'180',
				'75,296',
				'189',
				'0',
				'19866',
				'',
				'',
				'0',
				'10300',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'1365',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBDJ',
				'61',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0138888888888889',
				'',
				'',
				'43631',
				'0,0236111111111111',
				'Транзит',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'ИРКУТСК',
				'ИКТ',
				'IKT',
				'UIII',
				'ИРКУТСК',
				'ИКТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'77',
				'77',
				'34',
				'43',
				'0',
				'68',
				'31',
				'37',
				'9',
				'3',
				'6',
				'0',
				'0',
				'0',
				'61',
				'29',
				'32',
				'0',
				'673',
				'266',
				'407',
				'0',
				'0',
				'0',
				'165',
				'27',
				'138',
				'66',
				'11',
				'55',
				'0',
				'0',
				'0',
				'0',
				'77',
				'77',
				'162',
				'0',
				'19657',
				'',
				'',
				'0',
				'12700',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'0',
				'1365',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBDJ',
				'61',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0138888888888889',
				'',
				'',
				'43631',
				'0,0236111111111111',
				'Транзит',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'ВЛАДИВОСТОК',
				'ВВО',
				'VVO',
				'UHWW',
				'ВЛАДИВОСТОК',
				'ВВО',
				'',
				'',
				'',
				'',
				'',
				'',
				'80',
				'78',
				'43',
				'35',
				'0',
				'74',
				'42',
				'32',
				'4',
				'1',
				'3',
				'2',
				'0',
				'2',
				'70',
				'38',
				'32',
				'0',
				'884',
				'431',
				'453',
				'0',
				'0',
				'0',
				'188',
				'108',
				'80',
				'95',
				'26',
				'69',
				'0',
				'0',
				'0',
				'0',
				'78',
				'0',
				'162',
				'0',
				'19657',
				'',
				'',
				'0',
				'12700',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'339',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VPBPT',
				'12',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,00694444444444444',
				'43631',
				'0,0243055555555556',
				'43631',
				'0,0256944444444444',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'СОЧИ',
				'СОЧ',
				'AER',
				'URSS',
				'СОЧИ',
				'СОЧ',
				'',
				'',
				'',
				'',
				'',
				'',
				'190',
				'184',
				'184',
				'0',
				'0',
				'149',
				'149',
				'0',
				'35',
				'35',
				'0',
				'6',
				'6',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1106',
				'1106',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'184',
				'75,296',
				'189',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'1436',
				'Главный',
				'SU',
				'AFL',
				'ПАО Аэрофлот Российские авиалинии',
				'PJSC Aeroflot Russian Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBTX',
				'15',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0590277777777778',
				'43631',
				'0,0416666666666667',
				'43631',
				'0,0388888888888889',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/SVO',
				'ШРМ',
				'SVO',
				'UUEE',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'155',
				'154',
				'154',
				'0',
				'0',
				'152',
				'152',
				'0',
				'2',
				'2',
				'0',
				'1',
				'1',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1209',
				'1209',
				'0',
				'0',
				'0',
				'0',
				'720',
				'720',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'6',
				'148',
				'75,5',
				'158',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'2931',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'VPBJV',
				'16',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0381944444444444',
				'',
				'',
				'43631',
				'0,0402777777777778',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'СИМФЕРОПОЛЬ',
				'СИП',
				'SIP',
				'URFF',
				'СИМФЕРОПОЛЬ',
				'СИП',
				'',
				'',
				'',
				'',
				'',
				'',
				'130',
				'127',
				'127',
				'0',
				'0',
				'101',
				'101',
				'0',
				'26',
				'26',
				'0',
				'3',
				'3',
				'0',
				'91',
				'91',
				'0',
				'0',
				'1046',
				'1046',
				'0',
				'0',
				'0',
				'0',
				'55',
				'55',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'127',
				'73,5',
				'140',
				'0',
				'11571',
				'',
				'',
				'0',
				'17800',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'76702',
				'Главный',
				'',
				'',
				'Войсковая часть 41520 Министерство обороны РФ',
				'',
				'Российская',
				'Ильюшин Ил-76',
				'ИЛ7',
				'ИЛ-76',
				'76702',
				'ЗС4',
				'Нерегулярный',
				'Внутренний',
				'Госавиация**',
				'43631',
				'0,0416666666666667',
				'',
				'',
				'43631',
				'0,0423611111111111',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'РОГАЧЕВО',
				'',
				'',
				'XLDR',
				'САНКТ-ПЕТЕРБУРГ',
				'СПТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'190',
				'0',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'726',
				'Главный',
				'AY',
				'FIN',
				'Finnair Oyj',
				'Finnair Oyj',
				'Международная',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'OHLVK',
				'8',
				'Регулярный',
				'Международный',
				'Пассажирский',
				'43631',
				'0,0347222222222222',
				'',
				'',
				'43631',
				'0,04375',
				'',
				'Вылет',
				'',
				'ФИНЛЯНДИЯ',
				'ХЕЛЬСИНКИ',
				'ХЕЛ',
				'HEL',
				'EFHK',
				'ХЕЛЬСИНКИ',
				'ХЕЛ',
				'',
				'',
				'',
				'',
				'',
				'',
				'118',
				'117',
				'117',
				'0',
				'0',
				'112',
				'112',
				'0',
				'5',
				'5',
				'0',
				'1',
				'1',
				'0',
				'78',
				'78',
				'0',
				'0',
				'1203',
				'1203',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'7',
				'110',
				'66,9',
				'138',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'340',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VQBTI',
				'11',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0381944444444444',
				'',
				'',
				'43631',
				'0,0444444444444444',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'СОЧИ',
				'СОЧ',
				'AER',
				'URSS',
				'СОЧИ',
				'СОЧ',
				'',
				'',
				'',
				'',
				'',
				'',
				'190',
				'187',
				'187',
				'0',
				'0',
				'160',
				'160',
				'0',
				'27',
				'27',
				'0',
				'3',
				'3',
				'0',
				'101',
				'101',
				'0',
				'0',
				'1090',
				'1090',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'187',
				'75,296',
				'189',
				'0',
				'20004',
				'',
				'',
				'0',
				'11100',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'2950',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBQN',
				'11',
				'Регулярный',
				'СНГ',
				'Пассажирский',
				'43631',
				'0,0138888888888889',
				'43631',
				'0,0451388888888889',
				'43631',
				'0,0465277777777778',
				'',
				'Прилет',
				'',
				'РЕСПУБЛИКА АРМЕНИЯ',
				'ЕРЕВАН',
				'ЗВР',
				'EVN',
				'UDYZ',
				'ЕРЕВАН',
				'ЕВН',
				'',
				'',
				'',
				'',
				'',
				'',
				'101',
				'99',
				'99',
				'0',
				'0',
				'86',
				'86',
				'0',
				'13',
				'13',
				'0',
				'2',
				'2',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1720',
				'1720',
				'0',
				'0',
				'0',
				'0',
				'3059',
				'3059',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'99',
				'77',
				'162',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'219',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VPBKX',
				'15',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0416666666666667',
				'',
				'',
				'43631',
				'0,0472222222222222',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'СОЧИ',
				'СОЧ',
				'AER',
				'URSS',
				'СОЧИ',
				'СОЧ',
				'',
				'',
				'',
				'',
				'',
				'',
				'162',
				'159',
				'159',
				'0',
				'0',
				'128',
				'128',
				'0',
				'31',
				'31',
				'0',
				'3',
				'3',
				'0',
				'106',
				'106',
				'0',
				'0',
				'1128',
				'1128',
				'0',
				'0',
				'0',
				'0',
				'162',
				'162',
				'0',
				'4',
				'4',
				'0',
				'0',
				'0',
				'0',
				'0',
				'159',
				'77',
				'162',
				'0',
				'12884',
				'',
				'',
				'0',
				'16400',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'541',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VPBPL',
				'13',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0208333333333333',
				'43631',
				'0,0416666666666667',
				'43631',
				'0,0513888888888889',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МИН  ВОДЫ',
				'МРВ',
				'MRV',
				'URMM',
				'МИНЕРАЛЬНЫЕ ВОДЫ',
				'МРВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'183',
				'181',
				'181',
				'0',
				'0',
				'157',
				'157',
				'0',
				'24',
				'24',
				'0',
				'2',
				'2',
				'0',
				'82',
				'82',
				'0',
				'0',
				'856',
				'856',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'181',
				'75,296',
				'189',
				'0',
				'20050',
				'',
				'',
				'0',
				'10200',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'52',
				'Главный',
				'S7',
				'SBI',
				'АО Авиакомпания Сибирь',
				'Siberia Airlines JSC',
				'Российская',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'VPBHK',
				'59',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0486111111111111',
				'',
				'',
				'43631',
				'0,0527777777777778',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/DME',
				'ДМД',
				'DME',
				'UUDD',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'143',
				'142',
				'142',
				'0',
				'0',
				'134',
				'134',
				'0',
				'8',
				'8',
				'0',
				'1',
				'1',
				'0',
				'63',
				'63',
				'0',
				'0',
				'889',
				'889',
				'0',
				'0',
				'0',
				'0',
				'311',
				'311',
				'0',
				'150',
				'150',
				'0',
				'0',
				'0',
				'0',
				'0',
				'142',
				'70',
				'144',
				'0',
				'15999',
				'',
				'',
				'0',
				'8300',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК АФ,ТЗК РН',
			],
			[
				'43631',
				'1',
				'6402',
				'Главный',
				'FV',
				'SDM',
				'АО Авиакомпания Россия',
				'Rossiya airlines JSC',
				'Российская',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'VQBAT',
				'58',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0520833333333333',
				'',
				'',
				'43631',
				'0,0541666666666667',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'С-ПЕТЕРБУРГ',
				'ПЛК',
				'LED',
				'ULLI',
				'САНКТ-ПЕТЕРБУРГ',
				'СПТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'111',
				'111',
				'111',
				'0',
				'0',
				'100',
				'100',
				'0',
				'11',
				'11',
				'0',
				'0',
				'0',
				'0',
				'49',
				'49',
				'0',
				'0',
				'649',
				'649',
				'0',
				'0',
				'0',
				'0',
				'539',
				'539',
				'0',
				'337',
				'337',
				'0',
				'0',
				'0',
				'0',
				'2',
				'109',
				'70',
				'128',
				'0',
				'17012',
				'',
				'',
				'0',
				'10100',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'404',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VQBTH',
				'14',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0555555555555556',
				'',
				'',
				'43631',
				'0,0583333333333333',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/VKO',
				'ВНК',
				'VKO',
				'UUWW',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'186',
				'183',
				'183',
				'0',
				'0',
				'180',
				'180',
				'0',
				'3',
				'3',
				'0',
				'3',
				'3',
				'0',
				'54',
				'54',
				'0',
				'0',
				'590',
				'590',
				'0',
				'0',
				'0',
				'0',
				'2',
				'2',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'183',
				'75,296',
				'189',
				'0',
				'19867',
				'',
				'',
				'0',
				'9200',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'516',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VPBPT',
				'12',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0277777777777778',
				'43631',
				'0,0486111111111111',
				'43631',
				'0,0618055555555556',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'С-ПЕТЕРБУРГ',
				'ПЛК',
				'LED',
				'ULLI',
				'САНКТ-ПЕТЕРБУРГ',
				'СПТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'166',
				'163',
				'163',
				'0',
				'0',
				'160',
				'160',
				'0',
				'3',
				'3',
				'0',
				'3',
				'3',
				'0',
				'54',
				'54',
				'0',
				'0',
				'505',
				'505',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'163',
				'75,296',
				'189',
				'0',
				'19734',
				'',
				'',
				'0',
				'11800',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'564',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VPBQC',
				'13',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0625',
				'43631',
				'0,0694444444444444',
				'43631',
				'0,0666666666666667',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'КРАСНОДАР',
				'КПА',
				'KRR',
				'URKK',
				'КРАСНОДАР',
				'КРР',
				'',
				'',
				'',
				'',
				'',
				'',
				'175',
				'174',
				'174',
				'0',
				'0',
				'158',
				'158',
				'0',
				'16',
				'16',
				'0',
				'1',
				'1',
				'0',
				'0',
				'0',
				'0',
				'0',
				'981',
				'981',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'175',
				'75,296',
				'189',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'223',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBGJ',
				'62',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0590277777777778',
				'',
				'',
				'43631',
				'0,0694444444444444',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'С-ПЕТЕРБУРГ',
				'ПЛК',
				'LED',
				'ULLI',
				'САНКТ-ПЕТЕРБУРГ',
				'СПТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'151',
				'151',
				'151',
				'0',
				'0',
				'144',
				'144',
				'0',
				'7',
				'7',
				'0',
				'0',
				'0',
				'0',
				'128',
				'128',
				'0',
				'0',
				'1550',
				'1550',
				'0',
				'0',
				'0',
				'0',
				'317',
				'317',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'151',
				'77',
				'162',
				'0',
				'17661',
				'',
				'',
				'0',
				'10900',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'2904',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBCI',
				'7',
				'Регулярный',
				'СНГ',
				'Пассажирский',
				'43631',
				'0,0833333333333333',
				'43631',
				'0,0729166666666667',
				'43631',
				'0,0708333333333333',
				'',
				'Прилет',
				'',
				'КЫРГЫЗСКАЯ РЕСПУБЛИКА',
				'ОШ',
				'ОШШ',
				'OSS',
				'UCFO',
				'ОШ',
				'ОШШ',
				'',
				'',
				'',
				'',
				'',
				'',
				'168',
				'161',
				'161',
				'0',
				'0',
				'142',
				'142',
				'0',
				'19',
				'19',
				'0',
				'7',
				'7',
				'0',
				'0',
				'0',
				'0',
				'0',
				'2751',
				'2751',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'161',
				'77',
				'162',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'930',
				'Главный',
				'EL',
				'ELB',
				'Ellinair SA',
				'Ellinair SA',
				'Международная',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'SXEMB',
				'10A',
				'Регулярный',
				'Международный',
				'Пассажирский',
				'43631',
				'0,0138888888888889',
				'43631',
				'0,0798611111111111',
				'43631',
				'0,0743055555555556',
				'',
				'Прилет',
				'',
				'ГРЕЦИЯ',
				'САЛОНИКИ',
				'СКГ',
				'SKG',
				'LGTS',
				'САЛОНИКИ',
				'СКГ',
				'',
				'',
				'',
				'',
				'',
				'',
				'147',
				'143',
				'143',
				'0',
				'0',
				'121',
				'121',
				'0',
				'22',
				'22',
				'0',
				'4',
				'4',
				'0',
				'0',
				'0',
				'0',
				'0',
				'2079',
				'2079',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'143',
				'75,5',
				'144',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'475',
				'Главный',
				'TK',
				'THY',
				'Авиакомпания Турецкие Авиалинии',
				'Turkish Airlines company',
				'Международная',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'TCJUG',
				'9',
				'Регулярный',
				'Международный',
				'Пассажирский',
				'43631',
				'0,0798611111111111',
				'43631',
				'0,0798611111111111',
				'43631',
				'0,0770833333333333',
				'',
				'Прилет',
				'',
				'ТУРЦИЯ',
				'СТАМБУЛ',
				'IST',
				'IST',
				'LTFM',
				'СТАМБУЛ',
				'ИСТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'98',
				'95',
				'95',
				'0',
				'0',
				'84',
				'84',
				'0',
				'11',
				'11',
				'0',
				'3',
				'3',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1787',
				'1787',
				'0',
				'0',
				'0',
				'0',
				'766',
				'766',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'8',
				'87',
				'77',
				'162',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'9310',
				'Главный',
				'5N',
				'AUL',
				'АО Нордавиа Региональные авиалинии',
				'JSC Nordavia regional airlines',
				'Российская',
				'Боинг 737-800  Пассажирский',
				'738',
				'B-737-800',
				'VPBEV',
				'8',
				'Чартер-Программа',
				'Международный',
				'Пассажирский',
				'43631',
				'0,100694444444444',
				'43631',
				'0,0833333333333333',
				'43631',
				'0,0791666666666667',
				'',
				'Прилет',
				'',
				'ТУРЦИЯ',
				'ДАЛАМАН',
				'ДАН',
				'DLM',
				'LTBS',
				'ДАЛАМАН',
				'ДАН',
				'',
				'',
				'',
				'',
				'',
				'',
				'190',
				'187',
				'187',
				'0',
				'0',
				'161',
				'161',
				'0',
				'26',
				'26',
				'0',
				'3',
				'3',
				'0',
				'0',
				'0',
				'0',
				'0',
				'2203',
				'2203',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'187',
				'79,015',
				'189',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'264',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'VQBTP',
				'10A',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0763888888888889',
				'',
				'',
				'43631',
				'0,0826388888888889',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/DME',
				'ДМД',
				'DME',
				'UUDD',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'126',
				'126',
				'126',
				'0',
				'0',
				'122',
				'122',
				'0',
				'4',
				'4',
				'0',
				'0',
				'0',
				'0',
				'75',
				'75',
				'0',
				'0',
				'959',
				'959',
				'0',
				'0',
				'0',
				'0',
				'1209',
				'1209',
				'0',
				'343',
				'343',
				'0',
				'0',
				'0',
				'0',
				'0',
				'126',
				'75,5',
				'140',
				'0',
				'16128',
				'',
				'',
				'0',
				'9200',
				'ТЗК АФ,ТЗК РН',
			],
			[
				'43631',
				'1',
				'1407',
				'Главный',
				'SU',
				'AFL',
				'ПАО Аэрофлот Российские авиалинии',
				'PJSC Aeroflot Russian Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VPBJW',
				'57',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0833333333333333',
				'',
				'',
				'43631',
				'0,0840277777777778',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/SVO',
				'ШРМ',
				'SVO',
				'UUEE',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'153',
				'150',
				'150',
				'0',
				'0',
				'134',
				'134',
				'0',
				'16',
				'16',
				'0',
				'3',
				'3',
				'0',
				'103',
				'103',
				'0',
				'0',
				'1537',
				'1537',
				'0',
				'0',
				'0',
				'0',
				'1513',
				'1513',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'6',
				'144',
				'75,5',
				'158',
				'0',
				'16074',
				'',
				'',
				'0',
				'11600',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'302',
				'Главный',
				'UT',
				'UTA',
				'ПАО Авиакомпания ЮТэйр',
				'UTair aviation PJSC',
				'Российская',
				'АТР-72-500',
				'АТ7',
				'AT-72-5',
				'VQBLD',
				'60',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0833333333333333',
				'',
				'',
				'43631',
				'0,0861111111111111',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'ХАНТЫ-МАНС.',
				'ХАС',
				'HMA',
				'USHH',
				'ХАНТЫ-МАНСИЙСК',
				'ХАС',
				'',
				'',
				'',
				'',
				'',
				'',
				'29',
				'29',
				'29',
				'0',
				'0',
				'27',
				'27',
				'0',
				'2',
				'2',
				'0',
				'0',
				'0',
				'0',
				'14',
				'14',
				'0',
				'0',
				'146',
				'146',
				'0',
				'0',
				'0',
				'0',
				'35',
				'35',
				'0',
				'33',
				'33',
				'0',
				'0',
				'0',
				'0',
				'0',
				'29',
				'22,8',
				'70',
				'0',
				'7247',
				'',
				'',
				'0',
				'2000',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'406',
				'Главный',
				'DP',
				'PBD',
				'ООО Авиакомпания Победа',
				'Pobeda Airlines LLC',
				'Российская',
				'Боинг 737-800 Винглетс Пассажирский',
				'73Х',
				'B-737-800W',
				'VPBQC',
				'13',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,0902777777777778',
				'',
				'',
				'43631',
				'0,0993055555555555',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/VKO',
				'ВНК',
				'VKO',
				'UUWW',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'189',
				'187',
				'187',
				'0',
				'0',
				'180',
				'180',
				'0',
				'7',
				'7',
				'0',
				'2',
				'2',
				'0',
				'60',
				'60',
				'0',
				'0',
				'670',
				'670',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'187',
				'75,296',
				'189',
				'0',
				'20173',
				'',
				'',
				'0',
				'9000',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'807',
				'Главный',
				'7R',
				'RLU',
				'АО Авиационная компания РусЛайн',
				'Joint Stock Aviation Company RusLine',
				'Российская',
				'Канадэйр Бомбардье Регионал Джет 200',
				'ЦР2',
				'CRJ-200',
				'VQBFF',
				'47',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,104166666666667',
				'',
				'',
				'43631',
				'0,109027777777778',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'НОЯБРЬСК',
				'НОЯ',
				'NOJ',
				'USRO',
				'НОЯБРЬСК',
				'НОЯ',
				'',
				'',
				'',
				'',
				'',
				'',
				'35',
				'33',
				'33',
				'0',
				'0',
				'31',
				'31',
				'0',
				'2',
				'2',
				'0',
				'2',
				'2',
				'0',
				'15',
				'15',
				'0',
				'0',
				'180',
				'180',
				'0',
				'0',
				'0',
				'0',
				'16',
				'16',
				'0',
				'13',
				'13',
				'0',
				'0',
				'0',
				'0',
				'0',
				'33',
				'24,04',
				'50',
				'0',
				'3440',
				'',
				'',
				'0',
				'5600',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'0',
				'807',
				'Главный',
				'7R',
				'RLU',
				'АО Авиационная компания РусЛайн',
				'Joint Stock Aviation Company RusLine',
				'Российская',
				'Канадэйр Бомбардье Регионал Джет 200',
				'ЦР2',
				'CRJ-200',
				'VQBFF',
				'47',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,104166666666667',
				'',
				'',
				'43631',
				'0,109027777777778',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'КРАСНОЯРСК',
				'КЯА',
				'KJA',
				'UNKL',
				'КРАСНОЯРСК',
				'КАЯ',
				'',
				'',
				'',
				'',
				'',
				'',
				'2',
				'2',
				'2',
				'0',
				'0',
				'2',
				'2',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'2',
				'0',
				'50',
				'0',
				'3440',
				'',
				'',
				'0',
				'5600',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'247',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBLO',
				'14',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,121527777777778',
				'43631',
				'0,114583333333333',
				'43631',
				'0,113888888888889',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'КРАСНОЯРСК',
				'КЯА',
				'KJA',
				'UNKL',
				'КРАСНОЯРСК',
				'КАЯ',
				'',
				'',
				'',
				'',
				'',
				'',
				'165',
				'160',
				'160',
				'0',
				'56',
				'153',
				'153',
				'0',
				'7',
				'7',
				'0',
				'5',
				'5',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1184',
				'1184',
				'0',
				'0',
				'0',
				'0',
				'89',
				'89',
				'0',
				'20',
				'20',
				'0',
				'0',
				'0',
				'0',
				'0',
				'160',
				'77',
				'162',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'823',
				'Главный',
				'7R',
				'RLU',
				'АО Авиационная компания РусЛайн',
				'Joint Stock Aviation Company RusLine',
				'Российская',
				'Канадэйр Бомбардье Регионал Джет 200',
				'ЦР2',
				'CRJ-200',
				'VQBFI',
				'48',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,121527777777778',
				'',
				'',
				'43631',
				'0,127083333333333',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'ТОМСК',
				'ТСК',
				'TOF',
				'UNTT',
				'ТОМСК',
				'ТСК',
				'',
				'',
				'',
				'',
				'',
				'',
				'25',
				'24',
				'24',
				'0',
				'0',
				'24',
				'24',
				'0',
				'0',
				'0',
				'0',
				'1',
				'1',
				'0',
				'3',
				'3',
				'0',
				'0',
				'46',
				'46',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'6',
				'6',
				'0',
				'0',
				'0',
				'0',
				'0',
				'24',
				'24,04',
				'50',
				'0',
				'3488',
				'',
				'',
				'0',
				'6300',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'476',
				'Главный',
				'TK',
				'THY',
				'Авиакомпания Турецкие Авиалинии',
				'Turkish Airlines company',
				'Международная',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'TCJUG',
				'9',
				'Регулярный',
				'Международный',
				'Пассажирский',
				'43631',
				'0,125',
				'',
				'',
				'43631',
				'0,132638888888889',
				'',
				'Вылет',
				'',
				'ТУРЦИЯ',
				'СТАМБУЛ',
				'IST',
				'IST',
				'LTFM',
				'СТАМБУЛ',
				'ИСТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'157',
				'156',
				'156',
				'0',
				'0',
				'138',
				'138',
				'0',
				'18',
				'18',
				'0',
				'1',
				'1',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1966',
				'1966',
				'0',
				'0',
				'0',
				'0',
				'1588',
				'1588',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'12',
				'144',
				'77',
				'162',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'931',
				'Главный',
				'EL',
				'ELB',
				'Ellinair SA',
				'Ellinair SA',
				'Международная',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'SXEMB',
				'10A',
				'Регулярный',
				'Международный',
				'Пассажирский',
				'43631',
				'0,0659722222222222',
				'43631',
				'0,135416666666667',
				'43631',
				'0,135416666666667',
				'',
				'Вылет',
				'',
				'ГРЕЦИЯ',
				'САЛОНИКИ',
				'СКГ',
				'SKG',
				'LGTS',
				'САЛОНИКИ',
				'СКГ',
				'',
				'',
				'',
				'',
				'',
				'',
				'143',
				'138',
				'138',
				'0',
				'0',
				'108',
				'108',
				'0',
				'30',
				'30',
				'0',
				'5',
				'5',
				'0',
				'114',
				'114',
				'0',
				'0',
				'1551',
				'1551',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'138',
				'75,5',
				'144',
				'0',
				'16375',
				'',
				'',
				'0',
				'1600',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'262',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBQN',
				'11',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,135416666666667',
				'',
				'',
				'43631',
				'0,136805555555556',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/DME',
				'ДМД',
				'DME',
				'UUDD',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'154',
				'154',
				'154',
				'0',
				'0',
				'148',
				'148',
				'0',
				'6',
				'6',
				'0',
				'0',
				'0',
				'0',
				'74',
				'74',
				'0',
				'0',
				'887',
				'887',
				'0',
				'0',
				'0',
				'0',
				'1012',
				'1012',
				'0',
				'268',
				'268',
				'0',
				'0',
				'0',
				'0',
				'4',
				'150',
				'77',
				'162',
				'0',
				'20495',
				'',
				'',
				'0',
				'9500',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'211',
				'Главный',
				'YC',
				'LLM',
				'АО Авиационная транспортная компания ЯМАЛ',
				'Yamal airlines',
				'Российская',
				'Sukhoi Superjet 100-95LR',
				'СУ9',
				'RRJ-95LR',
				'89091',
				'21',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,145833333333333',
				'',
				'',
				'43631',
				'0,147222222222222',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'НАДЫМ',
				'НДМ',
				'NYM',
				'USMM',
				'НАДЫМ',
				'НДМ',
				'',
				'',
				'',
				'',
				'',
				'',
				'38',
				'38',
				'38',
				'0',
				'0',
				'38',
				'38',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'34',
				'34',
				'0',
				'0',
				'429',
				'429',
				'0',
				'18',
				'18',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'38',
				'49,45',
				'100',
				'0',
				'6819',
				'',
				'',
				'0',
				'9200',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'1437',
				'Главный',
				'SU',
				'AFL',
				'ПАО Аэрофлот Российские авиалинии',
				'PJSC Aeroflot Russian Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBTX',
				'15',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,152777777777778',
				'',
				'',
				'43631',
				'0,154166666666667',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/SVO',
				'ШРМ',
				'SVO',
				'UUEE',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'153',
				'150',
				'150',
				'0',
				'0',
				'133',
				'133',
				'0',
				'17',
				'17',
				'0',
				'3',
				'3',
				'0',
				'92',
				'92',
				'0',
				'0',
				'1321',
				'1321',
				'0',
				'0',
				'0',
				'0',
				'1914',
				'1914',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'7',
				'143',
				'75,5',
				'158',
				'0',
				'18269',
				'',
				'',
				'0',
				'9300',
				'[оператор] ТЗК Газпром-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'77',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 319',
				'319',
				'A-319',
				'VQBTZ',
				'12',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,159722222222222',
				'',
				'',
				'43631',
				'0,159722222222222',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'СИМФЕРОПОЛЬ',
				'СИП',
				'SIP',
				'URFF',
				'СИМФЕРОПОЛЬ',
				'СИП',
				'',
				'',
				'',
				'',
				'',
				'',
				'143',
				'140',
				'140',
				'0',
				'0',
				'108',
				'108',
				'0',
				'32',
				'32',
				'0',
				'3',
				'3',
				'0',
				'106',
				'106',
				'0',
				'0',
				'1184',
				'1184',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'140',
				'75,5',
				'140',
				'0',
				'11667',
				'',
				'',
				'0',
				'17800',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'9309',
				'Главный',
				'5N',
				'AUL',
				'АО Нордавиа Региональные авиалинии',
				'JSC Nordavia regional airlines',
				'Российская',
				'Боинг 737-800  Пассажирский',
				'738',
				'B-737-800',
				'VPBEV',
				'8',
				'Чартер-Программа',
				'Международный',
				'Пассажирский',
				'43631',
				'0,163194444444444',
				'',
				'',
				'43631',
				'0,166666666666667',
				'',
				'Вылет',
				'',
				'ТУРЦИЯ',
				'ДАЛАМАН',
				'ДАН',
				'DLM',
				'LTBS',
				'ДАЛАМАН',
				'ДАН',
				'',
				'',
				'',
				'',
				'',
				'',
				'187',
				'187',
				'187',
				'0',
				'0',
				'157',
				'157',
				'0',
				'30',
				'30',
				'0',
				'0',
				'0',
				'0',
				'139',
				'139',
				'0',
				'0',
				'1496',
				'1496',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'187',
				'79,015',
				'189',
				'0',
				'18368',
				'',
				'',
				'0',
				'15500',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'9782',
				'Главный',
				'**',
				'***',
				'***',
				'Без авиакомпании (Россия)',
				'Российская',
				'Антонов Ан-12',
				'АНФ',
				'АН-12',
				'9782',
				'',
				'Нерегулярный',
				'Внутренний',
				'Госавиация**',
				'43631',
				'0,166666666666667',
				'',
				'',
				'43631',
				'0,171527777777778',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/Ч',
				'ЧКЛ',
				'CKL',
				'UUMU',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'61',
				'14',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'ТЗК РН',
			],
			[
				'43631',
				'1',
				'279',
				'Главный',
				'UT',
				'UTA',
				'ПАО Авиакомпания ЮТэйр',
				'UTair aviation PJSC',
				'Российская',
				'АТР-72-500',
				'АТ7',
				'AT-72-5',
				'VQBMA',
				'58',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,197916666666667',
				'43631',
				'0,180555555555556',
				'43631',
				'0,177777777777778',
				'Транзит',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'ТЮМЕНЬ',
				'РЩН',
				'TJM',
				'USTR',
				'ТЮМЕНЬ',
				'ТЮМ',
				'',
				'',
				'',
				'',
				'',
				'',
				'54',
				'53',
				'20',
				'33',
				'4',
				'51',
				'20',
				'31',
				'2',
				'0',
				'2',
				'1',
				'1',
				'0',
				'0',
				'0',
				'0',
				'0',
				'228',
				'36',
				'192',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'30',
				'30',
				'0',
				'0',
				'0',
				'0',
				'0',
				'53',
				'22,8',
				'70',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'111',
				'Главный',
				'UT',
				'UTA',
				'ПАО Авиакомпания ЮТэйр',
				'UTair aviation PJSC',
				'Российская',
				'АТР-72-500',
				'АТ7',
				'AT-72-5',
				'VQBLJ',
				'57',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,197916666666667',
				'43631',
				'0,184027777777778',
				'43631',
				'0,182638888888889',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'СУРГУТ',
				'СУР',
				'SGC',
				'USRR',
				'СУРГУТ',
				'СУР',
				'',
				'',
				'',
				'',
				'',
				'',
				'69',
				'69',
				'69',
				'0',
				'29',
				'69',
				'69',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'381',
				'381',
				'0',
				'0',
				'0',
				'0',
				'6',
				'6',
				'0',
				'73',
				'73',
				'0',
				'0',
				'0',
				'0',
				'0',
				'69',
				'22,8',
				'70',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'272',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBLO',
				'14',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,1875',
				'',
				'',
				'43631',
				'0,188888888888889',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/DME',
				'ДМД',
				'DME',
				'UUDD',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'152',
				'151',
				'151',
				'0',
				'0',
				'142',
				'142',
				'0',
				'9',
				'9',
				'0',
				'1',
				'1',
				'0',
				'87',
				'87',
				'0',
				'0',
				'1057',
				'1057',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'2',
				'149',
				'77',
				'162',
				'0',
				'18190',
				'',
				'',
				'0',
				'9400',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'271',
				'Главный',
				'KC',
				'KZR',
				'АО Эйр Астана',
				'Air Astana',
				'Международная',
				'Эмбраер ERJ-190',
				'Е90',
				'EMB-190',
				'P4KCK',
				'8',
				'Регулярный',
				'СНГ',
				'Пассажирский',
				'43631',
				'0,208333333333333',
				'43631',
				'0,208333333333333',
				'43631',
				'0,207638888888889',
				'',
				'Прилет',
				'',
				'РЕСПУБЛИКА КАЗАХСТАН',
				'НУР-СУЛТАН',
				'АКЛ',
				'NQZ',
				'UACC',
				'НУР-СУЛТАН',
				'АКЛ',
				'',
				'',
				'',
				'',
				'',
				'',
				'90',
				'90',
				'90',
				'0',
				'0',
				'87',
				'87',
				'0',
				'3',
				'3',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'794',
				'794',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'5',
				'85',
				'49,99',
				'110',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'32806',
				'Главный',
				'',
				'',
				'АСЦ Жуковский МЧС России ФГБУ',
				'',
				'Российская',
				'Камов Ка-32',
				'К32',
				'КА-32',
				'32806',
				'67',
				'Нерегулярный',
				'Внутренний',
				'Госавиация',
				'43631',
				'0,208333333333333',
				'',
				'',
				'43631',
				'0,208333333333333',
				'Транзит',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'УВАТ',
				'УВТ',
				'',
				'USTA',
				'УВАТ',
				'УВТ',
				'',
				'',
				'',
				'',
				'',
				'',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'11',
				'0',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'2954',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBAG',
				'10B',
				'Регулярный',
				'СНГ',
				'Пассажирский',
				'43631',
				'0,204861111111111',
				'43631',
				'0,21875',
				'43631',
				'0,216666666666667',
				'',
				'Прилет',
				'',
				'РЕСПУБЛИКА ТАДЖИКИСТАН',
				'ДУШАНБЕ',
				'ДШБ',
				'DYU',
				'UTDD',
				'ДУШАНБЕ',
				'ДШБ',
				'',
				'',
				'',
				'',
				'',
				'',
				'157',
				'155',
				'155',
				'0',
				'10',
				'148',
				'148',
				'0',
				'7',
				'7',
				'0',
				'2',
				'2',
				'0',
				'0',
				'0',
				'0',
				'0',
				'2070',
				'2070',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'155',
				'77',
				'156',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'9003',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VQBCI',
				'7',
				'Нерегулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,21875',
				'',
				'',
				'43631',
				'0,220833333333333',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'САМАРА',
				'СКЧ',
				'KUF',
				'UWWW',
				'САМАРА',
				'СМШ',
				'',
				'',
				'',
				'',
				'',
				'',
				'111',
				'107',
				'107',
				'0',
				'0',
				'98',
				'98',
				'0',
				'9',
				'9',
				'0',
				'4',
				'4',
				'0',
				'69',
				'69',
				'0',
				'0',
				'783',
				'783',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'107',
				'77',
				'162',
				'0',
				'17865',
				'',
				'',
				'0',
				'5500',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'1',
				'Главный',
				'U6',
				'SVR',
				'ОАО Авиакомпания Уральские авиалинии',
				'Ural Airlines',
				'Российская',
				'Аэробус Индустри 320',
				'320',
				'A-320',
				'VPBTZ',
				'11',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,229166666666667',
				'',
				'',
				'43631',
				'0,23125',
				'',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'МОСКВА/SVO',
				'ШРМ',
				'SVO',
				'UUEE',
				'МОСКВА',
				'МОВ',
				'',
				'',
				'',
				'',
				'',
				'',
				'146',
				'145',
				'145',
				'0',
				'0',
				'134',
				'134',
				'0',
				'11',
				'11',
				'0',
				'1',
				'1',
				'0',
				'80',
				'80',
				'0',
				'0',
				'948',
				'948',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'145',
				'77',
				'162',
				'0',
				'17986',
				'',
				'',
				'0',
				'8600',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'279',
				'Главный',
				'UT',
				'UTA',
				'ПАО Авиакомпания ЮТэйр',
				'UTair aviation PJSC',
				'Российская',
				'АТР-72-500',
				'АТ7',
				'AT-72-5',
				'VQBMA',
				'58',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,236111111111111',
				'',
				'',
				'43631',
				'0,2375',
				'Транзит',
				'Вылет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'САМАРА',
				'СКЧ',
				'KUF',
				'UWWW',
				'САМАРА',
				'СМШ',
				'',
				'',
				'',
				'',
				'',
				'',
				'70',
				'70',
				'37',
				'33',
				'0',
				'64',
				'33',
				'31',
				'6',
				'4',
				'2',
				'0',
				'0',
				'0',
				'36',
				'19',
				'17',
				'0',
				'396',
				'204',
				'192',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'7',
				'7',
				'0',
				'0',
				'0',
				'0',
				'0',
				'70',
				'22,8',
				'70',
				'0',
				'6913',
				'',
				'',
				'0',
				'2300',
				'[оператор] ТЗК Лукойл-Аэро,ТЗК РН',
			],
			[
				'43631',
				'1',
				'5015',
				'Главный',
				'S7',
				'SBI',
				'АО Авиакомпания Сибирь',
				'Siberia Airlines JSC',
				'Российская',
				'Эмбраер ERJ-170',
				'Е70',
				'EMB-170',
				'VQBYH',
				'13',
				'Регулярный',
				'Внутренний',
				'Пассажирский',
				'43631',
				'0,246527777777778',
				'43631',
				'0,243055555555556',
				'43631',
				'0,240277777777778',
				'',
				'Прилет',
				'',
				'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
				'НОВОСИБИРСК',
				'ТЛЧ',
				'OVB',
				'UNNT',
				'НОВОСИБИРСК',
				'ОВБ',
				'',
				'',
				'',
				'',
				'',
				'',
				'75',
				'75',
				'75',
				'0',
				'1',
				'73',
				'73',
				'0',
				'2',
				'2',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'381',
				'381',
				'0',
				'0',
				'0',
				'0',
				'173',
				'173',
				'0',
				'189',
				'189',
				'0',
				'0',
				'0',
				'0',
				'0',
				'75',
				'37,2',
				'78',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'',
			],
			[
				'43631',
				'1',
				'272',
				'Главный',
				'KC',
				'KZR',
				'АО Эйр Астана',
				'Air Astana',
				'Международная',
				'Эмбраер ERJ-190',
				'Е90',
				'EMB-190',
				'P4KCK',
				'8',
				'Регулярный',
				'СНГ',
				'Пассажирский',
				'43631',
				'0,25',
				'',
				'',
				'43631',
				'0,251388888888889',
				'',
				'Вылет',
				'',
				'РЕСПУБЛИКА КАЗАХСТАН',
				'НУР-СУЛТАН',
				'АКЛ',
				'NQZ',
				'UACC',
				'НУР-СУЛТАН',
				'АКЛ',
				'',
				'',
				'',
				'',
				'',
				'',
				'69',
				'66',
				'66',
				'0',
				'0',
				'63',
				'63',
				'0',
				'3',
				'3',
				'0',
				'3',
				'3',
				'0',
				'0',
				'0',
				'0',
				'0',
				'608',
				'608',
				'0',
				'0',
				'0',
				'0',
				'275',
				'275',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1',
				'65',
				'49,99',
				'110',
				'0',
				'0',
				'',
				'',
				'0',
				'0',
				'ТЗК РН',
			]
		);

		const reportData4 = new ReportData();
		reportData4.id = 4;
		reportData4.titles = [
			'Номер борта',
			'Номер стоянки',
			'Номер рейса наприлет',
			'Фактическая датакасания',
			'Плановая датапостановкина стоянку',
			'Фактическая датазаруливанияна стоянку',
			'Номер рейса навылет',
			'Плановая датаосвобождениястоянки',
			'Фактическая датаосвобождениястоянки',
			'Фактическая датавзлета',
			'начало периода',
			'конец периода',
			'Фактическая занятость',
			'Плановаяя занятость',
			'Интервал времени до начала суток',
			'Интервал времени после конца суток',
			'',
			'время от касания до постановки на стоянку',
			'время поосле выыруливания до взлета',
			'Рейсы, которые занимают стоянки вне пределов диапазона',
			'Рейсы, которые не надо отображать',
			'фактичекая занятость в периоде',
		];
		reportData4.data.push(
			[
				'VPBTZ',
				'1',
				'150',
				'12.07.2019 16:53:00',
				'12.07.2019 17:37:47',
				'12.07.2019 17:37:47',
				'1',
				'13.07.2019 03:25:27',
				'13.07.2019 03:25:27',
				'13.07.2019 05:30:00',
				'43659',
				'43659,9999884259',
				'0,408101851848187',
				'0,408101851848187',
				'',
				'',
				'',
				'0,0310995370382443',
				'0,0864930555544561',
				'',
				'',
				'0,142673611109785',
			],
			[
				'VQBCI',
				'1',
				'322',
				'13.07.2019 17:08:00',
				'13.07.2019 17:59:21',
				'13.07.2019 17:59:21',
				'VQBCI',
				'13.07.2019 20:45:55',
				'13.07.2019 20:45:55',
				'13.07.2019 20:45:55',
				'43659',
				'43659,9999884259',
				'0,115671296291112',
				'0,115671296291112',
				'',
				'',
				'',
				'0,0356597222271375',
				'0',
				'',
				'',
				'0,115671296291112',
			],
			[
				'VQBCI',
				'1',
				'VQBCI',
				'13.07.2019 20:45:55',
				'13.07.2019 20:45:55',
				'13.07.2019 20:45:55',
				'201',
				'13.07.2019 22:29:48',
				'13.07.2019 22:29:48',
				'14.07.2019 04:00:00',
				'43659',
				'43659,9999884259',
				'0,0721412037019036',
				'0,0721412037019036',
				'',
				'',
				'',
				'0',
				'0,229305555556493',
				'',
				'',
				'0,0721412037019036',
			],
			[
				'VPBJG',
				'7',
				'1842',
				'13.07.2019 08:40:00',
				'13.07.2019 08:40:00',
				'13.07.2019 08:44:00',
				'131',
				'13.07.2019 09:42:01',
				'13.07.2019 09:42:01',
				'13.07.2019 11:40:00',
				'43659',
				'43659,9999884259',
				'0,0402893518548808',
				'0,0430671296344372',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,0819328703655628',
				'',
				'',
				'0,0402893518548808',
			],
			[
				'VPBJG',
				'7',
				'132',
				'13.07.2019 19:38:00',
				'13.07.2019 19:38:00',
				'13.07.2019 19:47:00',
				'1841',
				'13.07.2019 22:45:00',
				'13.07.2019 22:40:00',
				'13.07.2019 22:55:00',
				'43659',
				'43659,9999884259',
				'0,120138888887595',
				'0,129861111105129',
				'',
				'',
				'',
				'0,00624999999854481',
				'0,0104166666642413',
				'',
				'',
				'0,120138888887595',
			],
			[
				'VQBEE',
				'7',
				'9310',
				'13.07.2019 01:11:00',
				'13.07.2019 01:10:00',
				'13.07.2019 01:17:00',
				'9309',
				'13.07.2019 03:55:00',
				'13.07.2019 03:41:00',
				'13.07.2019 03:57:00',
				'43659',
				'43659,9999884259',
				'0,0999999999985448',
				'0,114583333335759',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,0111111111109494',
				'',
				'',
				'0,0999999999985448',
			],
			[
				'VPBJV',
				'8',
				'858',
				'12.07.2019 22:02:00',
				'12.07.2019 22:05:00',
				'12.07.2019 22:08:00',
				'262',
				'13.07.2019 04:30:00',
				'13.07.2019 04:31:00',
				'13.07.2019 04:40:00',
				'43659',
				'43659,9999884259',
				'0,265972222223354',
				'0,267361111109494',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,00624999999854481',
				'',
				'',
				'0,188194444446708',
			],
			[
				'VPBLN',
				'11',
				'1408',
				'13.07.2019 22:58:00',
				'13.07.2019 22:55:00',
				'13.07.2019 23:03:00',
				'1409',
				'14.07.2019 00:15:00',
				'14.07.2019 00:04:00',
				'14.07.2019 00:17:00',
				'43659',
				'43659,9999884259',
				'0,0423611111109494',
				'0,0555555555547471',
				'-0,960416666668607',
				'0,002789351856336',
				'',
				'0,00347222222626442',
				'0,00902777777810115',
				'',
				'',
				'0,0395717592546134',
			],
			[
				'EX37012',
				'9',
				'865',
				'13.07.2019 06:49:00',
				'13.07.2019 07:00:00',
				'13.07.2019 06:55:00',
				'866',
				'13.07.2019 08:50:00',
				'13.07.2019 08:46:00',
				'13.07.2019 09:00:00',
				'43659',
				'43659,9999884259',
				'0,0770833333299379',
				'0,0763888888905058',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,00972222222480923',
				'',
				'',
				'0,0770833333299379',
			],
			[
				'P4KCH',
				'9',
				'271',
				'13.07.2019 04:51:00',
				'13.07.2019 04:50:00',
				'13.07.2019 04:56:00',
				'272',
				'13.07.2019 06:00:00',
				'13.07.2019 05:54:00',
				'13.07.2019 06:04:00',
				'43659',
				'43659,9999884259',
				'0,0402777777781012',
				'0,0486111111094942',
				'',
				'',
				'',
				'0,00347222222626442',
				'0,00694444444525288',
				'',
				'',
				'0,0402777777781012',
			],
			[
				'SXEMM',
				'9',
				'930',
				'13.07.2019 00:19:00',
				'13.07.2019 00:20:00',
				'13.07.2019 00:26:00',
				'931',
				'13.07.2019 01:35:00',
				'13.07.2019 01:30:00',
				'13.07.2019 01:46:00',
				'43659',
				'43659,9999884259',
				'0,0444444444437977',
				'0,0520833333284827',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,0111111111109494',
				'',
				'',
				'0,0444444444437977',
			],
			[
				'VPBOA',
				'9',
				'5768',
				'13.07.2019 11:45:00',
				'13.07.2019 11:45:00',
				'13.07.2019 11:51:00',
				'5803',
				'13.07.2019 13:25:00',
				'13.07.2019 13:11:00',
				'13.07.2019 13:27:00',
				'43659',
				'43659,9999884259',
				'0,0555555555547471',
				'0,0694444444452529',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,0111111111109494',
				'',
				'',
				'0,0555555555547471',
			],
			[
				'VPBUY',
				'9',
				'9696',
				'13.07.2019 19:41:00',
				'13.07.2019 19:40:00',
				'13.07.2019 19:49:00',
				'9693',
				'13.07.2019 22:20:00',
				'13.07.2019 22:10:00',
				'13.07.2019 22:24:00',
				'43659',
				'43659,9999884259',
				'0,0979166666656965',
				'0,111111111109494',
				'',
				'',
				'',
				'0,00555555555183673',
				'0,00972222222480923',
				'',
				'',
				'0,0979166666656965',
			],
			[
				'VQBCY',
				'9',
				'2904',
				'13.07.2019 01:44:00',
				'13.07.2019 01:50:00',
				'13.07.2019 01:49:00',
				'77',
				'13.07.2019 04:05:00',
				'13.07.2019 04:13:00',
				'13.07.2019 04:22:00',
				'43659',
				'43659,9999884259',
				'0,0999999999985448',
				'0,09375',
				'',
				'',
				'',
				'0,00347222221898846',
				'0,00624999999854481',
				'',
				'',
				'0,0999999999985448',
			],
			[
				'EIXLE',
				'10',
				'5866',
				'13.07.2019 20:14:00',
				'13.07.2019 20:15:00',
				'13.07.2019 20:22:00',
				'5865',
				'13.07.2019 22:20:00',
				'13.07.2019 22:04:00',
				'13.07.2019 22:20:00',
				'43659',
				'43659,9999884259',
				'0,0708333333313931',
				'0,0868055555547471',
				'',
				'',
				'',
				'0,00555555555911269',
				'0,0111111111109494',
				'',
				'',
				'0,0708333333313931',
			],
			[
				'EIXLD',
				'11',
				'5604',
				'13.07.2019 08:06:00',
				'13.07.2019 08:10:00',
				'13.07.2019 08:13:00',
				'5603',
				'13.07.2019 10:45:00',
				'13.07.2019 10:35:00',
				'13.07.2019 10:46:00',
				'43659',
				'43659,9999884259',
				'0,0986111111051287',
				'0,10763888888323',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00763888889196096',
				'',
				'',
				'0,0986111111051287',
			],
			[
				'VPBNO',
				'51',
				'846',
				'13.07.2019 23:13:00',
				'13.07.2019 23:14:00',
				'13.07.2019 23:18:00',
				'845',
				'14.07.2019 00:30:00',
				'14.07.2019 00:25:00',
				'14.07.2019 00:33:00',
				'43659',
				'43659,9999884259',
				'0,046527777776646',
				'0,0527777777824667',
				'-0,970833333332848',
				'0,0173726851862739',
				'',
				'0,00347222221898846',
				'0,00555555555911269',
				'',
				'',
				'0,0291550925903721',
			],
			[
				'VPBPJ',
				'11',
				'542',
				'12.07.2019 23:32:00',
				'12.07.2019 23:30:00',
				'12.07.2019 23:39:00',
				'541',
				'13.07.2019 00:30:00',
				'13.07.2019 00:35:00',
				'13.07.2019 00:44:00',
				'43659',
				'43659,9999884259',
				'0,038888888884685',
				'0,0416666666715173',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00624999999854481',
				'',
				'',
				'0,0243055555547471',
			],
			[
				'VPBQW',
				'11',
				'202',
				'13.07.2019 12:55:00',
				'13.07.2019 13:00:00',
				'13.07.2019 12:59:00',
				'225',
				'13.07.2019 14:30:00',
				'13.07.2019 14:18:00',
				'13.07.2019 14:30:00',
				'43659',
				'43659,9999884259',
				'0,054861111108039',
				'0,0625',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00833333333139308',
				'',
				'',
				'0,054861111108039',
			],
			[
				'VPBTZ',
				'11',
				'150',
				'12.07.2019 16:53:00',
				'13.07.2019 03:25:27',
				'13.07.2019 03:25:27',
				'1',
				'13.07.2019 05:30:00',
				'13.07.2019 05:20:00',
				'13.07.2019 05:30:00',
				'43659',
				'43659,9999884259',
				'0,0795486111092032',
				'0,0864930555544561',
				'',
				'',
				'',
				'0,439201388886431',
				'0,00694444444525288',
				'',
				'',
				'0,222222222218988',
			],
			[
				'VQBWD',
				'11',
				'1414',
				'13.07.2019 16:33:00',
				'13.07.2019 16:34:00',
				'13.07.2019 16:39:00',
				'1415',
				'13.07.2019 18:00:00',
				'13.07.2019 17:50:00',
				'13.07.2019 18:01:00',
				'43659',
				'43659,9999884259',
				'0,0493055555562023',
				'0,0597222222204437',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,00763888889196096',
				'',
				'',
				'0,0493055555562023',
			],
			[
				'VPBIE',
				'12',
				'365',
				'13.07.2019 02:06:00',
				'13.07.2019 02:10:00',
				'13.07.2019 02:11:00',
				'365',
				'13.07.2019 03:45:00',
				'13.07.2019 03:42:00',
				'13.07.2019 03:51:00',
				'43659',
				'43659,9999884259',
				'0,0631944444467081',
				'0,0659722222189885',
				'',
				'',
				'',
				'0,00347222221898846',
				'0,00624999999854481',
				'',
				'',
				'0,0631944444467081',
			],
			[
				'VPBPL',
				'12',
				'304',
				'13.07.2019 23:33:00',
				'13.07.2019 23:35:00',
				'13.07.2019 23:37:00',
				'516',
				'14.07.2019 00:40:00',
				'14.07.2019 00:27:00',
				'14.07.2019 00:40:00',
				'43659',
				'43659,9999884259',
				'0,0347222222262644',
				'0,0451388888905058',
				'-0,984027777776646',
				'0,01876157407969',
				'',
				'0,00277777777955635',
				'0,00902777777810115',
				'',
				'',
				'0,0159606481465744',
			],
			[
				'VPBPT',
				'12',
				'515',
				'12.07.2019 23:29:00',
				'12.07.2019 23:30:00',
				'12.07.2019 23:36:00',
				'404',
				'13.07.2019 01:20:00',
				'13.07.2019 01:09:00',
				'13.07.2019 01:20:00',
				'43659',
				'43659,9999884259',
				'0,0645833333401242',
				'0,0763888888905058',
				'',
				'',
				'',
				'0,00486111110512866',
				'0,007638888884685',
				'',
				'',
				'0,0479166666700621',
			],
			[
				'VQBCI',
				'12',
				'366',
				'13.07.2019 07:54:00',
				'13.07.2019 07:55:00',
				'13.07.2019 07:57:00',
				'321',
				'13.07.2019 10:05:00',
				'13.07.2019 10:05:00',
				'13.07.2019 10:13:00',
				'43659',
				'43659,9999884259',
				'0,0888888888875954',
				'0,0902777777810115',
				'',
				'',
				'',
				'0,00208333333284827',
				'0,00555555555183673',
				'',
				'',
				'0,0888888888875954',
			],
			[
				'VQBCZ',
				'12',
				'302',
				'13.07.2019 15:58:00',
				'13.07.2019 15:58:00',
				'13.07.2019 16:02:00',
				'299',
				'13.07.2019 18:55:00',
				'13.07.2019 18:52:00',
				'13.07.2019 19:02:00',
				'43659',
				'43659,9999884259',
				'0,118055555554747',
				'0,122916666667152',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00694444444525288',
				'',
				'',
				'0,118055555554747',
			],
			[
				'VQBSR',
				'12',
				'5630',
				'13.07.2019 12:41:00',
				'13.07.2019 12:40:00',
				'13.07.2019 12:49:00',
				'5629',
				'13.07.2019 15:50:00',
				'13.07.2019 15:37:00',
				'13.07.2019 15:50:00',
				'43659',
				'43659,9999884259',
				'0,116666666661331',
				'0,131944444437977',
				'',
				'',
				'',
				'0,00555555555911269',
				'0,00902777777810115',
				'',
				'',
				'0,116666666661331',
			],
			[
				'VQBYV',
				'12',
				'5017',
				'13.07.2019 11:17:00',
				'13.07.2019 11:20:00',
				'13.07.2019 11:24:00',
				'5018',
				'13.07.2019 12:25:00',
				'13.07.2019 12:16:00',
				'13.07.2019 12:28:00',
				'43659',
				'43659,9999884259',
				'0,0361111111124046',
				'0,0451388888905058',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00833333333139308',
				'',
				'',
				'0,0361111111124046',
			],
			[
				'VPBHP',
				'13',
				'55',
				'13.07.2019 14:01:00',
				'13.07.2019 14:10:00',
				'13.07.2019 14:09:00',
				'56',
				'13.07.2019 15:00:00',
				'13.07.2019 15:00:00',
				'13.07.2019 15:18:00',
				'43659',
				'43659,9999884259',
				'0,0354166666656965',
				'0,0347222222189885',
				'',
				'',
				'',
				'0,00555555555911269',
				'0,0124999999970896',
				'',
				'',
				'0,0354166666656965',
			],
			[
				'VPBTN',
				'13',
				'53',
				'13.07.2019 09:34:00',
				'13.07.2019 09:40:00',
				'13.07.2019 09:38:00',
				'54',
				'13.07.2019 10:40:00',
				'13.07.2019 10:39:00',
				'13.07.2019 10:49:00',
				'43659',
				'43659,9999884259',
				'0,0423611111109494',
				'0,0416666666642413',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00694444444525288',
				'',
				'',
				'0,0423611111109494',
			],
			[
				'VPBTQ',
				'13',
				'51',
				'13.07.2019 00:28:00',
				'13.07.2019 00:35:00',
				'13.07.2019 00:33:00',
				'52',
				'13.07.2019 01:20:00',
				'13.07.2019 01:18:00',
				'13.07.2019 01:30:00',
				'43659',
				'43659,9999884259',
				'0,03125',
				'0,03125',
				'',
				'',
				'',
				'0,00347222222626442',
				'0,00833333333139308',
				'',
				'',
				'0,03125',
			],
			[
				'VQBFW',
				'13',
				'247',
				'13.07.2019 02:33:00',
				'13.07.2019 02:40:00',
				'13.07.2019 02:37:00',
				'272',
				'13.07.2019 04:30:00',
				'13.07.2019 04:24:00',
				'13.07.2019 04:32:00',
				'43659',
				'43659,9999884259',
				'0,0743055555576575',
				'0,0763888888905058',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00555555555183673',
				'',
				'',
				'0,0743055555576575',
			],
			[
				'VQBGJ',
				'13',
				'299',
				'13.07.2019 16:51:00',
				'13.07.2019 16:49:00',
				'13.07.2019 16:55:00',
				'VQBGJ',
				'13.07.2019 17:40:30',
				'13.07.2019 17:40:30',
				'13.07.2019 19:59:21',
				'43659',
				'43659,9999884259',
				'0,031597222223354',
				'0,0357638888890506',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,0964236111103673',
				'',
				'',
				'0,031597222223354',
			],
			[
				'VQBHT',
				'13',
				'1400',
				'13.07.2019 07:39:00',
				'13.07.2019 07:40:00',
				'13.07.2019 07:44:00',
				'1401',
				'13.07.2019 08:55:00',
				'13.07.2019 08:48:00',
				'13.07.2019 09:03:00',
				'43659',
				'43659,9999884259',
				'0,0444444444437977',
				'0,0520833333357587',
				'',
				'',
				'',
				'0,00347222222626442',
				'0,0104166666642413',
				'',
				'',
				'0,0444444444437977',
			],
			[
				'VQBWH',
				'13',
				'405',
				'13.07.2019 23:03:00',
				'13.07.2019 23:05:00',
				'13.07.2019 23:07:00',
				'563',
				'14.07.2019 00:05:00',
				'13.07.2019 23:56:00',
				'14.07.2019 00:07:00',
				'43659',
				'43659,9999884259',
				'0,0340277777795563',
				'0,0416666666642413',
				'',
				'',
				'',
				'0,00277777777228039',
				'0,00763888889196096',
				'',
				'',
				'0,0340277777795563',
			],
			[
				'VQBYK',
				'13',
				'5015',
				'13.07.2019 05:35:00',
				'13.07.2019 05:40:00',
				'13.07.2019 05:39:00',
				'5016',
				'13.07.2019 06:45:00',
				'13.07.2019 06:35:00',
				'13.07.2019 06:46:00',
				'43659',
				'43659,9999884259',
				'0,038888888884685',
				'0,0451388888905058',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00763888889196096',
				'',
				'',
				'0,038888888884685',
			],
			[
				'VPBBG',
				'14',
				'2932',
				'13.07.2019 08:10:00',
				'13.07.2019 08:10:00',
				'13.07.2019 08:17:00',
				'527',
				'13.07.2019 09:55:00',
				'13.07.2019 09:51:00',
				'13.07.2019 10:01:00',
				'43659',
				'43659,9999884259',
				'0,0652777777795563',
				'0,0729166666642413',
				'',
				'',
				'',
				'0,00486111110512866',
				'0,00694444444525288',
				'',
				'',
				'0,0652777777795563',
			],
			[
				'VPBCE',
				'14',
				'1402',
				'13.07.2019 10:33:00',
				'13.07.2019 10:35:00',
				'13.07.2019 10:37:00',
				'1403',
				'13.07.2019 11:50:00',
				'13.07.2019 11:40:00',
				'13.07.2019 11:50:00',
				'43659',
				'43659,9999884259',
				'0,0437499999970896',
				'0,0520833333357587',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00694444444525288',
				'',
				'',
				'0,0437499999970896',
			],
			[
				'VPBQG',
				'14',
				'1403',
				'12.07.2019 22:12:00',
				'12.07.2019 22:15:00',
				'12.07.2019 22:16:00',
				'340',
				'13.07.2019 00:55:00',
				'13.07.2019 01:00:00',
				'13.07.2019 01:12:00',
				'43659',
				'43659,9999884259',
				'0,113888888889051',
				'0,111111111109494',
				'',
				'',
				'',
				'0,00277777777228039',
				'0,00833333333866904',
				'',
				'',
				'0,0416666666642413',
			],
			[
				'VPBZD',
				'14',
				'173',
				'13.07.2019 22:53:00',
				'13.07.2019 22:52:00',
				'13.07.2019 22:59:00',
				'174',
				'14.07.2019 00:05:00',
				'13.07.2019 23:54:00',
				'14.07.2019 00:05:00',
				'43659',
				'43659,9999884259',
				'0,0381944444452529',
				'0,0506944444423425',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,007638888884685',
				'',
				'',
				'0,0381944444452529',
			],
			[
				'VQBHW',
				'14',
				'1404',
				'13.07.2019 14:22:00',
				'13.07.2019 14:25:00',
				'13.07.2019 14:30:00',
				'1405',
				'13.07.2019 15:50:00',
				'13.07.2019 15:37:00',
				'13.07.2019 15:52:00',
				'43659',
				'43659,9999884259',
				'0,046527777776646',
				'0,0590277777737356',
				'',
				'',
				'',
				'0,00555555555183673',
				'0,0104166666715173',
				'',
				'',
				'0,046527777776646',
			],
			[
				'VQBRV',
				'14',
				'1412',
				'13.07.2019 12:38:00',
				'13.07.2019 12:40:00',
				'13.07.2019 12:43:00',
				'1413',
				'13.07.2019 13:45:00',
				'13.07.2019 13:37:00',
				'13.07.2019 13:47:00',
				'43659',
				'43659,9999884259',
				'0,0374999999985448',
				'0,0451388888832298',
				'',
				'',
				'',
				'0,00347222222626442',
				'0,00694444444525288',
				'',
				'',
				'0,0374999999985448',
			],
			[
				'VQBTC',
				'14',
				'564',
				'13.07.2019 01:05:00',
				'13.07.2019 01:15:00',
				'13.07.2019 01:12:00',
				'406',
				'13.07.2019 02:10:00',
				'13.07.2019 02:04:00',
				'13.07.2019 02:15:00',
				'43659',
				'43659,9999884259',
				'0,0361111111051287',
				'0,0381944444452529',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00763888889196096',
				'',
				'',
				'0,0361111111051287',
			],
			[
				'VQBTW',
				'14',
				'1410',
				'13.07.2019 05:57:00',
				'13.07.2019 06:00:00',
				'13.07.2019 05:58:00',
				'1411',
				'13.07.2019 07:05:00',
				'13.07.2019 06:55:00',
				'13.07.2019 07:06:00',
				'43659',
				'43659,9999884259',
				'0,0395833333313931',
				'0,0451388888905058',
				'',
				'',
				'',
				'0,000694444446708076',
				'0,007638888884685',
				'',
				'',
				'0,0395833333313931',
			],
			[
				'VQBTY',
				'14',
				'624',
				'13.07.2019 16:04:00',
				'13.07.2019 16:05:00',
				'13.07.2019 16:10:00',
				'624',
				'13.07.2019 17:10:00',
				'13.07.2019 17:19:00',
				'13.07.2019 17:28:00',
				'43659',
				'43659,9999884259',
				'0,0479166666700621',
				'0,0451388888905058',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,00624999999854481',
				'',
				'',
				'0,0479166666700621',
			],
			[
				'VQBYH',
				'14',
				'5019',
				'13.07.2019 18:27:00',
				'13.07.2019 18:25:00',
				'13.07.2019 18:31:00',
				'5020',
				'13.07.2019 19:35:00',
				'13.07.2019 19:19:00',
				'13.07.2019 19:43:00',
				'43659',
				'43659,9999884259',
				'0,0333333333328483',
				'0,0486111111094942',
				'',
				'',
				'',
				'0,00277777777228039',
				'0,0166666666700621',
				'',
				'',
				'0,0333333333328483',
			],
			[
				'VPBBG',
				'15',
				'528',
				'13.07.2019 17:19:00',
				'13.07.2019 17:20:00',
				'13.07.2019 17:26:00',
				'661',
				'13.07.2019 23:00:00',
				'13.07.2019 22:58:00',
				'13.07.2019 23:08:00',
				'43659',
				'43659,9999884259',
				'0,230555555550382',
				'0,23611111111677',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00694444444525288',
				'',
				'',
				'0,230555555550382',
			],
			[
				'VPBDL',
				'15',
				'210',
				'13.07.2019 12:48:00',
				'13.07.2019 12:50:00',
				'13.07.2019 12:55:00',
				'29',
				'13.07.2019 16:25:00',
				'13.07.2019 16:29:00',
				'13.07.2019 16:40:00',
				'43659',
				'43659,9999884259',
				'0,148611111108039',
				'0,149305555562023',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00763888889196096',
				'',
				'',
				'0,148611111108039',
			],
			[
				'VPBIP',
				'15',
				'1436',
				'13.07.2019 00:56:00',
				'13.07.2019 01:00:00',
				'13.07.2019 01:01:00',
				'1437',
				'13.07.2019 03:40:00',
				'13.07.2019 03:30:00',
				'13.07.2019 03:40:00',
				'43659',
				'43659,9999884259',
				'0,103472222224809',
				'0,11111111111677',
				'',
				'',
				'',
				'0,00347222221898846',
				'0,00694444444525288',
				'',
				'',
				'0,103472222224809',
			],
			[
				'VPBJG',
				'15',
				'1842',
				'13.07.2019 08:40:00',
				'13.07.2019 09:42:01',
				'13.07.2019 09:42:01',
				'131',
				'13.07.2019 11:25:00',
				'13.07.2019 11:30:00',
				'13.07.2019 11:40:00',
				'43659',
				'43659,9999884259',
				'0,07498842592031',
				'0,0715162037013215',
				'',
				'',
				'',
				'0,0430671296344372',
				'0,00694444444525288',
				'',
				'',
				'0,07498842592031',
			],
			[
				'VPBCP',
				'52',
				'305',
				'13.07.2019 23:27:00',
				'13.07.2019 23:25:00',
				'13.07.2019 23:35:00',
				'305',
				'14.07.2019 00:30:00',
				'14.07.2019 00:45:00',
				'14.07.2019 00:55:00',
				'43659',
				'43659,9999884259',
				'0,0486111111094942',
				'0,0451388888905058',
				'-0,982638888890506',
				'0,0312615740767797',
				'',
				'0,00555555555911269',
				'0,00694444444525288',
				'',
				'',
				'0,0173495370327146',
			],
			[
				'VQBSG',
				'15',
				'1408',
				'12.07.2019 22:52:00',
				'12.07.2019 23:00:00',
				'12.07.2019 23:00:00',
				'1409',
				'13.07.2019 00:15:00',
				'13.07.2019 00:14:00',
				'13.07.2019 00:25:00',
				'43659',
				'43659,9999884259',
				'0,0513888888890506',
				'0,0520833333284827',
				'',
				'',
				'',
				'0,00555555555911269',
				'0,007638888884685',
				'',
				'',
				'0,00972222222480923',
			],
			[
				'VQBTY',
				'15',
				'174',
				'13.07.2019 06:10:00',
				'13.07.2019 06:05:00',
				'13.07.2019 06:14:00',
				'174',
				'13.07.2019 09:30:00',
				'13.07.2019 09:24:00',
				'13.07.2019 09:30:00',
				'43659',
				'43659,9999884259',
				'0,131944444445253',
				'0,14236111111677',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00416666666569654',
				'',
				'',
				'0,131944444445253',
			],
			[
				'VPBKX',
				'17',
				'7710',
				'13.07.2019 22:21:00',
				'13.07.2019 22:20:00',
				'13.07.2019 22:27:00',
				'2931',
				'14.07.2019 00:55:00',
				'14.07.2019 00:50:00',
				'14.07.2019 01:00:00',
				'43659',
				'43659,9999884259',
				'0,0993055555518367',
				'0,107638888890506',
				'-0,935416666667152',
				'0,0347337962957681',
				'',
				'0,00416666666569654',
				'0,00694444444525288',
				'',
				'',
				'0,0645717592560686',
			],
			[
				'VQBFV',
				'16',
				'300',
				'13.07.2019 07:58:00',
				'13.07.2019 08:00:00',
				'13.07.2019 08:03:00',
				'300',
				'13.07.2019 10:00:00',
				'13.07.2019 09:52:00',
				'13.07.2019 10:03:00',
				'43659',
				'43659,9999884259',
				'0,0756944444437977',
				'0,0833333333284827',
				'',
				'',
				'',
				'0,00347222222626442',
				'0,007638888884685',
				'',
				'',
				'0,0756944444437977',
			],
			[
				'VQBHT',
				'16',
				'1406',
				'12.07.2019 20:37:00',
				'12.07.2019 20:40:00',
				'12.07.2019 20:44:00',
				'1407',
				'13.07.2019 02:00:00',
				'13.07.2019 01:55:00',
				'13.07.2019 02:12:00',
				'43659',
				'43659,9999884259',
				'0,215972222220444',
				'0,222222222226264',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,0118055555576575',
				'',
				'',
				'0,0798611111094942',
			],
			[
				'VPBKB',
				'17',
				'263',
				'12.07.2019 19:13:00',
				'12.07.2019 19:15:00',
				'12.07.2019 19:18:00',
				'219',
				'13.07.2019 01:00:00',
				'13.07.2019 00:56:00',
				'13.07.2019 01:10:00',
				'43659',
				'43659,9999884259',
				'0,234722222223354',
				'0,239583333328483',
				'',
				'',
				'',
				'0,00347222222626442',
				'0,00972222221753327',
				'',
				'',
				'0,038888888891961',
			],
			[
				'VPBQY',
				'15',
				'515',
				'13.07.2019 23:29:00',
				'13.07.2019 23:28:00',
				'13.07.2019 23:35:00',
				'340',
				'14.07.2019 00:55:00',
				'14.07.2019 00:55:00',
				'14.07.2019 01:04:00',
				'43659',
				'43659,9999884259',
				'0,0555555555547471',
				'0,0604166666671517',
				'-0,982638888890506',
				'0,0382060185220325',
				'',
				'0,00416666666569654',
				'0,00624999999854481',
				'',
				'',
				'0,0173495370327146',
			],
			[
				'VPBBG',
				'18',
				'265',
				'12.07.2019 21:15:00',
				'12.07.2019 21:15:00',
				'12.07.2019 21:21:00',
				'2931',
				'13.07.2019 00:55:00',
				'13.07.2019 00:50:00',
				'13.07.2019 00:58:00',
				'43659',
				'43659,9999884259',
				'0,145138888889051',
				'0,152777777781012',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,00555555555911269',
				'',
				'',
				'0,0347222222189885',
			],
			[
				'VPBQJ',
				'57',
				'403',
				'13.07.2019 21:42:00',
				'13.07.2019 21:40:00',
				'13.07.2019 21:48:00',
				'404',
				'14.07.2019 01:20:00',
				'14.07.2019 01:10:00',
				'14.07.2019 01:21:00',
				'43659',
				'43659,9999884259',
				'0,140277777776646',
				'0,152777777773736',
				'-0,908333333332848',
				'0,0486226851862739',
				'',
				'0,00416666666569654',
				'0,00763888889196096',
				'',
				'',
				'0,0916550925903721',
			],
			[
				'VPBIT',
				'56',
				'6401',
				'13.07.2019 23:41:00',
				'13.07.2019 23:40:00',
				'13.07.2019 23:48:00',
				'6402',
				'14.07.2019 01:15:00',
				'14.07.2019 01:12:00',
				'14.07.2019 01:23:00',
				'43659',
				'43659,9999884259',
				'0,0583333333343035',
				'0,0659722222262644',
				'-0,991666666668607',
				'0,05001157407969',
				'',
				'0,00486111111240461',
				'0,007638888884685',
				'',
				'',
				'0,00832175925461343',
			],
			[
				'VPBKB',
				'18',
				'778',
				'13.07.2019 20:50:00',
				'13.07.2019 20:49:00',
				'13.07.2019 20:55:00',
				'262',
				'14.07.2019 01:18:38',
				'14.07.2019 01:18:38',
				'14.07.2019 03:22:00',
				'43659',
				'43659,9999884259',
				'0,183078703703359',
				'0,187245370376331',
				'-0,871527777781012',
				'0,05461805556115',
				'',
				'0,00347222222626442',
				'0,0856712962922757',
				'',
				'',
				'0,128460648142209',
			],
			[
				'89087',
				'21',
				'89087',
				'12.07.2019 15:44:52',
				'12.07.2019 15:44:52',
				'12.07.2019 15:44:52',
				'211',
				'13.07.2019 03:30:00',
				'13.07.2019 03:21:00',
				'13.07.2019 03:32:00',
				'43659',
				'43659,9999884259',
				'0,483425925922347',
				'0,489675925928168',
				'',
				'',
				'',
				'0',
				'0,00763888889196096',
				'',
				'',
				'0,139583333329938',
			],
			[
				'89087',
				'21',
				'212',
				'13.07.2019 14:25:00',
				'13.07.2019 14:30:00',
				'13.07.2019 14:32:00',
				'89087',
				'13.07.2019 17:56:45',
				'13.07.2019 17:56:45',
				'13.07.2019 17:56:45',
				'43659',
				'43659,9999884259',
				'0,142187500001455',
				'0,143576388894871',
				'',
				'',
				'',
				'0,00486111111240461',
				'0',
				'',
				'',
				'0,142187500001455',
			],
			[
				'VPBOA',
				'10A',
				'5804',
				'13.07.2019 23:20:00',
				'13.07.2019 23:20:00',
				'13.07.2019 23:27:00',
				'5609',
				'14.07.2019 01:30:00',
				'14.07.2019 01:29:00',
				'14.07.2019 01:42:00',
				'43659',
				'43659,9999884259',
				'0,0847222222218988',
				'0,0902777777810115',
				'-0,977083333331393',
				'0,0618171296300716',
				'',
				'0,00486111111240461',
				'0,00902777777810115',
				'',
				'',
				'0,0229050925918273',
			],
			[
				'76803',
				'27',
				'76803',
				'13.07.2019 07:49:00',
				'13.07.2019 07:50:00',
				'13.07.2019 08:01:00',
				'76803',
				'13.07.2019 09:00:00',
				'13.07.2019 09:08:00',
				'13.07.2019 09:21:00',
				'43659',
				'43659,9999884259',
				'0,0465277777839219',
				'0,0486111111094942',
				'',
				'',
				'',
				'0,00833333333139308',
				'0,0090277777708252',
				'',
				'',
				'0,0465277777839219',
			],
			[
				'86898',
				'27',
				'86898',
				'13.07.2019 19:11:00',
				'13.07.2019 19:10:00',
				'13.07.2019 19:22:00',
				'86898',
				'13.07.2019 23:01:05',
				'13.07.2019 23:01:05',
				'13.07.2019 23:01:05',
				'43659',
				'43659,9999884259',
				'0,15214120370365',
				'0,160474537035043',
				'',
				'',
				'',
				'0,007638888884685',
				'0',
				'',
				'',
				'0,15214120370365',
			],
			[
				'VQBTP',
				'58',
				'662',
				'13.07.2019 21:52:00',
				'13.07.2019 21:49:00',
				'13.07.2019 21:56:00',
				'264',
				'14.07.2019 01:50:00',
				'14.07.2019 01:40:00',
				'14.07.2019 01:50:00',
				'43659',
				'43659,9999884259',
				'0,155555555553292',
				'0,167361111110949',
				'-0,913888888891961',
				'0,0694560185220325',
				'',
				'0,00277777777955635',
				'0,00694444444525288',
				'',
				'',
				'0,0860995370312594',
			],
			[
				'76363',
				'40',
				'9622',
				'12.07.2019 12:50:00',
				'12.07.2019 12:50:00',
				'12.07.2019 13:00:00',
				'9621',
				'13.07.2019 02:00:00',
				'13.07.2019 01:48:00',
				'13.07.2019 02:10:00',
				'43659',
				'43659,9999884259',
				'0,533333333332848',
				'0,54861111111677',
				'',
				'',
				'',
				'0,00694444444525288',
				'0,0152777777839219',
				'',
				'',
				'0,0749999999970896',
			],
			[
				'VQBGJ',
				'1B',
				'VQBGJ',
				'13.07.2019 19:59:21',
				'13.07.2019 19:59:21',
				'13.07.2019 19:59:21',
				'1',
				'14.07.2019 01:42:42',
				'14.07.2019 01:42:42',
				'14.07.2019 05:30:00',
				'43659',
				'43659,9999884259',
				'0,238437500003783',
				'0,238437500003783',
				'-0,832881944443216',
				'0,0713310185237788',
				'',
				'0',
				'0,157847222217242',
				'',
				'',
				'0,167106481480005',
			],
			[
				'VQBNE',
				'48',
				'814',
				'12.07.2019 22:21:00',
				'12.07.2019 22:20:00',
				'12.07.2019 22:26:00',
				'823',
				'13.07.2019 02:55:00',
				'13.07.2019 02:54:00',
				'13.07.2019 03:05:00',
				'43659',
				'43659,9999884259',
				'0,18611111111386',
				'0,190972222226264',
				'',
				'',
				'',
				'0,00347222221898846',
				'0,007638888884685',
				'',
				'',
				'0,120833333334303',
			],
			[
				'VQBFB',
				'49',
				'862',
				'12.07.2019 19:28:00',
				'12.07.2019 19:30:00',
				'12.07.2019 19:33:00',
				'807',
				'13.07.2019 02:30:00',
				'13.07.2019 02:24:00',
				'13.07.2019 02:35:00',
				'43659',
				'43659,9999884259',
				'0,285416666665697',
				'0,291666666664241',
				'',
				'',
				'',
				'0,00347222221898846',
				'0,00763888889196096',
				'',
				'',
				'0,0999999999985448',
			],
			[
				'76363',
				'40',
				'9622',
				'13.07.2019 12:44:00',
				'13.07.2019 12:45:00',
				'13.07.2019 12:53:00',
				'9621',
				'14.07.2019 02:00:00',
				'14.07.2019 01:45:00',
				'14.07.2019 02:02:00',
				'43659',
				'43659,9999884259',
				'0,536111111105129',
				'0,552083333335759',
				'-0,536805555559113',
				'0,072928240741021',
				'',
				'0,00625000000582077',
				'0,0118055555576575',
				'',
				'',
				'0,463182870364108',
			],
			[
				'VQBAT',
				'50',
				'6581',
				'12.07.2019 23:59:00',
				'12.07.2019 23:55:00',
				'13.07.2019 00:06:00',
				'6581',
				'13.07.2019 00:40:00',
				'13.07.2019 01:20:00',
				'13.07.2019 01:32:00',
				'43659',
				'43659,9999884259',
				'0,0513888888890506',
				'0,03125',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00833333333139308',
				'',
				'',
				'0,0555555555547471',
			],
			[
				'VPBFA',
				'16',
				'1406',
				'13.07.2019 20:37:00',
				'13.07.2019 20:37:00',
				'13.07.2019 20:42:00',
				'1407',
				'14.07.2019 02:00:00',
				'14.07.2019 01:55:00',
				'14.07.2019 02:08:00',
				'43659',
				'43659,9999884259',
				'0,217361111106584',
				'0,224305555559113',
				'-0,86250000000291',
				'0,0798726851862739',
				'',
				'0,00347222222626442',
				'0,00902777777810115',
				'',
				'',
				'0,13748842592031',
			],
			[
				'VQBCE',
				'8',
				'1070',
				'13.07.2019 20:28:00',
				'13.07.2019 20:30:00',
				'13.07.2019 20:34:00',
				'727',
				'14.07.2019 01:40:00',
				'14.07.2019 01:57:00',
				'14.07.2019 02:05:00',
				'43659',
				'43659,9999884259',
				'0,224305555559113',
				'0,215277777781012',
				'-0,856944444443798',
				'0,08126157407969',
				'',
				'0,00416666666569654',
				'0,00555555555183673',
				'',
				'',
				'0,143043981479423',
			],
			[
				'VQBMA',
				'51',
				'113',
				'13.07.2019 01:02:00',
				'13.07.2019 01:00:00',
				'13.07.2019 01:07:00',
				'302',
				'13.07.2019 02:00:00',
				'13.07.2019 01:53:00',
				'13.07.2019 02:00:00',
				'43659',
				'43659,9999884259',
				'0,0319444444467081',
				'0,0416666666715173',
				'',
				'',
				'',
				'0,00347222221898846',
				'0,00486111111240461',
				'',
				'',
				'0,0319444444467081',
			],
			[
				'VQBNE',
				'51',
				'824',
				'13.07.2019 08:34:00',
				'13.07.2019 08:35:00',
				'13.07.2019 08:38:00',
				'432',
				'13.07.2019 13:00:00',
				'13.07.2019 12:48:00',
				'13.07.2019 13:00:00',
				'43659',
				'43659,9999884259',
				'0,173611111109494',
				'0,184027777773736',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00833333333139308',
				'',
				'',
				'0,173611111109494',
			],
			[
				'VQBUV',
				'51',
				'173',
				'12.07.2019 22:26:00',
				'12.07.2019 22:30:00',
				'12.07.2019 22:33:00',
				'174',
				'13.07.2019 00:05:00',
				'13.07.2019 00:05:00',
				'13.07.2019 00:16:00',
				'43659',
				'43659,9999884259',
				'0,0638888888861402',
				'0,0659722222189885',
				'',
				'',
				'',
				'0,00486111111240461',
				'0,00763888889196096',
				'',
				'',
				'0,00347222221898846',
			],
			[
				'VQBFB',
				'49',
				'869',
				'13.07.2019 19:28:00',
				'13.07.2019 19:30:00',
				'13.07.2019 19:33:00',
				'823',
				'14.07.2019 02:15:00',
				'14.07.2019 02:07:00',
				'14.07.2019 02:19:00',
				'43659',
				'43659,9999884259',
				'0,273611111108039',
				'0,28125',
				'-0,814583333332848',
				'0,088206018517667',
				'',
				'0,00347222221898846',
				'0,00833333333866904',
				'',
				'',
				'0,185405092590372',
			],
			[
				'VPBNN',
				'52',
				'6401',
				'12.07.2019 23:52:00',
				'12.07.2019 23:50:00',
				'12.07.2019 23:57:00',
				'6402',
				'13.07.2019 01:15:00',
				'13.07.2019 01:09:00',
				'13.07.2019 01:17:00',
				'43659',
				'43659,9999884259',
				'0,0500000000029104',
				'0,0590277777810115',
				'',
				'',
				'',
				'0,00347222222626442',
				'0,00555555555183673',
				'',
				'',
				'0,0479166666700621',
			],
			[
				'VQBFB',
				'52',
				'808',
				'13.07.2019 12:34:00',
				'13.07.2019 12:36:00',
				'13.07.2019 12:40:00',
				'870',
				'13.07.2019 16:15:00',
				'13.07.2019 16:06:00',
				'13.07.2019 16:15:00',
				'43659',
				'43659,9999884259',
				'0,143055555548926',
				'0,152083333334303',
				'',
				'',
				'',
				'0,0041666666729725',
				'0,00625000000582077',
				'',
				'',
				'0,143055555548926',
			],
			[
				'VQBLJ',
				'52',
				'111',
				'13.07.2019 04:36:00',
				'13.07.2019 04:35:00',
				'13.07.2019 04:40:00',
				'105',
				'13.07.2019 06:35:00',
				'13.07.2019 06:35:00',
				'13.07.2019 06:43:00',
				'43659',
				'43659,9999884259',
				'0,0798611111094942',
				'0,0833333333357587',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00555555555911269',
				'',
				'',
				'0,0798611111094942',
			],
			[
				'VQBNE',
				'50',
				'431',
				'13.07.2019 23:17:00',
				'13.07.2019 23:15:00',
				'13.07.2019 23:21:00',
				'825',
				'14.07.2019 02:35:00',
				'14.07.2019 02:28:00',
				'14.07.2019 02:37:00',
				'43659',
				'43659,9999884259',
				'0,129861111112405',
				'0,138888888890506',
				'-0,972916666665697',
				'0,102789351854881',
				'',
				'0,00277777777955635',
				'0,00624999999854481',
				'',
				'',
				'0,0270717592575238',
			],
			[
				'VPBQZ',
				'56',
				'339',
				'12.07.2019 23:45:00',
				'12.07.2019 23:45:00',
				'12.07.2019 23:52:00',
				'516',
				'13.07.2019 00:40:00',
				'13.07.2019 00:37:00',
				'13.07.2019 00:49:00',
				'43659',
				'43659,9999884259',
				'0,03125',
				'0,0381944444452529',
				'',
				'',
				'',
				'0,00486111110512866',
				'0,00833333333866904',
				'',
				'',
				'0,0256944444408873',
			],
			[
				'VQBTR',
				'56',
				'8072',
				'13.07.2019 19:36:00',
				'13.07.2019 19:35:00',
				'13.07.2019 19:47:00',
				'8071',
				'13.07.2019 21:35:00',
				'13.07.2019 21:27:00',
				'13.07.2019 21:44:00',
				'43659',
				'43659,9999884259',
				'0,0694444444452529',
				'0,0833333333357587',
				'',
				'',
				'',
				'0,00763888889196096',
				'0,0118055555503815',
				'',
				'',
				'0,0694444444452529',
			],
			[
				'VPBFZ',
				'57',
				'261',
				'13.07.2019 08:58:00',
				'13.07.2019 09:00:00',
				'13.07.2019 09:03:00',
				'266',
				'13.07.2019 13:40:00',
				'13.07.2019 13:39:00',
				'13.07.2019 13:49:00',
				'43659',
				'43659,9999884259',
				'0,191666666665697',
				'0,194444444445253',
				'',
				'',
				'',
				'0,00347222221898846',
				'0,00694444444525288',
				'',
				'',
				'0,191666666665697',
			],
			[
				'VQBCY',
				'7',
				'78',
				'13.07.2019 18:22:00',
				'13.07.2019 23:13:23',
				'13.07.2019 23:13:23',
				'701',
				'14.07.2019 02:50:00',
				'14.07.2019 02:50:00',
				'14.07.2019 03:01:00',
				'43659',
				'43659,9999884259',
				'0,150428240740439',
				'0,150428240740439',
				'-0,967627314814308',
				'0,118067129631527',
				'',
				'0,202349537037662',
				'0,00763888889196096',
				'',
				'',
				'0,0323611111089122',
			],
			[
				'VQBKQ',
				'57',
				'69',
				'13.07.2019 07:16:00',
				'13.07.2019 07:20:00',
				'13.07.2019 07:19:00',
				'70',
				'13.07.2019 08:25:00',
				'13.07.2019 08:19:00',
				'13.07.2019 08:31:00',
				'43659',
				'43659,9999884259',
				'0,0416666666715173',
				'0,0451388888905058',
				'',
				'',
				'',
				'0,00208333333284827',
				'0,00833333333139308',
				'',
				'',
				'0,0416666666715173',
			],
			[
				'VQBLJ',
				'57',
				'106',
				'13.07.2019 14:33:00',
				'13.07.2019 14:40:00',
				'13.07.2019 14:37:00',
				'112',
				'13.07.2019 16:05:00',
				'13.07.2019 15:54:00',
				'13.07.2019 16:06:00',
				'43659',
				'43659,9999884259',
				'0,0534722222218988',
				'0,0590277777810115',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00833333333139308',
				'',
				'',
				'0,0534722222218988',
			],
			[
				'22325',
				'57',
				'61',
				'12.07.2019 13:18:00',
				'12.07.2019 13:20:00',
				'12.07.2019 13:20:00',
				'61',
				'13.07.2019 04:00:00',
				'13.07.2019 02:45:00',
				'13.07.2019 03:01:00',
				'43659',
				'43659,9999884259',
				'0,559027777781012',
				'0,611111111109494',
				'',
				'',
				'',
				'0,00138888888614019',
				'0,0111111111109494',
				'',
				'',
				'0,114583333335759',
			],
			[
				'VPBTZ',
				'58',
				'2',
				'13.07.2019 18:19:00',
				'13.07.2019 18:20:00',
				'13.07.2019 18:23:00',
				'2985',
				'13.07.2019 21:20:00',
				'13.07.2019 21:15:00',
				'13.07.2019 21:25:00',
				'43659',
				'43659,9999884259',
				'0,119444444440887',
				'0,125',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00694444444525288',
				'',
				'',
				'0,119444444440887',
			],
			[
				'OELDR',
				'69',
				'92R',
				'13.07.2019 12:26:00',
				'13.07.2019 12:30:00',
				'13.07.2019 12:33:00',
				'92R',
				'14.07.2019 03:00:00',
				'14.07.2019 03:13:00',
				'14.07.2019 03:29:00',
				'43659',
				'43659,9999884259',
				'0,611111111109494',
				'0,604166666664241',
				'-0,522916666668607',
				'0,134039351854881',
				'',
				'0,00486111111240461',
				'0,0111111111109494',
				'',
				'',
				'0,477071759254613',
			],
			[
				'25533',
				'58',
				'65',
				'12.07.2019 13:18:00',
				'12.07.2019 13:20:00',
				'12.07.2019 13:22:00',
				'65',
				'13.07.2019 04:00:00',
				'13.07.2019 02:47:00',
				'13.07.2019 03:02:00',
				'43659',
				'43659,9999884259',
				'0,559027777781012',
				'0,611111111109494',
				'',
				'',
				'',
				'0,00277777777228039',
				'0,0104166666642413',
				'',
				'',
				'0,115972222221899',
			],
			[
				'87971',
				'58',
				'77',
				'13.07.2019 14:18:00',
				'13.07.2019 14:25:00',
				'13.07.2019 14:24:00',
				'77',
				'13.07.2019 15:00:00',
				'13.07.2019 14:57:00',
				'13.07.2019 15:14:00',
				'43659',
				'43659,9999884259',
				'0,0229166666686069',
				'0,0243055555547471',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,0118055555576575',
				'',
				'',
				'0,0229166666686069',
			],
			[
				'89087',
				'21',
				'89087',
				'13.07.2019 17:56:45',
				'13.07.2019 17:56:45',
				'13.07.2019 17:56:45',
				'121',
				'14.07.2019 03:25:00',
				'14.07.2019 03:20:00',
				'14.07.2019 03:32:00',
				'43659',
				'43659,9999884259',
				'0,391145833331393',
				'0,394618055550382',
				'-0,747743055559113',
				'0,138900462967285',
				'',
				'0',
				'0,00833333333139308',
				'',
				'',
				'0,252245370364108',
			],
			[
				'VQBCY',
				'60',
				'78',
				'13.07.2019 18:22:00',
				'13.07.2019 18:21:00',
				'13.07.2019 18:25:00',
				'701',
				'13.07.2019 23:13:23',
				'13.07.2019 23:13:23',
				'14.07.2019 03:01:00',
				'43659',
				'43659,9999884259',
				'0,200266203704814',
				'0,20304398148437',
				'',
				'',
				'',
				'0,00208333333284827',
				'0,1580671296324',
				'',
				'',
				'0,200266203704814',
			],
			[
				'VQBTZ',
				'60',
				'702',
				'12.07.2019 22:15:00',
				'12.07.2019 22:15:00',
				'12.07.2019 22:19:00',
				'264',
				'13.07.2019 01:50:00',
				'13.07.2019 01:39:00',
				'13.07.2019 01:53:00',
				'43659',
				'43659,9999884259',
				'0,138888888890506',
				'0,149305555554747',
				'',
				'',
				'',
				'0,00277777777228039',
				'0,00972222222480923',
				'',
				'',
				'0,0687499999985448',
			],
			[
				'VQBCI',
				'61',
				'322',
				'13.07.2019 17:08:00',
				'13.07.2019 17:10:00',
				'13.07.2019 17:14:00',
				'VQBCI',
				'13.07.2019 17:59:21',
				'13.07.2019 17:59:21',
				'13.07.2019 20:45:55',
				'43659',
				'43659,9999884259',
				'0,031493055561441',
				'0,0342708333337214',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,115671296291112',
				'',
				'',
				'0,031493055561441',
			],
			[
				'VQBCI',
				'61',
				'VQBCI',
				'13.07.2019 20:45:55',
				'13.07.2019 22:29:48',
				'13.07.2019 22:29:48',
				'201',
				'14.07.2019 04:00:00',
				'14.07.2019 03:51:00',
				'14.07.2019 04:00:00',
				'43659',
				'43659,9999884259',
				'0,223055555557949',
				'0,229305555556493',
				'-0,937361111107748',
				'0,160428240742476',
				'',
				'0,0721412037019036',
				'0,00624999999854481',
				'',
				'',
				'0,0626273148154723',
			],
			[
				'VQBQN',
				'61',
				'524',
				'12.07.2019 21:19:00',
				'12.07.2019 21:20:00',
				'12.07.2019 21:25:00',
				'223',
				'13.07.2019 01:25:00',
				'13.07.2019 01:22:00',
				'13.07.2019 01:35:00',
				'43659',
				'43659,9999884259',
				'0,164583333331393',
				'0,170138888890506',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,00902777777810115',
				'',
				'',
				'0,0569444444408873',
			],
			[
				'VPBQW',
				'19',
				'226',
				'13.07.2019 20:42:00',
				'13.07.2019 20:41:00',
				'13.07.2019 20:47:00',
				'223',
				'14.07.2019 04:20:00',
				'14.07.2019 04:14:00',
				'14.07.2019 04:22:00',
				'43659',
				'43659,9999884259',
				'0,310416666667152',
				'0,318749999998545',
				'-0,865972222221899',
				'0,17640046296583',
				'',
				'0,00347222221898846',
				'0,00555555555183673',
				'',
				'',
				'0,134016203701322',
			],
			[
				'86898',
				'27',
				'86898',
				'13.07.2019 23:01:05',
				'13.07.2019 23:01:05',
				'13.07.2019 23:01:05',
				'86898',
				'14.07.2019 09:00:00',
				'14.07.2019 08:33:00',
				'14.07.2019 09:02:00',
				'43659',
				'43659,9999884259',
				'0,397164351852552',
				'0,415914351855463',
				'-0,959085648144537',
				'0,356261574073869',
				'',
				'0',
				'0,0201388888890506',
				'',
				'',
				'0,0409027777786832',
			],
			[
				'OHLKK',
				'10A',
				'725',
				'13.07.2019 00:01:00',
				'12.07.2019 23:55:00',
				'13.07.2019 00:05:00',
				'726',
				'13.07.2019 00:50:00',
				'13.07.2019 00:50:00',
				'13.07.2019 01:08:00',
				'43659',
				'43659,9999884259',
				'0,03125',
				'0,0381944444379769',
				'',
				'',
				'',
				'0,00277777777228039',
				'0,0125000000043656',
				'',
				'',
				'0,03125',
			],
			[
				'VQBCZ',
				'10A',
				'2950',
				'13.07.2019 01:38:00',
				'13.07.2019 01:40:00',
				'13.07.2019 01:41:00',
				'301',
				'13.07.2019 03:00:00',
				'13.07.2019 03:01:00',
				'13.07.2019 03:12:00',
				'43659',
				'43659,9999884259',
				'0,0555555555547471',
				'0,0555555555547471',
				'',
				'',
				'',
				'0,00208333333284827',
				'0,007638888884685',
				'',
				'',
				'0,0555555555547471',
			],
			[
				'TCJLY',
				'10B',
				'475',
				'13.07.2019 01:23:00',
				'13.07.2019 01:25:00',
				'13.07.2019 01:29:00',
				'476',
				'13.07.2019 03:00:00',
				'13.07.2019 03:00:00',
				'13.07.2019 03:15:00',
				'43659',
				'43659,9999884259',
				'0,0631944444467081',
				'0,0659722222189885',
				'',
				'',
				'',
				'0,00416666666569654',
				'0,0104166666642413',
				'',
				'',
				'0,0631944444467081',
			],
			[
				'TCTJR',
				'10B',
				'8181',
				'13.07.2019 22:00:00',
				'13.07.2019 22:00:00',
				'13.07.2019 22:08:00',
				'8182',
				'13.07.2019 23:10:00',
				'13.07.2019 23:06:00',
				'13.07.2019 23:15:00',
				'43659',
				'43659,9999884259',
				'0,0402777777781012',
				'0,0486111111167702',
				'',
				'',
				'',
				'0,00555555555911269',
				'0,00624999999854481',
				'',
				'',
				'0,0402777777781012',
			],
			[
				'VPBKB',
				'10B',
				'220',
				'13.07.2019 08:04:00',
				'13.07.2019 08:05:00',
				'13.07.2019 08:08:00',
				'777',
				'13.07.2019 10:00:00',
				'13.07.2019 09:54:00',
				'13.07.2019 10:06:00',
				'43659',
				'43659,9999884259',
				'0,0736111111109494',
				'0,0798611111094942',
				'',
				'',
				'',
				'0,00277777777955635',
				'0,00833333333139308',
				'',
				'',
				'0,0736111111109494',
			],
			[
				'VPBQW',
				'10B',
				'2954',
				'13.07.2019 04:17:00',
				'13.07.2019 04:15:00',
				'13.07.2019 04:20:00',
				'201',
				'13.07.2019 06:00:00',
				'13.07.2019 05:51:00',
				'13.07.2019 06:00:00',
				'43659',
				'43659,9999884259',
				'0,0631944444467081',
				'0,0729166666642413',
				'',
				'',
				'',
				'0,00208333333284827',
				'0,00624999999854481',
				'',
				'',
				'0,0631944444467081',
			],
			[
				'VQBGJ',
				'1B',
				'299',
				'13.07.2019 16:51:00',
				'13.07.2019 17:40:30',
				'13.07.2019 17:40:30',
				'VQBGJ',
				'13.07.2019 19:59:21',
				'13.07.2019 19:59:21',
				'13.07.2019 19:59:21',
				'43659',
				'43659,9999884259',
				'0,0964236111103673',
				'0,0964236111103673',
				'',
				'',
				'',
				'0,0343750000029104',
				'0',
				'',
				'',
				'0,0964236111103673',
			]
		);

		const reportData5 = new ReportData();
		reportData5.id = 5;
		reportData5.visual = 2;
		reportData5.titles = ['Параметр', 'Ед.изм.', '2018г.', '2019г.', '2020г.', 'Уровень к 2018г.', 'Уровень к 2019г.'];
		reportData5.data.push(
			['1. Общий объем', '', '', '', '', '', ''],
			['1.1. Пассажиры, в т.ч.', 'чел', '1189324', '1242314', '142676', '0,12', '0,1148'],
			['Внутренние', 'чел', '815704', '875219', '140209', '0,1719', '0,1602'],
			['Международные', 'чел', '336487', '327322', '630', '0,0019', '0,0019'],
			['СНГ', 'чел', '37133', '39773', '1837', '0,0495', '0,0462'],
			['1.2. Самолето-вылеты и прибытие ВС, в т.ч.', 'ед', '12856', '12669', '4036', '0,3139', '0,3186'],
			['Внутренние', 'ед', '10207', '10273', '3571', '0,3499', '0,3476'],
			['Международные', 'ед', '2236', '1975', '432', '0,1932', '0,2187'],
			['СНГ', 'ед', '413', '421', '33', '0,0799', '0,0784'],
			['1.3. Груз всего, в т.ч.', 'т', '3052,64', '2698,17', '1807,45', '0,5921', '0,6699'],
			['Внутренние', 'т', '2351,34', '2045', '1648,37', '0,701', '0,806'],
			['Международные', 'т', '635,5', '574,19', '151,92', '0,2391', '0,2646'],
			['СНГ', 'т', '65,8', '78,98', '7,16', '0,1088', '0,0907'],
			['1.4. Груз без грузовых ВС, в т.ч.', 'т', '2591,45', '2015,14', '1398,31', '0,5396', '0,6939'],
			['Внутренние', 'т', '2327,23', '1773,92', '1391,32', '0,5978', '0,7843'],
			['Международные', 'т', '198,42', '162,24', '0,43', '0,0022', '0,0027'],
			['СНГ', 'т', '65,8', '78,98', '6,56', '0,0997', '0,0831'],
			['1.5. Почта, в т.ч.', 'т', '454,83', '491,37', '376,85', '0,8286', '0,7669'],
			['Внутренние', 'т', '323,04', '398,98', '249,12', '0,7712', '0,6244'],
			['Международные', 'т', '131,79', '92,39', '127,45', '0,9671', '1,3795'],
			['СНГ', 'т', '0', '0', '0,28', '-', '-'],
			['1.6. Багаж, в т.ч.', 'т', '9709,26', '9947,45', '1307,03', '0,1346', '0,1314'],
			['Внутренние', 'т', '5587,56', '5900,29', '1268,79', '0,2271', '0,215'],
			['Международные', 'т', '3606,54', '3510,32', '18,54', '0,0051', '0,0053'],
			['СНГ', 'т', '515,16', '536,84', '19,7', '0,0382', '0,0367'],
			['1.7. Макс. взлетные массы ВС, в т.ч.', 'т', '408940,85', '409992,391', '121797,955', '0,2978', '0,2971'],
			['Внутренние', 'т', '294877,343', '299596,492', '98185,688', '0,333', '0,3277'],
			['Международные', 'т', '98774,525', '95551,778', '22032,126', '0,2231', '0,2306'],
			['СНГ', 'т', '15288,982', '14844,121', '1580,141', '0,1034', '0,1064'],
			['2. Отправки', '', '', '', '', '', ''],
			['2.1. Пассажиры, в т.ч.', 'чел', '601975', '624600', '68939', '0,1145', '0,1104'],
			['Внутренние', 'чел', '407090', '437256', '67634', '0,1661', '0,1547'],
			['Международные', 'чел', '177543', '169124', '41', '0,0002', '0,0002'],
			['СНГ', 'чел', '17342', '18220', '1264', '0,0729', '0,0694'],
			['прямой транзит', 'чел', '15494', '13342', '3281', '0,2118', '0,2459'],
			['2.2. Самолето-вылеты, в т.ч.', 'ед', '6429', '6348', '2016', '0,3136', '0,3176'],
			['Внутренние', 'ед', '5103', '5149', '1781', '0,349', '0,3459'],
			['Международные', 'ед', '1117', '986', '218', '0,1952', '0,2211'],
			['СНГ', 'ед', '209', '213', '17', '0,0813', '0,0798'],
			['2.3. Груз всего, в т.ч.', 'т', '1039,05', '851,51', '534,4', '0,5143', '0,6276'],
			['Внутренние', 'т', '871,68', '773,04', '526,97', '0,6045', '0,6817'],
			['Международные', 'т', '140,3', '43,92', '0,95', '0,0068', '0,0216'],
			['СНГ', 'т', '27,07', '34,55', '6,48', '0,2394', '0,1876'],
			['2.4. Груз без грузовых ВС, в т.ч.', 'т', '978', '588,69', '345,92', '0,3537', '0,5876'],
			['Внутренние', 'т', '856,38', '510,22', '339,03', '0,3959', '0,6645'],
			['Международные', 'т', '94,55', '43,92', '0,41', '0,0043', '0,0093'],
			['СНГ', 'т', '27,07', '34,55', '6,48', '0,2394', '0,1876'],
			['2.5. Почта, в т.ч.', 'т', '133,71', '171,34', '99,89', '0,7471', '0,583'],
			['Внутренние', 'т', '133,71', '171,34', '99,89', '0,7471', '0,583'],
			['Международные', 'т', '0', '0', '0', '-', '-'],
			['СНГ', 'т', '0', '0', '0', '-', '-'],
			['2.6. Багаж, в т.ч.', 'т', '4592,59', '4690,46', '661,27', '0,144', '0,141'],
			['Внутренние', 'т', '2710,93', '2909,06', '643,54', '0,2374', '0,2212'],
			['Международные', 'т', '1636', '1537,47', '0,6', '0,0004', '0,0004'],
			['СНГ', 'т', '245,66', '243,93', '17,13', '0,0697', '0,0702'],
			['3. Прибытия', '', '', '', '', '', ''],
			['3.1. Пассажиры, в т.ч.', 'чел', '571855', '604372', '70456', '0,1232', '0,1166'],
			['Внутренние', 'чел', '394452', '425405', '69296', '0,1757', '0,1629'],
			['Международные', 'чел', '157612', '157414', '587', '0,0037', '0,0037'],
			['СНГ', 'чел', '19791', '21553', '573', '0,029', '0,0266'],
			['3.2. Прибытия, в т.ч.', 'ед', '6427', '6321', '2020', '0,3143', '0,3196'],
			['Внутренние', 'ед', '5104', '5124', '1790', '0,3507', '0,3493'],
			['Международные', 'ед', '1119', '989', '214', '0,1912', '0,2164'],
			['СНГ', 'ед', '204', '208', '16', '0,0784', '0,0769'],
			['3.3. Груз всего, в т.ч.', 'т', '2013,59', '1846,66', '1273,05', '0,6322', '0,6894'],
			['Внутренние', 'т', '1479,66', '1271,96', '1121,4', '0,7579', '0,8816'],
			['Международные', 'т', '495,2', '530,27', '150,97', '0,3049', '0,2847'],
			['СНГ', 'т', '38,73', '44,43', '0,68', '0,0176', '0,0153'],
			['3.4. Груз без грузовых ВС, в т.ч.', 'т', '1613,45', '1426,45', '1052,39', '0,6523', '0,7378'],
			['Внутренние', 'т', '1470,85', '1263,7', '1052,29', '0,7154', '0,8327'],
			['Международные', 'т', '103,87', '118,32', '0,02', '0,0002', '0,0002'],
			['СНГ', 'т', '38,73', '44,43', '0,08', '0,0021', '0,0018'],
			['3.5. Почта, в т.ч.', 'т', '321,12', '320,03', '276,96', '0,8625', '0,8654'],
			['Внутренние', 'т', '189,33', '227,64', '149,23', '0,7882', '0,6556'],
			['Международные', 'т', '131,79', '92,39', '127,45', '0,9671', '1,3795'],
			['СНГ', 'т', '0', '0', '0,28', '-', '-'],
			['3.6. Багаж, в т.ч.', 'т', '5116,67', '5256,99', '645,76', '0,1262', '0,1228'],
			['Внутренние', 'т', '2876,63', '2991,23', '625,25', '0,2174', '0,209'],
			['Международные', 'т', '1970,54', '1972,85', '17,94', '0,0091', '0,0091'],
			['СНГ', 'т', '269,5', '292,91', '2,57', '0,0095', '0,0088']
		);

		const reportData22 = new ReportData();
		reportData22.id = 22;
		reportData22.visual = 2;
		reportData22.titlesRu = ['Название папки', 'Количество принятых сообщений', 'Количество переданных сообщений'];
		reportData22.titlesEn = ['Folder name', 'Number of messages received', 'Number of messages sent'];
		reportData22.titles = this.settingsService.language == 'RU' ? reportData22.titlesRu : reportData22.titlesEn;
		reportData22.dataNames = ['folder', 'received', 'sent'];

		const reportData23 = new ReportData();
		reportData23.id = 23;
		reportData23.visual = 2;
		reportData23.titlesRu = [
			'Название папки',
			'Принято за вчера',
			'Передано за вчера',
			'Принято за сегодня',
			'Передано за сегодня',
			'Принято за текущий месяц',
			'Передано за текущий месяц',
		];
		reportData23.titlesEn = [
			'Folder name',
			'Accepted for yesterday',
			'Transmitted for yesterday',
			'Accepted Today',
			'Submitted Today',
			'Received this month',
			'Transmitted this month',
		];
		reportData23.titles = this.settingsService.language == 'RU' ? reportData23.titlesRu : reportData23.titlesEn;
		reportData23.dataNames = ['folder', 'dateBefore', 'date', 'month'];

		const reportData24 = new ReportData();
		reportData24.id = 24;
		reportData24.visual = 2;
		reportData24.titlesRu = [
			'Тип формализованного сообщения',
			'Количество принятых сообщений',
			'Количество переданных сообщений',
		];
		reportData24.titlesEn = ['Type of formalized message', 'Number of messages received', 'Number of messages sent'];
		reportData24.titles = this.settingsService.language == 'RU' ? reportData24.titlesRu : reportData24.titlesEn;
		reportData24.dataNames = ['type', 'received', 'sent'];

		const reportData25 = new ReportData();
		reportData25.id = 25;
		reportData25.visual = 2;
		reportData25.titlesRu = [
			'Название папки',
			'5 минут',
			'10 минут',
			'30 минут',
			'1 час',
			'3 часа',
			'12 часов',
			'24 часа',
			'Более 24 часов',
		];
		reportData25.titlesEn = [
			'Folder name',
			'5 minutes',
			'10 minutes',
			'30 minutes',
			'1 hour',
			'3 hours',
			'12 hours',
			'24 hours',
			'More than 24 hours',
		];
		reportData25.titles = this.settingsService.language == 'RU' ? reportData25.titlesRu : reportData25.titlesEn;
		reportData25.dataNames = [
			'folder',
			'minute5',
			'minute10',
			'minute30',
			'hour1',
			'hour3',
			'hour12',
			'hour24',
			'moreThanHour24',
		];

		const reportData26 = new ReportData();
		reportData26.id = 26;
		reportData26.visual = 2;
		reportData26.titlesRu = ['Создано', 'Событие', 'Имя пользователя', 'Папка'];
		reportData26.titlesEn = ['Created', 'Event', 'Username', 'Folder'];
		reportData26.titles = this.settingsService.language == 'RU' ? reportData26.titlesRu : reportData26.titlesEn;
		reportData26.dataNames = ['created', 'event', 'username', 'folder'];

		const reportData27 = new ReportData();
		reportData27.id = 27;
		reportData27.visual = 2;
		reportData27.titlesRu = ['Имя пользователя', 'Дата', 'Вход', 'Выход'];
		reportData27.titlesEn = ['Username', 'Date', 'Logon', 'Logoff'];
		reportData27.titles = this.settingsService.language == 'RU' ? reportData27.titlesRu : reportData27.titlesEn;
		reportData27.dataNames = ['username', 'date', 'logon', 'logoff'];

		const reportData28 = new ReportData();
		reportData28.id = 28;
		reportData28.visual = 2;
		reportData28.titlesRu = [
			'Канал',
			'Принято всего',
			'Принято рабочих',
			'Принято ЦХ',
			'Принято СЖЦ',
			'Искаженные',
			'Отправлено всего',
			'Отправлено рабочих',
			'Отправлено ЦХ',
			'Отправлено СЖЦ',
		];
		reportData28.titlesEn = [
			'Channel',
			'Received all',
			'Received work',
			'Received CH',
			'Received SVC',
			'Corrupted',
			'Sent all',
			'Sent work',
			'Sent CH',
			'Sent SVC',
		];
		reportData28.titles = this.settingsService.language == 'RU' ? reportData28.titlesRu : reportData28.titlesEn;
		reportData28.dataNames = [
			'channel',
			'rcvCount',
			'rcvWorkCount',
			'rcvChCount',
			'rcvSvcCount',
			'rcvCorruptCount',
			'sndCount',
			'sndWorkCount',
			'sndChCount',
			'sndSvcCount',
		];

		// const reportData29 = new ReportData();
		// reportData29.id = 29;
		// reportData29.visual = 2;
		// reportData29.titlesRu = ['ID', 'Имя пользователя', 'Имя', 'Фамилия', 'Активен'];
		// reportData29.titlesEn = ['ID', 'Username', 'First name', 'Last name', 'Enabled'];
		// reportData29.titles = this.settingsService.language == 'RU' ? reportData29.titlesRu : reportData29.titlesEn;
		// reportData29.dataNames = ['id', 'username', 'firstName', 'lastName', 'enabled'];

		const reportData29 = new ReportData();
		reportData29.id = 29;
		reportData29.visual = 2;
		reportData29.titlesRu = ['Дата', 'Событие', 'Имя пользователя', 'Поле', 'Старое', 'Новое'];
		reportData29.titlesEn = ['Date', 'Event', 'Username', 'Field', 'Old', 'New'];
		reportData29.titles = this.settingsService.language == 'RU' ? reportData29.titlesRu : reportData29.titlesEn;
		reportData29.dataNames = ['dt', 'event', 'username', 'field', 'old', 'new'];

		const reportData30 = new ReportData();
		reportData30.id = 30;
		reportData30.visual = 2;
		reportData30.titlesRu = ['Дата', 'Событие', 'Имя пользователя', 'Поле', 'Старое', 'Новое'];
		reportData30.titlesEn = ['Date', 'Event', 'Username', 'Field', 'Old', 'New'];
		reportData30.titles = this.settingsService.language == 'RU' ? reportData30.titlesRu : reportData30.titlesEn;
		reportData30.dataNames = ['dt', 'event', 'username', 'field', 'old', 'new'];

		const reportData31 = new ReportData();
		reportData31.id = 31;
		reportData31.visual = 2;
		reportData31.titlesRu = ['Аэропорт', 'Статус', 'Характеристики', 'Дата', 'ФИО', 'Адрес', 'Рейсы', '...'];
		reportData31.titlesEn = [
			'Airport',
			'Baggage status',
			'Characteristics',
			'Date',
			'Full name',
			'Address',
			'Flights',
			'...',
		];
		reportData31.titles = this.settingsService.language == 'RU' ? reportData31.titlesRu : reportData31.titlesEn;
		reportData31.dataNames = [
			'airport',
			'baggageStatus',
			'characteristics',
			'dtInsert',
			'fio',
			'address',
			'flights',
			'',
		];

		const reportData32 = new ReportData();
		reportData32.id = 32;
		reportData32.visual = 2;
		reportData32.titlesRu = [
			'Аэропорт неисправности',
			'Станция неисправности',
			'Количество',
			'Причина неисправности 1',
			'Причина неисправности 2',
		];
		reportData32.titlesEn = ['Aiport irregularity', 'Aiport', 'Quantity', 'Irregularity Code 1', 'Irregularity Code 2'];
		reportData32.titles = this.settingsService.language == 'RU' ? reportData32.titlesRu : reportData32.titlesEn;
		reportData32.dataNames = ['aiportIrregularity', 'airport', 'int', 'irregularityCode1', 'irregularityCode2'];

		const reportData33 = new ReportData();
		reportData33.id = 33;
		reportData33.visual = 2;
		reportData33.titlesRu = [
			'Аэропорт',
			'Дата заявления',
			'Номер заявления',
			'ФИО пассажира',
			'Номер бирки',
			'Статус багажа',
			'Номер досылочной бирки',
			'Рейсы досылки',
			'Маршрут досылки',
			'Дата досылки',
			'Характеристики багажа',
			'Внешние элементы',
			'Вес багажа',
		];
		reportData33.titlesEn = [
			'Airport',
			'Date of claim',
			'Request number',
			'Passenger name',
			'Tag number',
			'Baggage status',
			'Release tag number',
			'Release flights',
			'Release route',
			'Release date',
			'Baggage characteristics',
			'External elements',
			'Baggage weight',
		];
		reportData33.titles = this.settingsService.language == 'RU' ? reportData33.titlesRu : reportData33.titlesEn;
		reportData33.dataNames = [
			'airport',
			'dtInsert',
			'name',
			'passenger',
			'tag',
			'baggageStatus',
			'forwardingTag',
			'forwardingFlights',
			'forwardingRoute',
			'forwardingDt',
			'characteristics',
			'externalElements',
			'weight',
		];

		const reportData34 = new ReportData();
		reportData34.id = 34;
		reportData34.visual = 2;
		reportData34.titlesRu = [
			'Аэропорт',
			'10',
			'20',
			'30',
			'40',
			'50',
			'Подитог 10-50',
			'60',
			'70',
			'Подитог 10-70',
			'80',
			'90',
			'Итог',
		];
		reportData34.titlesEn = [
			'Airport',
			'10',
			'20',
			'30',
			'40',
			'50',
			'Sub Total 10-50',
			'60',
			'70',
			'Sub Total 10-70',
			'80',
			'90',
			'Total',
		];
		reportData34.titles = this.settingsService.language == 'RU' ? reportData34.titlesRu : reportData34.titlesEn;
		reportData34.dataNames = [
			'airport',
			'irr10',
			'irr10Percents',
			'irr20',
			'irr20Percents',
			'irr30',
			'irr30Percents',
			'irr40',
			'irr40Percents',
			'irr50',
			'irr50Percents',
			'irr1050',
			'irr1050Percents',
			'irr60',
			'irr60Percents',
			'irr70',
			'irr70Percents',
			'irr1070',
			'irr1070Percents',
			'irr80',
			'irr80Percents',
			'irr90',
			'irr90Percents',
			'total',
			'totalPercents',
		];

		const reportData35 = new ReportData();
		reportData35.id = 35;
		reportData35.visual = 2;
		reportData35.titlesRu = [
			'Станция',
			'Дата заявления',
			'Причина',
			'Номер заявления',
			'ФИО',
			'Рейс/дата',
			'Багаж',
			'Статус',
			'Дата закрытия',
			'Аэропорт неисправности',
			'Код причины',
			'Код повреждения',
			'Стоимость',
			'Кража',
			'Примечание',
		];
		reportData35.titlesEn = [
			'Station',
			'Claim date',
			'Reason',
			'Reference',
			'Name',
			'Flights/Date',
			'Bag',
			'Status',
			'Date closed',
			'Fault station',
			'Irregularity',
			'Damage code',
			'Cost',
			'Pilferage Info',
			'Comments',
		];
		reportData35.titles = this.settingsService.language == 'RU' ? reportData35.titlesRu : reportData35.titlesEn;
		reportData35.dataNames = [
			'station',
			'time',
			'statementType',
			'statementName',
			'passenger',
			'flights',
			'baggages',
			'status',
			'dtClosed',
			'faultStation',
			'irregularityCode',
			'damageCode',
			'cost',
			'pilferage',
			'commentaries',
		];

		const reportData36 = new ReportData();
		reportData36.id = 36;
		reportData36.visual = 2;
		reportData36.titlesRu = [
			'Аэропорт неисправности',
			'Дата заявления',
			'Причина',
			'Номер заявления',
			'ФИО',
			'Рейс/дата',
			'Багаж',
			'Статус',
			'Дата закрытия',
			'Код причины',
			'Код повреждения',
			'Стоимость',
			'Кража',
			'Примечание',
		];
		reportData36.titlesEn = [
			'Fault station',
			'Claim date',
			'Reason',
			'Reference',
			'Name',
			'Flights/Date',
			'Bag',
			'Status',
			'Date closed',
			'Irregularity',
			'Damage code',
			'Cost',
			'Pilferage Info',
			'Comments',
		];
		reportData36.titles = this.settingsService.language == 'RU' ? reportData36.titlesRu : reportData36.titlesEn;
		reportData36.dataNames = [
			'station',
			'time',
			'statementType',
			'statementName',
			'passenger',
			'flights',
			'baggages',
			'status',
			'dtClosed',
			'irregularityCode',
			'damageCode',
			'cost',
			'pilferage',
			'commentaries',
		];

		const reportData37 = new ReportData();
		reportData37.id = 37;
		reportData37.visual = 2;
		reportData37.titlesRu = ['Аэропорт неисправности', 'Причина', 'Заявление', 'Багаж', 'Открыто', 'Закрыто'];
		reportData37.titlesEn = ['Station', 'Reason', 'Claim', 'Bag', 'Open', 'Close'];
		reportData37.titles = this.settingsService.language == 'RU' ? reportData37.titlesRu : reportData37.titlesEn;
		reportData37.dataNames = ['station', 'statementType', 'statements', 'baggages', 'opened', 'closed'];

		const reportData38 = new ReportData();
		reportData38.id = 38;
		reportData38.visual = 2;
		reportData38.titlesRu = ['Авиакомпания', 'Причина', 'Заявление', 'Багаж', 'Открыто', 'Закрыто'];
		reportData38.titlesEn = ['Airlines', 'Reason', 'Claim', 'Bag', 'Open', 'Close'];
		reportData38.titles = this.settingsService.language == 'RU' ? reportData38.titlesRu : reportData38.titlesEn;
		reportData38.dataNames = ['station', 'statementType', 'statements', 'baggages', 'opened', 'closed'];

		const reportData39 = new ReportData();
		reportData39.id = 39;
		reportData39.visual = 2;
		reportData39.titlesRu = [
			'Аэропорт',
			'Авиакомпания',
			'Хранение',
			'Багажная бирка',
			'ФИО',
			'Начало хранения',
			'Окончание хранения',
			'Срок хранения (дни)',
			'Примечание',
		];
		reportData39.titlesEn = [
			'Airport',
			'Airline',
			'Storage',
			'Tag',
			'Passenger',
			'Start',
			'Finish',
			'Duration',
			'Description',
		];
		reportData39.titles = this.settingsService.language == 'RU' ? reportData39.titlesRu : reportData39.titlesEn;
		reportData39.dataNames = [
			'airport',
			'airline',
			'storage',
			'tag',
			'passenger',
			'dtStart',
			'dtFinish',
			'duration',
			'description',
		];

		const reportData40 = new ReportData();
		reportData40.id = 40;
		reportData40.visual = 2;
		reportData40.titlesRu = [
			'Дата составления заявления',
			'Номер заявления',
			'АП обнаружения',
			'Статус',
			'Наименование',
			'Вес',
			'Описание',
			'Место обнаружения',
			'Авиакомпания',
			'Номер рейса',
			'Дата рейса',
			'Место хранения',
			'Начало хранения',
			'Окончание хранения',
			'Реализовано',
			'Отправлено авиакомпанией ',
			'Отправлено рейсом',
			'Отправлено датой',
			'Отправлено в аэропорт',
		];
		reportData40.titlesEn = [
			'Date',
			'Claim',
			'Station',
			'Status',
			'Name',
			'Weight',
			'Description',
			'Detection name',
			'Dectection airline',
			'Detection flight',
			'Detection date',
			'Storage name',
			'Storage start',
			'Storage close',
			'Issued to owner',
			'Sent airline',
			'Sent flight',
			'Sent date',
			'Sent to airport',
		];
		reportData40.titles = this.settingsService.language == 'RU' ? reportData40.titlesRu : reportData40.titlesEn;
		reportData40.dataNames = [
			'dt',
			'name',
			'airport',
			'status',
			'item',
			'weight',
			'description',
			'placeFound',
			'airline',
			'flight',
			'flightDt',
			'storage',
			'storageDtStart',
			'storageDtClose',
			'owner',
			'airlineSent',
			'flightSent',
			'dtSent',
			'airportSent',
		];

		const reportData41 = new ReportData();
		reportData41.id = 41;
		reportData41.visual = 2;
		reportData41.titlesRu = [
			'Дата',
			'Заявление',
			'Событие',
			'Пользователь',
			'Поле',
			'Старое значение',
			'Новое значение',
		];
		reportData41.titlesEn = ['Date', 'Claim', 'Event', 'User', 'Field', 'Old value', 'New value'];
		reportData41.titles = this.settingsService.language == 'RU' ? reportData41.titlesRu : reportData41.titlesEn;
		reportData41.dataNames = ['dt', 'statement', 'event', 'username', 'field', 'old', 'new'];

		const reportData42 = new ReportData();
		reportData42.id = 42;
		reportData42.visual = 2;
		reportData42.titlesRu = ['Дата', 'Рейс', 'Вылет', 'Тип ВС', 'Борт', 'Направление', 'Диспетчер'];
		reportData42.titlesEn = ['Date', 'Flight', 'Departure', 'A/C type', 'Registration', 'Destination', 'Dispatcher'];
		reportData42.titles = this.settingsService.language == 'RU' ? reportData42.titlesRu : reportData42.titlesEn;
		reportData42.dataNames = [
			'dtFlight',
			'flight',
			'airportDeparture',
			'aircraftType',
			'tail',
			'airportDestination',
			'agent',
		];

		const reportData43 = new ReportData();
		reportData43.id = 43;
		reportData43.visual = 2;
		reportData43.titlesRu = ['Дата', 'Событие', 'Имя пользователя', 'Поле', 'Старое', 'Новое'];
		reportData43.titlesEn = ['Date', 'Event', 'Username', 'Field', 'Old', 'New'];
		reportData43.titles = this.settingsService.language == 'RU' ? reportData43.titlesRu : reportData43.titlesEn;
		reportData43.dataNames = ['dt', 'event', 'username', 'field', 'old', 'new'];

		// Опции для построения диаграмм. Разберем на примере строки ChartItem(1, 0, 2, '% пассажиров обслуженных через эту пару');
		// 1 - id записи
		// 0 - То, что будет использоваться для заголовков в диаграмме.
		//     Это 0 элемент массива в this.reportData.data. Для первой строки, это будет 9-11
		// 2 - Элемент массива для данных из this.reportData.data.  В нашем случае, это 21,7391304347826
		// % пассажиров бла, бла... - заголовок, который будет отображен в выборе опций при построении отчета.
		const charItem1 = new ChartItem(1, 0, 2, '% пассажиров обслуженных через эту пару');
		const charItem2 = new ChartItem(2, 0, 5, '% Рейсов');
		const charItem3 = new ChartItem(3, 1, 14, 'Пассажиры');
		const charItem4 = new ChartItem(4, 6, 43, 'Диаграмма №1 для 3 отчета');
		// Для построения графиков в 5 отчёте будут другие таблицы. Тут имеют значение лишь первый и последний элементы.
		const charItem5 = new ChartItem(5, 0, 1, 'График самолёто-вылеты');
		const charItem6 = new ChartItem(6, 0, 1, 'График грузы');
		const charItem7 = new ChartItem(7, 0, 1, 'Багаж');
		const charItem8 = new ChartItem(8, 0, 1, 'Пассажиры');
		// График для 4 отчета
		const charItem9 = new ChartItem(9, 2, 21, 'Занятность стоянок');

		// Диаграммы 1 отчета
		reportData1.chart.push(charItem1);
		reportData1.chart.push(charItem2);
		// Диаграммы 2 отчета
		reportData2.chart.push(charItem3);
		// Диаграммы 3 отчета
		reportData3.chart.push(charItem4);
		// Графики 5 отчета
		reportData5.chart.push(charItem5);
		reportData5.chart.push(charItem6);
		reportData5.chart.push(charItem7);
		reportData5.chart.push(charItem8);
		// График для 4 отчета
		reportData4.chart.push(charItem9);

		// Вставка данных в отчеты.
		this.reportsData[1] = reportData1;
		this.reportsData[2] = reportData2;
		this.reportsData[3] = reportData3;
		this.reportsData[4] = reportData4;
		this.reportsData[5] = reportData5;
		this.reportsData.push(reportData22);
		this.reportsData.push(reportData23);
		this.reportsData.push(reportData24);
		this.reportsData.push(reportData25);
		this.reportsData.push(reportData26);
		this.reportsData.push(reportData27);
		this.reportsData.push(reportData28);
		this.reportsData.push(reportData29);
		this.reportsData.push(
			reportData30,
			reportData31,
			reportData32,
			reportData33,
			reportData34,
			reportData35,
			reportData36,
			reportData37,
			reportData38,
			reportData39,
			reportData40,
			reportData41,
			reportData42,
			reportData43
		);

		// Столбики для построения
		this.reportDiagramColumns[1] = ['Title', 'Percent', 'Percent 2'];
		this.reportDiagramColumns[2] = ['Title', 'Percent', 'Percent 2'];
		this.reportDiagramColumns[3] = ['Title', 'Percent', 'Percent 2'];
		this.reportDiagramColumns[4] = ['Title', 'Percent', 'Percent 2'];
		this.reportDiagramColumns[5] = ['Год', 'Внутренние', 'Международные', 'СНГ'];
		this.reportDiagramColumns[6] = ['Год', 'Внутренние', 'Международные', 'СНГ'];
		this.reportDiagramColumns[7] = ['Год', 'Внутренние', 'Международные', 'СНГ'];
		this.reportDiagramColumns[8] = ['Год', 'Внутренние', 'Международные', 'СНГ'];
		this.reportDiagramColumns[9] = ['Title', 'Часов'];

		// Модель построения диаграммы
		this.reportDiagramType[1] = 'PieChart';
		this.reportDiagramType[2] = 'PieChart';
		this.reportDiagramType[3] = 'PieChart';
		this.reportDiagramType[4] = 'PieChart';
		this.reportDiagramType[5] = 'ColumnChart';
		this.reportDiagramType[6] = 'ColumnChart';
		this.reportDiagramType[7] = 'ColumnChart';
		this.reportDiagramType[8] = 'ColumnChart';
		this.reportDiagramType[9] = 'ColumnChart';

		this.reportShow = [
			{ id: 1, value: 'PieChart', title: 'Pie Chart' },
			{ id: 2, value: 'ColumnChart', title: 'Column Chart' },
			{ id: 3, value: 'BarChart', title: 'Bar Chart' },
			{ id: 4, value: 'Histogram', title: 'Histogram' },
			{ id: 5, value: 'ComboChart', title: 'Combo Chart' },
			{ id: 6, value: 'AreaChart', title: 'Area Chart' },
			{ id: 7, value: 'LineChart', title: 'Line Chart' },
			{ id: 8, value: 'Timelines', title: 'Timelines' },
		];

		// Костыль с I. Нужно будет убрать, когда будет введена нормальная модель данных.
		let i = 0;
		for (const reportsDataItem of this.reportsData) {
			if (i > 0) {
				for (const chart of reportsDataItem.chart) {
					const arr = [];
					for (const item of reportsDataItem.data) {
						arr.push([item[chart.columnTitle], parseInt(item[chart.columnData], 10), 10]);
					}
					this.reportDiagramData[chart.id] = arr;
				}
			}
			i++;
		}
		// График 4 отчета можно построить, только преобразовав данные
		const arrTempReportData4 = [];
		for (const item of reportData4.data) {
			if (!arrTempReportData4[item[1]]) {
				arrTempReportData4[item[1]] = 0;
			}
			arrTempReportData4[item[1]] += parseFloat(item[21].replace(',', '.'));
		}
		const arrReportData4 = [];
		for (const item in arrTempReportData4) {
			if (Object.prototype.hasOwnProperty.call(arrTempReportData4, item)) {
				arrReportData4.push([item, arrTempReportData4[item] * 24]);
			}
		}

		this.reportDiagramData[9] = arrReportData4;
		// Генерация сложных графиков для отчета № 5.
		this.reportDiagramData[5] = [
			['2018', 10207, 2236, 413],
			['2019', 10273, 1975, 421],
			['2020', 3571, 432, 33],
		];
		this.reportDiagramData[6] = [
			['2018', 2351, 2045, 1648],
			['2019', 635, 574, 151],
			['2020', 65, 78, 7],
		];
		this.reportDiagramData[7] = [
			['2018', 5587, 3606, 515],
			['2019', 5900, 3510, 536],
			['2020', 1268, 18, 19],
		];
		this.reportDiagramData[8] = [
			['2018', 815704, 336487, 37133],
			['2019', 875219, 327322, 39773],
			['2020', 140209, 630, 1837],
		];

		const dashboardReport1 = {
			id: 1,
			reportId: 1,
			configurationId: null,
			show: 'PieChart',
			updateTime: 5,
			size: 4,
			dataTitles: reportData1.titles,
			data: reportData1.data,
		};

		const dashboardReport2 = {
			id: 2,
			reportId: 5,
			configurationId: null,
			show: 'ColumnChart',
			updateTime: 5,
			size: 2,
			dataTitles: reportData5.titles,
			data: reportData5.data,
		};

		const dashboardReport3 = {
			id: 3,
			reportId: 2,
			configurationId: null,
			show: 'PieChart',
			updateTime: 5,
			size: 1,
			dataTitles: reportData2.titles,
			data: reportData2.data,
		};

		const dashboardReport4 = {
			id: 4,
			reportId: 1,
			configurationId: null,
			show: 'PieChart',
			updateTime: 5,
			size: 1,
			dataTitles: reportData5.titles,
			data: reportData5.data,
		};

		this.dashboards = [
			{
				id: 1,
				name: 'Default',
				reports: [dashboardReport1, dashboardReport2, dashboardReport3, dashboardReport4],
			},
			{
				id: 2,
				name: 'My dashboard',
				reports: [dashboardReport2],
			},
		];

		this.dashboard = Object.assign({}, this.dashboards[0]);
	}

	selectDashboard(id: number) {
		this.dashboard = Object.assign({}, this.dashboards.filter(el => el.id === id)[0]);
	}

	newDashboard() {
		this.userMode = 'addDashboard';
	}

	addDashboard(name: string) {
		this.userAction = '';
		this.dashboard = new Dashboard();
		this.dashboard.name = name;
		this.dashboards.push(this.dashboard);
	}

	editDashboard(id: number) {
		this.userMode = 'editDashboard';
	}

	renameDashboard(name: string) {
		this.dashboard.name = name;
		this.userAction = '';
	}

	deleteDashboard(id: number) {
		this.hideMessageWindow();
		this.dashboards = this.dashboards.filter(el => el.id !== id);
		this.dashboard = Object.assign({}, this.dashboards[0]);
	}

	createChart() {
		this.activeState = 'action-append';
		this.dashboardChart = Object.assign({}, new DashboardChart());
		this.dashboardChart.id = this.dashboard.reports.length + 1;
	}

	addChart() {
		this.dashboard.reports.push(this.dashboardChart);
		this.modalAddChart.nativeElement.click();
	}

	editChart(id: number) {
		this.activeState = 'action-update';
		this.dashboardChart = Object.assign({}, this.dashboard.reports.filter(el => el.id === id)[0]);
	}

	saveChart(id: number) {
		const index = this.dashboard.reports.indexOf(this.dashboard.reports.filter(el => el.id === id)[0]);
		this.dashboard.reports.splice(index, 1, this.dashboardChart);
		this.modalAddChart.nativeElement.click();
	}

	deleteChart(id: number) {
		this.hideMessageWindow();
		this.dashboard.reports = this.dashboard.reports.filter(el => el.id !== id);
	}

	selectReport() {
		this.searchReport = '';
		this.dashboardChart.reportId = this.selectedReport;
		this.dashboardChart.dataTitles = this.reportsData[this.selectedReport].titles;
		this.dashboardChart.data = this.reportsData[this.selectedReport].data;
		this.userAction = '';
	}

	showMessageWindow(text: string, type: string, func?, id?: number) {
		this.messageWindow.show = true;
		this.messageWindow.text = text;
		this.messageWindow.type = type;
		if (func) {
			this.messageWindow.func = func;
		}
		if (id) {
			this.messageWindow.id = id;
		}
	}

	hideMessageWindow() {
		this.messageWindow.show = false;
		this.messageWindow.text = '';
		this.messageWindow.title = '';
		this.messageWindow.type = '';
	}

	get reportGroup(): ReportGroup[] {
		if (this.searchReport !== '') {
			const data: Array<ReportGroup> = [];
			this.privateReportGroup.forEach(report => {
				const resReport = new ReportGroup();
				Object.assign(resReport, report);
				resReport.reportSubGroup = [];
				report.reportSubGroup.forEach(subgroup => {
					const resSubgroup = new ReportSubGroup();
					Object.assign(resSubgroup, subgroup);
					resSubgroup.reportItem = [];
					subgroup.reportItem.forEach(item => {
						const resItem = new ReportItem(item.id, ['', '']);
						Object.assign(resItem, item);
						resSubgroup.reportItem.push(resItem);
					});
					resReport.reportSubGroup.push(resSubgroup);
				});
				data.push(resReport);
			});
			return data.filter(group => {
				group.reportSubGroup = group.reportSubGroup.filter(subgroup => {
					subgroup.reportItem = subgroup.reportItem.filter(item => {
						return (
							item.getTitle(this.settingsService.language).toLowerCase().indexOf(this.searchReport.toLowerCase()) !== -1
						);
					});
					return subgroup.reportItem.length;
				});
				return group.reportSubGroup.length;
			});
		} else {
			return this.privateReportGroup;
		}
	}

	// Выбрать активную диаграмму
	changeDiagramActive(id) {
		this.reportDiagramActive = id;
	}

	// Выбрать вид отображения внутри отчета
	changeReportView(name) {
		this.reportView = name;
	}

	// Выбрать активную вкладку в правом столбике внутри отчета между parameters & favorites
	changeReportRightTab(name) {
		this.reportRightTab = name;
	}

	// Выбрать табу с отчетом
	changeTabReport(id) {
		this.searchReport = '';
		this.reportActiveTab = id;
	}

	// Выбрать режим сравнения между comparison & overlay
	changeCompareMode(name) {
		this.compareMode = name;
	}

	// Выбрать период между compare & previous
	changePeriod(name) {
		this.selectedPeriod = name;
	}

	showTabReport(item: number): boolean {
		return item === this.reportActiveTab;
	}

	// Выбрать отчет, а также выбрать активную диаграмму.
	changeReport(id) {
		this.reportActiveId = id;
		const report = this.reportItemAll.filter(el => el.id === id)[0];
		this.reportActive = report ? report : null;
		const reportData = this.reportsData.filter(el => el.id === id)[0];
		this.reportActiveData = reportData ? reportData : null;
		if (this.reportActiveData && this.reportActiveData.chart.length > 0) {
			this.reportDiagramActive = this.reportActiveData.chart[0].id;
		}

		this.reportParams.forwarding = null;
		this.reportParams.foundOnBoard = null;
		// Признак построения отчета для АК только по маршрутной сети
		this.routeNetworkOnly = false;
		// TODO выбор вида отчета от конфигурации
		// Присеты для отчетов (значения по умолчанию)
		if (id === 33) {
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airlines = null;
			this.reportParams.airports = null;
			this.reportParams.reportType = null;
			this.reportParams.statuses = null;
			this.reportParams.irregularities = null;
			this.reportParams.reportCategory = null;
			this.reportParams.forwarding = 'arrival';
		} else if (id === 34) {
			this.reportParams.airports = [];
			this.reportParams.totals = false;
		} else if (id === 35) {
			this.reportParams.statementTypes = ['Lost', 'Pilferage', 'Damage'];
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airports = [];
			this.reportParams.airlines = null;
			this.reportParams.reportType = 'detailed';
			this.reportParams.statuses = 'all';
			this.reportParams.reportCategory = 'airport';
			// this.reportParams.export = '';
		} else if (id === 36) {
			this.reportParams.statementTypes = ['Lost', 'Pilferage', 'Damage'];
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airports = [];
			this.reportParams.reportType = 'detailed';
			this.reportParams.statuses = 'all';
			this.reportParams.usernames = null;
		} else if (id === 37) {
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airports = [];
			this.reportParams.reportType = 'detailed';
			this.reportParams.irregularities = null;
			this.reportParams.reportCategory = 'airline';
			this.reportParams.usernames = null;
		} else if (id === 38) {
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airlines = null;
			this.reportParams.reportType = 'detailed';
			this.reportParams.irregularities = null;
			this.reportParams.reportCategory = 'airport';
			this.reportParams.usernames = null;
		} else if (id === 39) {
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airlines = null;
			this.reportParams.reportType = 'totals';
			this.reportParams.irregularities = null;
			this.reportParams.reportCategory = 'airport';
			this.reportParams.reportGrouping = 'airport_airline';
			this.reportParams.usernames = null;
		} else if (id === 40) {
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airlines = null;
			this.reportParams.airports = null;
			this.reportParams.reportType = 'detailed';
			this.reportParams.statuses = 'all';
			this.reportParams.irregularities = null;
			this.reportParams.reportCategory = null;
			this.reportParams.usernames = null;
			this.reportParams.foundOnBoard = false;
		} else if (id === 41) {
			this.reportParams.full = null;
			this.reportParams.date = null;
			this.reportParams.airlines = null;
			this.reportParams.airports = null;
			this.reportParams.reportType = null;
			this.reportParams.statuses = null;
			this.reportParams.irregularities = null;
			this.reportParams.reportCategory = null;
		}
	}

	// Аналог like sql. есть ли внутри str1 str2?
	like(str1, str2) {
		return str1.indexOf(str2) >= 0;
	}

	// Поиск title в массиве. Чтобы не перегружать html код ngFor
	arrTitleSearch(arr, id) {
		for (const item of arr) {
			if (item.id === id) {
				return item.getTitle(this.settingsService.language);
			}
		}
	}
	// Поиск реального ID в массиве
	arrIdSearch(arr, id) {
		let i = 0;
		for (const item of arr) {
			if (item.id === id) {
				return i;
			}
			i++;
		}
	}

	// Проверяет есть ли в группе избранные отчёты. В дальнейшем, конечно же лучше с сервера получить список избранного с структурой.
	issetFavoritesGroup(idGroup) {
		const id = this.arrIdSearch(this.reportGroup, idGroup);
		for (const itemSub of this.reportGroup[id].reportSubGroup) {
			for (const item of itemSub.reportItem) {
				if (item.favorite === 1) {
					return true;
				}
			}
		}
	}
	// Проверяет есть ли в подгруппе избранные отчёты. В дальнейшем, конечно же лучше с сервера получить список избранного с структурой.
	issetFavoritesSubGroup(idGroup, idSubGroup) {
		const id = this.arrIdSearch(this.reportGroup, idGroup);
		const subId = this.arrIdSearch(this.reportGroup[id].reportSubGroup, idSubGroup);
		for (const item of this.reportGroup[id].reportSubGroup[subId].reportItem) {
			if (item.favorite === 1) {
				return true;
			}
		}
	}

	loadReportDailyPlan() {
		if (this.reportParams.start && this.reportParams.finish && this.reportParams.start >= this.reportParams.finish) {
			this.messageWindow.show = true;
			this.messageWindow.text = 'Date range start cannot be greater than Date range finish';
			this.messageWindow.type = 'warning';
		} else {
			this.reportForm.form.markAllAsTouched();
			if (this.reportForm.valid) {
				return this.restApi.getReportDailyPlan(this.reportParams).subscribe((data: ReportDailyPlan) => {
					this.reportDailyPlan = data;
					this.diagramData = this.reportDailyPlan.arr.map(item => {
						return [new Date(item.dt), +item.flight];
					});
				});
			}
		}
	}

	loadReport(event) {
		this.changeTab(event);
		let reportName = event.target.id;
		if (reportName === 'report-1' || reportName === 'report-2' || reportName === 'report-3') {
			reportName = 'ReportDailyPlan';
		} else {
			this.reportParams.name = reportName;
			if (this.reportParams.start && this.reportParams.finish && this.reportParams.start >= this.reportParams.finish) {
				this.messageWindow.show = true;
				this.messageWindow.text = 'Date range start cannot be greater than Date range finish';
				this.messageWindow.type = 'warning';
			} else {
				this.reportForm.form.markAllAsTouched();
				if (this.reportForm.valid) {
					return this.restApi.getReport(this.reportParams).subscribe((data: ReportDailyPlan) => {
						this.reportDailyPlan = data;
						this.diagramData = this.reportDailyPlan.arr.map(item => {
							return [new Date(item.dt), +item.flight];
						});
					});
				}
			}
		}
	}

	loadChannels() {
		return this.restApi.getChannels().subscribe(data => {
			if (data) {
				this.referanceChannels = data;
			}
		});
	}

	changeRouteNetwork() {
		if (!this.routeNetworkOnly) {
			this.reportParams.airports = [];
		}
	}

	async my_loadReport(id?) {
		this.loading = true;
		this.activeTab = 'report';
		this.currentPage = 1;
		this.loadTab();
		this.reportParams.export = null;
		this.reportParams.recipients = null;
		if (this.reportParams.start && this.reportParams.finish && this.reportParams.start >= this.reportParams.finish) {
			this.messageWindow.show = true;
			this.messageWindow.text = 'Date range start cannot be greater than Date range finish';
			this.messageWindow.type = 'warning';
		} else {
			this.reportActiveData.data = [];
			if (this.reportActive.params.includes(14) && !this.reportParams.channel) {
				this.loadChannels();
				this.loading = false;
				this.showReportData = false;
				return;
			}
			if (this.reportActive.params.includes(0) && !this.reportParams.id) {
				this.loading = false;
				this.showReportData = false;
				return;
			}
			const url = this.reportActive.params.includes(0)
				? this.reportActive.url + '/' + this.reportParams.id
				: this.reportActive.params.includes(14)
				? this.reportActive.url + '/' + this.reportParams.channel
				: this.reportActive.url;
			const sendReportParam: ReportParams = new ReportParams();
			Object.assign(sendReportParam, this.reportParams);
			if (this.routeNetworkOnly) {
				await firstValueFrom(this.restApi.getUserAirports()).then(data => {
					sendReportParam.airports = data.map(item => item.id);
				});
			}
			return this.restApi.getReportData(sendReportParam, url, this.reportActive.port).subscribe(data => {
				if (Array.isArray(data)) {
					data.forEach(element => {
						this.reportActiveData.data.push(element);
					});
				} else {
					this.reportActiveData.data.push(data);
				}

				if (this.reportActiveId == 34) {
					type reportData = {
						airport: string;
						irr10: number;
						irr20: number;
						irr30: number;
						irr40: number;
						irr50: number;
						irr1050: number;
						irr60: number;
						irr70: number;
						irr1070: number;
						irr80: number;
						irr90: number;
						total: number;
					};

					type reportDataPercent = {
						airport: string;
						irr10Percents: number;
						irr20Percents: number;
						irr30Percents: number;
						irr40Percents: number;
						irr50Percents: number;
						irr1050Percents: number;
						irr60Percents: number;
						irr70Percents: number;
						irr1070Percents: number;
						irr80Percents: number;
						irr90Percents: number;
						totalPercents: number;
					};

					const values: Array<reportData> = [];
					const percents: Array<reportDataPercent> = [];
					const data = [];

					// Отчет с параметров Totals формируется только и стоговыми данными
					// обработка вложенности не требуется
					if (!this.reportParams.totals) {
						this.reportActiveData.data[0].report.forEach(item => {
							const dataValue = {} as reportData;
							const dataPercent = {} as reportDataPercent;
							for (const key in item) {
								// Если содержит значение в цифровом вырожении
								if (key.includes('irr') && !key.includes('Percents')) {
									dataValue[key] = item[key];
								} else if (key.includes('irr') && key.includes('Percents')) {
									// Если значение в процентном выражении
									dataPercent[key] = item[key];
								} else if (key.includes('total') && !key.includes('Percents')) {
									// Если содержит итог в цифровом вырожении
									dataValue[key] = item[key];
								} else if (key.includes('total') && key.includes('Percents')) {
									// Если итог в процентном выражении
									dataPercent[key] = item[key];
								} else {
									// Все остальные данные
									dataValue[key] = item[key];
									if (key == 'airport') {
										dataPercent[key] = '%';
									} else {
										dataPercent[key] = item[key];
									}
								}
							}
							values.push(dataValue);
							percents.push(dataPercent);
						});
						for (let i = 0; i < values.length; i++) {
							data.push(values[i]);
							data.push(percents[i]);
						}
					}

					// Формирование итоговых данных Totals
					const valuesTotal: Array<reportData> = [];
					const percentsTotal: Array<reportDataPercent> = [];
					const dataValue = {} as reportData;
					const dataPercent = {} as reportDataPercent;
					for (const key in this.reportActiveData.data[0].totals) {
						// Если содержит значение в цифровом вырожении
						if (key.includes('irr') && !key.includes('Percents')) {
							dataValue[key] = this.reportActiveData.data[0].totals[key];
						} else if (key.includes('irr') && key.includes('Percents')) {
							// Если значение в процентном выражении
							dataPercent[key] = this.reportActiveData.data[0].totals[key];
						} else if (key.includes('total') && !key.includes('Percents')) {
							// Если содержит итог в цифровом вырожении
							dataValue[key] = this.reportActiveData.data[0].totals[key];
						} else if (key.includes('total') && key.includes('Percents')) {
							// Если итог в процентном выражении
							dataPercent[key] = this.reportActiveData.data[0].totals[key];
						} else {
							// Все остальные значения
							dataValue[key] = this.reportActiveData.data[0].totals[key];
							dataPercent[key] = this.reportActiveData.data[0].totals[key];
							if (key == 'airport') {
								dataValue[key] = 'All total';
								dataPercent[key] = '%';
							}
						}
					}
					valuesTotal.push(dataValue);
					percentsTotal.push(dataPercent);

					for (let i = 0; i < valuesTotal.length; i++) {
						data.push(valuesTotal[i]);
						data.push(percentsTotal[i]);
					}

					this.reportActiveData.data[0].report = data;
				} else if (this.reportActiveId == 35) {
					type reportData = {
						baggages: number;
						commentaries: string;
						cost: string;
						damageCode: string;
						dtClosed: string;
						faultStation: string;
						flights: string;
						irregularityCode: string;
						passenger: string;
						pilferage: string;
						statementName: string;
						statementType: string;
						status: string;
						time: string;
						station: string;
						statements: number;
					};
					const table: Array<reportData> = [];
					let station: string = null;
					let time: string = null;
					if (
						this.reportActiveData.data &&
						this.reportActiveData.data.length > 0 &&
						this.reportActiveData.data[0].statementsAndBaggages
					) {
						this.reportActiveData.data[0].statementsAndBaggages.forEach(item => {
							if (item.dates && item.dates.length > 0) {
								item.dates.forEach(elementByDate => {
									elementByDate.baggages.forEach(bag => {
										const bagData = {} as reportData;
										for (const key in bag) {
											if (key == 'dtClosed') {
												bagData[key] =
													moment(bag[key]).format('DD.MM.YYYY') == '01.01.0001'
														? ''
														: moment(bag[key]).format('DD.MM.YYYY');
											} else {
												bagData[key] = bag[key];
											}
										}
										if (time !== elementByDate.time) {
											time = elementByDate.time;
											bagData.time = moment(elementByDate.time).format('DDMMM');
										}
										if (station !== item.station) {
											station = item.station;
											bagData.station = item.station;
										}
										table.push(bagData);
									});
								});
							}

							if (this.reportParams.reportType == 'totals') {
								const bagData = {} as reportData;
								bagData.baggages = item.baggages;
								bagData.statementType = item.statementType;
								bagData.statements = item.statements;
								table.push(bagData);
							}

							if (item.totals) {
								// Формирование строки результата по одному узлу
								const bagDataResult = {} as reportData;
								bagDataResult.statementName = item.totals.statements;
								bagDataResult.baggages = item.totals.bags;
								bagDataResult.dtClosed = item.totals.closed;
								bagDataResult.station = 'Total ' + item.station;
								table.push(bagDataResult);
							}
						});
					}

					const tableResult = {} as reportData;
					if (this.reportParams.reportType == 'totals') {
						tableResult.statementType = 'Total';
						tableResult.baggages = this.reportActiveData.data[0].totals.baggages;
						tableResult.statements = this.reportActiveData.data[0].totals.statements;
					} else {
						tableResult.statementName = this.reportActiveData.data[0].totals.statements;
						tableResult.baggages = this.reportActiveData.data[0].totals.bags;
						tableResult.dtClosed = this.reportActiveData.data[0].totals.closed;
						tableResult.station = 'Total';
					}

					table.push(tableResult);
					this.reportActiveData.data[0].report = table;
				} else if (this.reportActiveId == 36) {
					type reportData = {
						baggages: number;
						commentaries: string;
						cost: string;
						damageCode: string;
						dtClosed: string;
						faultStation: string;
						flights: string;
						irregularityCode: string;
						passenger: string;
						pilferage: string;
						statementName: string;
						statementType: string;
						status: string;
						time: string;
						station: string;
						statements: number;
					};

					if (
						this.reportActiveData.data &&
						this.reportActiveData.data.length > 0 &&
						this.reportParams.reportType !== 'totals'
					) {
						const table: Array<any> = [];
						let station: string = null;
						let time: string = null;
						if (this.reportActiveData.data[0].statementsAndBaggages) {
							this.reportActiveData.data[0].statementsAndBaggages.forEach(item => {
								if (item.dates && item.dates.length > 0) {
									item.dates.forEach(elementByDate => {
										elementByDate.baggages.forEach(bag => {
											const bagData = {} as reportData;
											for (const key in bag) {
												if (key == 'dtClosed') {
													bagData[key] =
														moment(bag[key]).format('DD.MM.YYYY') == '01.01.0001'
															? ''
															: moment(bag[key]).format('DD.MM.YYYY');
												} else {
													bagData[key] = bag[key];
												}
											}
											if (time !== elementByDate.time) {
												time = elementByDate.time;
												bagData.time = moment(elementByDate.time).format('DDMMM');
											}
											if (station !== item.station) {
												station = item.station;
												bagData.station = item.station;
											}
											table.push(bagData);
										});
									});
								}

								if (this.reportParams.reportType == 'totals') {
									const bagData = {} as reportData;
									bagData.baggages = item.baggages;
									bagData.statementType = item.statementType;
									bagData.statements = item.statements;
									table.push(bagData);
								}

								if (item.totals) {
									// Формирование строки результата по одному узлу
									const bagDataResult = {} as reportData;
									bagDataResult.statementName = item.totals.statements;
									bagDataResult.baggages = item.totals.bags;
									bagDataResult.dtClosed = item.totals.closed;
									bagDataResult.station = 'Total ' + item.station;
									table.push(bagDataResult);
								}
							});
						}

						const tableResult = {} as reportData;
						if (this.reportParams.reportType == 'totals') {
							tableResult.statementType = 'Total';
							tableResult.baggages = this.reportActiveData.data[0].totals.baggages;
							tableResult.statements = this.reportActiveData.data[0].totals.statements;
						} else {
							tableResult.statementName = this.reportActiveData.data[0].totals.statements;
							tableResult.baggages = this.reportActiveData.data[0].totals.bags;
							tableResult.dtClosed = this.reportActiveData.data[0].totals.closed;
							tableResult.station = 'Total';
						}

						table.push(tableResult);
						this.reportActiveData.data[0].report = table;
					} else {
						// Total report
						const tableDamage: Array<any> = [];
						this.reportActiveData.data[0].damages.stations.forEach(element => {
							const data = {};
							data['station'] = element.station;
							element.totals.forEach(damage => {
								data[damage.code] = damage.count;
							});
							tableDamage.push(data);
						});

						const dataTotal = {};
						dataTotal['station'] = 'Total';
						this.reportActiveData.data[0].damages.totals.forEach(element => {
							dataTotal[element.code] = element.count;
						});
						tableDamage.push(dataTotal);
						this.reportActiveData.data.push(tableDamage);

						const tableIrrCode: Array<any> = [];
						this.reportActiveData.data[0].irregularities.stations.forEach(element => {
							const data = {};
							data['station'] = element.station;
							element.totals.forEach(station => {
								data[station.code] = station.count;
							});
							tableIrrCode.push(data);
						});

						const dataTotalIrrCode = {};
						dataTotalIrrCode['station'] = 'Total';
						this.reportActiveData.data[0].irregularities.totals.forEach(element => {
							dataTotalIrrCode[element.code] = element.count;
						});
						tableIrrCode.push(dataTotalIrrCode);
						this.reportActiveData.data.push(tableIrrCode);
					}
				} else if (this.reportActiveId == 37 || this.reportActiveId == 38) {
					type reportData = {
						baggages: number;
						commentaries: string;
						cost: string;
						damageCode: string;
						dtClosed: string;
						faultStation: string;
						flights: string;
						irregularityCode: string;
						passenger: string;
						pilferage: string;
						statementName: string;
						statementType: string;
						status: string;
						time: string;
						station: string;
						statements: number;
					};

					if (
						this.reportActiveData.data &&
						this.reportActiveData.data.length > 0 &&
						this.reportParams.reportType !== 'totals'
					) {
						const table: Array<any> = [];
						let station: string = null;
						const time: string = null;
						this.reportActiveData.data[0].stations.forEach(item => {
							if (item.statements && item.statements.length > 0) {
								item.statements.forEach(statement => {
									const data = {};
									data['statementType'] = statement.statementType;
									data['baggages'] = statement.baggages;
									data['closed'] = statement.closed;
									data['opened'] = statement.opened;
									data['statements'] = statement.statements;
									if (station !== item.station) {
										station = item.station;
										data['station'] = item.station;
									}
									table.push(data);
								});

								const dataTotal = {};
								dataTotal['statementType'] = item.totals.statementType;
								dataTotal['baggages'] = item.totals.baggages;
								dataTotal['closed'] = item.totals.closed;
								dataTotal['opened'] = item.totals.opened;
								dataTotal['statements'] = item.totals.statements;
								dataTotal['station'] = 'Total ' + item.station;
								table.push(dataTotal);
							}
						});

						if (
							this.reportActiveData.data[0].all.statements &&
							this.reportActiveData.data[0].all.statements.length > 0
						) {
							this.reportActiveData.data[0].all.statements.forEach(statement => {
								const data = {};
								data['statementType'] = statement.statementType;
								data['baggages'] = statement.baggages;
								data['closed'] = statement.closed;
								data['opened'] = statement.opened;
								data['statements'] = statement.statements;
								if (station !== 'ALL') {
									station = 'ALL';
									data['station'] = 'ALL';
								}
								table.push(data);
							});
						}

						const dataTotal = {};
						dataTotal['statementType'] = this.reportActiveData.data[0].totals.statementType;
						dataTotal['baggages'] = this.reportActiveData.data[0].totals.baggages;
						dataTotal['closed'] = this.reportActiveData.data[0].totals.closed;
						dataTotal['opened'] = this.reportActiveData.data[0].totals.opened;
						dataTotal['statements'] = this.reportActiveData.data[0].totals.statements;
						dataTotal['station'] = 'Total';
						table.push(dataTotal);
						this.reportActiveData.data[0].report = table;
					} else {
						// Total report
						const table: Array<any> = [];
						let station: string = null;
						if (
							this.reportActiveData.data[0].all.statements &&
							this.reportActiveData.data[0].all.statements.length > 0
						) {
							this.reportActiveData.data[0].all.statements.forEach(statement => {
								const data = {};
								data['statementType'] = statement.statementType;
								data['baggages'] = statement.baggages;
								data['closed'] = statement.closed;
								data['opened'] = statement.opened;
								data['statements'] = statement.statements;
								if (station !== 'ALL') {
									station = 'ALL';
									data['station'] = 'ALL';
								}
								table.push(data);
							});
						}

						const dataTotal = {};
						dataTotal['statementType'] = this.reportActiveData.data[0].totals.statementType;
						dataTotal['baggages'] = this.reportActiveData.data[0].totals.baggages;
						dataTotal['closed'] = this.reportActiveData.data[0].totals.closed;
						dataTotal['opened'] = this.reportActiveData.data[0].totals.opened;
						dataTotal['statements'] = this.reportActiveData.data[0].totals.statements;
						dataTotal['station'] = 'Total';
						table.push(dataTotal);
						this.reportActiveData.data[0].report = table;
					}
				} else if (this.reportActiveId == 39) {
					if (
						this.reportActiveData.data &&
						this.reportActiveData.data.length > 0 &&
						this.reportParams.reportType !== 'totals'
					) {
						const table: Array<any> = [];
						this.reportActiveData.data.forEach(item => {
							const data = {};
							data['airport'] = item.airport;
							data['airline'] = item.airline;
							data['storage'] = item.storage;
							data['tag'] = item.tag;
							data['passenger'] = item.passenger;
							data['dtStart'] =
								moment(item.dtStart).format('DD.MM.YYYY') == '01.01.0001'
									? ''
									: moment(item.dtStart).format('DD.MM.YYYY');
							data['dtFinish'] =
								moment(item.dtFinish).format('DD.MM.YYYY') == '01.01.0001'
									? ''
									: moment(item.dtFinish).format('DD.MM.YYYY');
							data['duration'] = item.duration;
							data['description'] = item.description;
							table.push(data);
						});
						this.reportActiveData.data = table;
					} else {
						// Total report
						const table: Array<any> = [];
						if (this.reportActiveData.data && this.reportActiveData.data.length > 0) {
							this.reportActiveData.data.forEach(item => {
								const data = {};
								data['name'] = item.name;
								data['pieces'] = item.pieces;
								data['storageTime'] = item.storageTime;
								table.push(data);
							});
						}
						this.reportActiveData.data[0].report = table;
					}
				} else if (this.reportActiveId == 40) {
					if (
						this.reportActiveData.data &&
						this.reportActiveData.data.length > 0 &&
						this.reportParams.reportType !== 'totals'
					) {
						const table: Array<any> = [];
						this.reportActiveData.data.forEach(item => {
							const data = {};
							data['dt'] =
								moment(item.dt).format('DD.MM.YYYY') == '01.01.0001' ? '' : moment(item.dt).format('DD.MM.YYYY');
							data['name'] = item.name;
							data['airport'] = item.airport;
							data['status'] = item.status;
							data['item'] = item.item;
							data['weight'] = item.weight;
							data['description'] = item.description;
							data['placeFound'] = item.placeFound;
							data['airline'] = item.airline;
							data['flight'] = item.flight;
							data['flightDt'] =
								moment(item.flightDt).format('DD.MM.YYYY') == '01.01.0001'
									? ''
									: moment(item.flightDt).format('DD.MM.YYYY');
							data['storage'] = item.storage;
							data['storageDtStart'] =
								moment(item.storageDt[0]).format('DD.MM.YYYY') == '01.01.0001'
									? ''
									: moment(item.storageDt[0]).format('DD.MM.YYYY');
							data['storageDtClose'] =
								moment(item.storageDt[1]).format('DD.MM.YYYY') == '01.01.0001'
									? ''
									: moment(item.storageDt[1]).format('DD.MM.YYYY');
							data['owner'] = item.owner;
							data['airlineSent'] = item.airlineSent;
							data['flightSent'] = item.flightSent;
							data['dtSent'] =
								moment(item.dtSent).format('DD.MM.YYYY') == '01.01.0001'
									? ''
									: moment(item.dtSent).format('DD.MM.YYYY');
							data['airportSent'] = item.airportSent;
							table.push(data);
						});
						this.reportActiveData.data = table;
					} else {
						// Total report
						const table: Array<any> = [];
						if (this.reportActiveData.data && this.reportActiveData.data.length > 0) {
							this.reportActiveData.data[0].lostItems.forEach(item => {
								const data = {};
								data['dt'] =
									moment(item.dt).format('DD.MM.YYYY') == '01.01.0001' ? '' : moment(item.dt).format('DD.MM.YYYY');
								data['amount'] = item.amount;
								table.push(data);
							});

							const dataTotal = {};
							dataTotal['dt'] = this.settingsService.language == 'RU' ? 'Итого' : 'Total';
							dataTotal['amount'] = this.reportActiveData.data[0].total;
							table.push(dataTotal);
						}
						this.reportActiveData.data[0].report = table;
					}
				} else if (this.reportActiveId == 42) {
				}
				this.loading = false;
				this.showReportData = true;
			});
		}
	}

	clearReportData() {
		this.reportActiveData.data = [];
	}

	sendByEmail() {
		this.reportParams.export = 'email';
		this.loading = true;
		return this.restApi
			.getReportData(this.reportParams, this.reportActive.url, this.reportActive.port)
			.subscribe(data => {
				if (data.type === 'ok') {
					this.loading = false;
					this.messageSended = true;
					setTimeout(() => {
						this.messageSended = false;
						this.modalEnterEmail = false;
					}, 3000);
				}
			});
	}

	changeParamsPeriod(value) {
		let day = new Date();
		this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
		if (this.selectedPeriod === '') {
			this.selectedPeriod = 'current';
		}
		switch (value) {
			case 'day':
				day = new Date();
				if (this.selectedPeriod === 'current') {
					this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
				} else if (this.selectedPeriod === 'previous') {
					day.setUTCDate(day.getDate() - 1);
					this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
					this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
				}
				break;
			case 'week':
				day = new Date();
				const mo = day.getDay();
				const diff = day.getDate() - mo + (mo === 0 ? -6 : 1);
				day = new Date(day.setUTCDate(diff));
				if (this.selectedPeriod === 'current') {
					this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
				} else if (this.selectedPeriod === 'previous') {
					day.setUTCDate(day.getDate() - 7);
					this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
					day.setUTCDate(day.getDate() + 6);
					this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
				}
				break;
			case 'month':
				day = new Date();
				day.setUTCDate(1);
				if (this.selectedPeriod === 'current') {
					this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
				} else if (this.selectedPeriod === 'previous') {
					day.setUTCDate(day.getDate() - 1);
					this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
					day.setUTCDate(1);
					this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
				}
				break;
			case 'qarter':
				day = new Date();
				day.setUTCDate(1);
				if (this.selectedPeriod === 'current') {
					if ([0, 1, 2].includes(day.getMonth())) {
						day.setUTCMonth(0);
					} else if ([3, 4, 5].includes(day.getMonth())) {
						day.setUTCMonth(3);
					} else if ([6, 7, 8].includes(day.getMonth())) {
						day.setUTCMonth(6);
					} else if ([9, 10, 11].includes(day.getMonth())) {
						day.setUTCMonth(9);
					}
					this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
				} else if (this.selectedPeriod === 'previous') {
					if ([0, 1, 2].includes(day.getMonth())) {
						day.setUTCMonth(0);
						day.setUTCDate(day.getDate() - 1);
						this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
						day = new Date();
						day.setUTCFullYear(day.getFullYear() - 1);
						day.setUTCDate(1);
						day.setUTCMonth(8);
						this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
					} else if ([3, 4, 5].includes(day.getMonth())) {
						day.setUTCMonth(0);
						day.setUTCDate(1);
						this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
						day.setUTCMonth(3);
						day.setUTCDate(day.getDate() - 1);
						this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
					} else if ([6, 7, 8].includes(day.getMonth())) {
						day.setUTCMonth(3);
						day.setUTCDate(1);
						this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
						day.setUTCMonth(6);
						day.setUTCDate(day.getDate() - 1);
						this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
					} else if ([9, 10, 11].includes(day.getMonth())) {
						day.setUTCMonth(6);
						day.setUTCDate(1);
						this.reportParams.start = new Date(day.setUTCHours(0, 0, 0, 0)).toISOString();
						day.setUTCMonth(9);
						day.setUTCDate(day.getDate() - 1);
						this.reportParams.finish = new Date(day.setUTCHours(23, 59, 59)).toISOString();
					}
				}
				break;
			default:
				this.selectedPeriod = '';
				break;
		}
	}

	checkType(value) {
		return typeof value;
	}

	selectPeriod(value) {
		this.selectedPeriod = value;
		this.changeParamsPeriod(this.reportParams.period);
	}

	saveReport(url: string, type: string) {
		this.reportParams.export = type;
		if (this.reportActive.params.includes(0)) {
			url = url + '/' + this.reportParams.id;
		} else if (this.reportActive.params.includes(14)) {
			url = url + '/' + this.reportParams.channel;
		}
		if (this.reportParams.forwarding !== null) {
			url += '/' + this.reportParams.forwarding;
		}
		return this.restApi.downloadReport(this.reportParams, url, this.reportActive.port).subscribe(data => {
			const blob = new Blob([data], { type: 'application' });
			this.fileSaverService.save(blob, url + '.' + type);
		});
	}

	loadReportSynchron() {
		if (this.reportParams.start && this.reportParams.finish && this.reportParams.start >= this.reportParams.finish) {
			this.messageWindow.show = true;
			this.messageWindow.text = 'Date range start cannot be greater than Date range finish';
			this.messageWindow.type = 'warning';
		} else {
			this.reportForm.form.markAllAsTouched();
			if (this.reportForm.valid) {
				return this.restApi.getReportSynchron(this.reportParams).subscribe((data: ReportSynchron[]) => {
					this.reportSynchron = data;
				});
			}
		}
	}

	loadReportMediaLogs() {
		if (this.reportParams.start && this.reportParams.finish && this.reportParams.start >= this.reportParams.finish) {
			this.messageWindow.show = true;
			this.messageWindow.text = 'Date range start cannot be greater than Date range finish';
			this.messageWindow.type = 'warning';
		} else {
			this.reportForm.form.markAllAsTouched();
			if (this.reportForm.valid) {
				return this.restApi.getReportMediaLogs(this.reportParams).subscribe((data: ReportMediaLogs[]) => {
					this.reportMediaLogs = data;
				});
			}
		}
	}

	loadMovements() {
		return this.restApi.getMovements().subscribe(
			(data: ReferanceMovement[]) => {
				this.referanceMovements = [];
				for (const item of data) {
					const movement = new ReferanceMovement();
					Object.assign(movement, item);
					this.referanceMovements.push(movement);
				}
			},
			err => {
				if (err.type) {
					this.messageWindow.show = true;
					this.messageWindow.text = err.message;
					this.messageWindow.type = err.type;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				}
			}
		);
	}

	loadAirports() {
		return this.restApi.getAirports().subscribe(
			(data: ReferanceAirport[]) => {
				this.referanceAirports = [];
				for (const item of data) {
					const airport = new ReferanceAirport();
					Object.assign(airport, item);
					this.referanceAirports.push(airport);
				}
			},
			err => {
				if (err.type) {
					this.messageWindow.show = true;
					this.messageWindow.text = err.message;
					this.messageWindow.type = err.type;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				}
			}
		);
	}

	loadAircrafts() {
		return this.restApi.getAircraftTypes().subscribe(
			(data: ReferanceAircraft[]) => {
				this.referanceAircraftTypes = [];
				for (const item of data) {
					const aircraft = new ReferanceAircraft();
					Object.assign(aircraft, item);
					this.referanceAircraftTypes.push(aircraft);
				}
			},
			err => {
				if (err.type) {
					this.messageWindow.show = true;
					this.messageWindow.text = err.message;
					this.messageWindow.type = err.type;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				}
			}
		);
	}

	loadKeycloakUsers() {
		return this.restApi.getKeycloakUsers().subscribe(data => {
			this.referenceKeycloakUser = [];
			data.forEach(user => {
				this.referenceKeycloakUser.push(user as ReferenceKeycloakUser);
			});
		});
	}

	// Airlines
	loadAirlines() {
		return this.restApi.getAirlines().subscribe(
			(data: ReferanceAirline[]) => {
				this.referanceAirlines = [];
				for (const item of data) {
					const airline = new ReferanceAirline();
					Object.assign(airline, item);
					this.referanceAirlines.push(airline);
				}
			},
			err => {
				if (err.type) {
					this.messageWindow.show = true;
					this.messageWindow.text = err.message;
					this.messageWindow.type = err.type;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				}
			}
		);
	}

	loadGeoTypes() {
		return this.restApi.getGeoTypes().subscribe(
			(data: ReferanceGeoTypes[]) => {
				this.referanceGeoTypes = [];
				for (const item of data) {
					const geo = new ReferanceGeoTypes();
					Object.assign(geo, item);
					this.referanceGeoTypes.push(geo);
				}
			},
			err => {
				if (err.type) {
					this.messageWindow.show = true;
					this.messageWindow.text = err.message;
					this.messageWindow.type = err.type;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				}
			}
		);
	}

	loadSeasons() {
		return this.restApi.getSeasons().subscribe(
			(data: ReferanceSeason[]) => {
				this.referanceSeasons = [];
				for (const item of data) {
					const season = new ReferanceSeason();
					Object.assign(season, item);
					this.referanceSeasons.push(season);
				}
			},
			err => {
				if (err.type) {
					this.messageWindow.show = true;
					this.messageWindow.text = err.message;
					this.messageWindow.type = err.type;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				}
			}
		);
	}

	loadRegularities() {
		return this.restApi.getRegularities().subscribe(
			(data: ReferanceRegulature[]) => {
				this.referanceRegulatures = [];
				for (const item of data) {
					const regulature = new ReferanceRegulature();
					Object.assign(regulature, item);
					this.referanceRegulatures.push(regulature);
				}
			},
			err => {
				if (err.type) {
					this.messageWindow.show = true;
					this.messageWindow.text = err.message;
					this.messageWindow.type = err.type;
					console.log('Error: ' + err.message + '\ndetail:' + err.detail);
				}
			}
		);
	}

	async loadReferences() {
		const xRequestId = this.settingsService.general.randomUuid;
		for (const key in this.references) {
			if (Object.prototype.hasOwnProperty.call(this.references, key)) {
				this.selectLoadAnimation[key] = true;
				await this.restApi.getReference(key, xRequestId).then(data => {
					this.references[key] = [];
					if (key == 'irregularity_groups') {
						data.forEach(el => {
							const item = new Reference();
							Object.assign(item, el);
							item['group'] = 'All, Все';
							this.references[key].push(item);
						});
					} else {
						data.forEach(el => {
							const item = new Reference();
							Object.assign(item, el);
							this.references[key].push(item);
						});
					}
				});
				this.selectLoadAnimation[key] = false;
			}
		}
	}

	async loadReferencesLocal() {
		this.references['statuses'] = [new Reference(0, ['Opened', 'Открытые']), new Reference(1, ['Closed', 'Закрытые'])];

		// Установка параметра для реалзизации множественного выбора в ng-select
		this.references['statuses'][0]['group'] = 'All, Все';
		this.references['statuses'][1]['group'] = 'All, Все';

		this.references['statementTypes'] = [
			new Reference(0, ['Lost', 'Неприбытие']),
			new Reference(1, ['Pilferage', 'Недостача']),
			new Reference(2, ['Damage', 'Повреждение']),
		];

		// Установка параметра для реалзизации множественного выбора в ng-select
		this.references['statementTypes'][0]['group'] = 'All, Все';
		this.references['statementTypes'][1]['group'] = 'All, Все';
		this.references['statementTypes'][2]['group'] = 'All, Все';

		this.references['reportType'] = [
			new Reference(0, ['Detailed', 'Детальный']),
			new Reference(1, ['Detailed long', 'Расширенный']),
			new Reference(2, ['Totals', 'Итоговый']),
		];

		this.references['reportTypeShort'] = [
			new Reference(0, ['Detailed long', 'Детальный']),
			new Reference(1, ['Totals', 'Итоговый']),
		];

		// Установка параметра для реалзизации множественного выбора в ng-select
		this.references['reportType'][0]['group'] = 'All, Все';
		this.references['reportType'][1]['group'] = 'All, Все';
		this.references['reportType'][2]['group'] = 'All, Все';
	}

	parseDate(dateString: string, time): string {
		if (time.value && dateString + 'T' + time.value) {
			return new Date(dateString + 'T' + time.value).toISOString();
		}
		return null;
	}

	parseTime(date, timeString: string): string {
		if (date.value && date.value + 'T' + timeString) {
			return new Date(date.value + 'T' + timeString).toISOString();
		}
		return null;
	}

	parseUTCDate(date: string, time: string): string {
		if (date && time) {
			const timeArray = time.split(':');
			const result = new Date(date);
			result.setUTCHours(+timeArray[0], +timeArray[1]);
			return new Date(result).toISOString();
		}
		return null;
	}

	changeTab(event: { target: { id: string } }) {
		if (event.target.id !== this.activeTab) {
			this.activeTab = event.target.id;
			this.loadTab();
			this.currentPage = 1;
			this.reportDailyPlan = null;
			this.reportSynchron = null;
			this.reportParams = new ReportParams();
		}
	}

	showTab(item: string): boolean {
		return item === this.activeTab;
	}

	loadTab() {
		switch (this.activeTab) {
			case 'menu-dashboard': {
				break;
			}
			case 'tab-daily-plan': {
				break;
			}
			case 'tab-synchron': {
				break;
			}
		}
	}

	changeModule(event: { target: { id: string } }) {
		this.activeModule = event.target.id;
		switch (this.activeModule) {
			case 'module-coordination': {
				this.activeTab = 'tab-daily-plan';
				break;
			}
			case 'module-informational': {
				this.activeTab = 'tab-media-logs';
				break;
			}
		}
	}

	clearPage() {
		this.currentPage = 1;
	}

	// Функции прокрутки для Авиакомпаний
	onScrollReferanceAirlines({ end }) {
		if (this.referanceAirlinesLoading || this.referanceAirlines.length <= this.referanceAirlinesBuffer.length) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAirlinesBuffer.length) {
			this.referanceAirlinesFetchMore();
		}
	}

	onScrollReferanceAirlinesToEnd() {
		this.referanceAirlinesFetchMore();
	}

	// Функция загрузки списка для отображения в ng-select Авиакомпаний
	private referanceAirlinesFetchMore() {
		const len = this.referanceAirlinesBuffer.length;
		const more = this.referanceAirlines.slice(len, this.referanceBufferSize + len);
		this.referanceAirlinesLoading = true;
		this.referanceAirlinesBuffer = this.referanceAirlines.concat(more);
		this.referanceAirlinesLoading = false;
	}

	// Функции прокрутки для Типов Движения
	onScrollReferanceMovements({ end }) {
		if (this.referanceMovementsLoading || this.referanceMovements.length <= this.referanceMovementsBuffer.length) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceMovementsBuffer.length) {
			this.referanceMovementsFetchMore();
		}
	}

	onScrollReferanceMovementsToEnd() {
		this.referanceMovementsFetchMore();
	}

	// Функция загрузки списка для отображения в ng-select Типов Движения
	private referanceMovementsFetchMore() {
		const len = this.referanceMovementsBuffer.length;
		const more = this.referanceMovements.slice(len, this.referanceBufferSize + len);
		this.referanceMovementsLoading = true;
		this.referanceMovementsBuffer = this.referanceMovements.concat(more);
		this.referanceMovementsLoading = false;
	}

	// Функции прокрутки для Типов Воздушных Судов
	onScrollReferanceAircraftTypes({ end }) {
		if (
			this.referanceAircraftTypesLoading ||
			this.referanceAircraftTypes.length <= this.referanceAircraftTypesBuffer.length
		) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAircraftTypesBuffer.length) {
			this.referanceAircraftTypesFetchMore();
		}
	}

	onScrollReferanceAircraftTypesToEnd() {
		this.referanceAircraftTypesFetchMore();
	}

	// Функция загрузки списка для отображения в ng-select Типов Воздушных Судов
	private referanceAircraftTypesFetchMore() {
		const len = this.referanceAircraftTypesBuffer.length;
		const more = this.referanceAircraftTypes.slice(len, this.referanceBufferSize + len);
		this.referanceAircraftTypesLoading = true;
		this.referanceAircraftTypesBuffer = this.referanceAircraftTypes.concat(more);
		this.referanceAircraftTypesLoading = false;
	}

	// Функции прокрутки для Направлений
	onScrollReferanceAirports({ end }) {
		if (this.referanceAirportsLoading || this.referanceAirports.length <= this.referanceAirportsBuffer.length) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAirportsBuffer.length) {
			this.referanceAirportsFetchMore();
		}
	}

	onScrollReferanceAirportsToEnd() {
		this.referanceAirportsFetchMore();
	}

	// Функция загрузки списка для отображения в ng-select Направлений
	private referanceAirportsFetchMore() {
		const len = this.referanceAirportsBuffer.length;
		const more = this.referanceAirports.slice(len, this.referanceBufferSize + len);
		this.referanceAirportsLoading = true;
		this.referanceAirportsBuffer = this.referanceAirports.concat(more);
		this.referanceAirportsLoading = false;
	}

	getById(array, id) {
		const result = array.filter(el => el.id === id);
		if (result) {
			return result[0];
		} else {
			return null;
		}
	}

	customSearchUser(term: string, item: any) {
		term = term.toLowerCase();
		return (
			item.username.toLowerCase().indexOf(term) > -1 ||
			item.firstName.toLowerCase().indexOf(term) > -1 ||
			item.lastName.toLowerCase().indexOf(term) > -1
		);
	}

	// Функция для печати, из-за постраничной навигации реализована отдельно
	print() {
		let printContent = '';
		let popupWin;

		if (this.reportActiveId === 42) {
			printContent += '<p>';
			printContent += '<br>Date start ' + this.reportParams.start;
			printContent += '<br>Date finish ' + this.reportParams.finish;
			printContent += '</p>';

			printContent += '<table class="table">';
			printContent += '<thead>';

			printContent += '<tr>';
			this.reportActiveData.titles.forEach(title => {
				printContent += `<th>${title}</th>`;
			});
			printContent += '</tr>';

			printContent += '</thead>';
			printContent += '<tbody class="font-small">';
			this.reportActiveData.data[0].detailed.forEach(row => {
				printContent += '<tr>';
				this.reportActiveData.dataNames.forEach(name => {
					if (this.checkType(row[name]) === 'object') {
						row[name].forEach(item => {
							printContent += `<td class="text-center">${item}</td>`;
						});
					} else {
						printContent += `<td class="text-center">${row[name]}</td>`;
					}
				});
				printContent += '<tr>';
			});
			printContent += '</tbody>';
			printContent += '</table>';
			printContent += '<br>';
			printContent += '<h5>TOTAL</h5>';

			printContent += '<table class="table">';
			printContent += '<thead>';

			printContent += '<tr>';
			printContent += `<th>Departure</th>`;
			printContent += `<th>Dispatcher</th>`;
			printContent += `<th>Number of flights</th>`;
			printContent += '</tr>';

			printContent += '</thead>';
			printContent += '<tbody class="font-small">';
			this.reportActiveData.data[0].total.forEach(row => {
				printContent += '<tr>';
				printContent += `<td class="text-center">${row['airport']}</td>`;
				printContent += `<td class="text-center">${row['agent']}</td>`;
				printContent += `<td class="text-center">${row['flights']}</td>`;
				printContent += '<tr>';
			});
			printContent += '</tbody>';
			printContent += '</table>';
		} else {
			printContent += '<table class="table">';
			printContent += '<thead>';

			printContent += '<tr>';
			this.reportActiveData.titles.forEach(title => {
				printContent += `<th>${title}</th>`;
			});
			printContent += '</tr>';

			printContent += '</thead>';
			printContent += '<tbody class="font-small">';
			this.reportActiveData.data.forEach(row => {
				printContent += '<tr>';
				this.reportActiveData.dataNames.forEach(name => {
					if (this.checkType(row[name]) === 'object') {
						row[name].forEach(item => {
							printContent += `<td class="text-center">${item}</td>`;
						});
					} else {
						printContent += `<td class="text-center">${row[name]}</td>`;
					}
				});
				printContent += '<tr>';
			});
			printContent += '</tbody>';
			printContent += '</table>';
		}

		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          table {border-collapse: collapse; border-spacing: 0;}
          th, td {padding: 4px 8px; position: relative; vertical-align: middle; border: solid 1px #ccd2e3;}
          </style>
        </head>
        <body onload="window.print();window.close()">${printContent}</body>
      </html>`);
		popupWin.document.close();
	}

	get language() {
		return this.settingsService.language;
	}

	checkUserRightForModule(id: Module) {
		return this.settingsService.user.isUserHasRoleForAccessToModule(id);
	}
}
